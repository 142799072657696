import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import { FaCheck, FaPenSquare, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal'
import './modal.css';
import { Input, Select, Button } from 'antd';
import { useStates } from '../../hooks/states';
import axios from 'axios';
import { useCep } from '../../../../../../hooks/cep';
import { useShowData } from '../../hooks/showData';
import api from '../../../../../../services/api';
import config from '../../../../../../services/auth';

const { Option } = Select;

interface Props{
    isOpen: boolean;
}

const Declaration: React.FC<Props> = ({ isOpen }) => {
    const { setState } = useStates();
    const { cep, setCep } = useCep();
    const { setShow } = useShowData();

    const [ uf, setUf ] = useState<any>([]);
    const [listCity, setListCity] = useState<any>([])
    const [ , setUnit ] = useState<number>();
    const [ , setLog_Type ] = useState('');
    const [ log, setLog ] = useState('');
    const [ city, setCity ] = useState('');
    const [ , setNumber ] = useState('');
    const [ , setComp ] = useState(' ');
    const [ neighborhood, setNeighborhood ] = useState('');
    const [ currentUf, setCurrentUf ] = useState('');
    const [ name, setName ] = useState('');
    const [ cpf, setCpf ] = useState('');
    const [ date, setDate ] = useState('');
    const [ rg, setRg ] = useState('');
    const [ organ, setOrgan ] = useState('');
    const [ , setMaritalStatus ] = useState('');

    function handleChangeUf(e: string){
        handleCities(e)    
    }

    async function handleUF(){ //buscar estados na tabela de Estados na api
        const res = await api.get(`/state`, config);
        setUf(res.data);
    }

    async function handleCities(UF: any){ //buscar cidades referentes ao estado
        const res = await api.get(`/municipalities/${UF}`, config); 
        setListCity(res.data);
    }

    function handleCep(e: any){ //setar o CEP e preencher dados referentes a ele
        setCep(e);
        handleData();
    }



    function closeModal(){ //fechar modal 
        setState(false);
        setUnit(0);
    }

    function handleChangeSelect(e: any){ //pegar valor do estado civil
        setMaritalStatus(e);
    }


    function handleClick(){ //fechar modal e disparar gatilho da tabela de assinante
        setShow(true);
        setState(false);
    }


    async function handleData(){ //pegar dados do CEP
        const res = await axios.get(`https://viacep.com.br/ws/${cep}/json`)
        setLog(res.data.logradouro);
        setNeighborhood(res.data.bairro);
        setCity(res.data.localidade);
        setCurrentUf(res.data.uf);
    }

    useEffect(()=> {
        handleUF();
    },[])

    return(
        <Modal isOpen={isOpen} ariaHideApp={false}  >
                <div>
                <h1>{<FaPenSquare/>}Declaração de Veracidade dos Documentos Digitalizados</h1>
                <Divider />
                    <p>Assinante Declaração de Veracidade:</p>

                    <div className="div-form">
                        <div className="div-unit">
                            <label> Nome Declarante: </label>
                            <Input style={{width: 300}} value={name} placeholder="Nome Declarante" onChange={e=>setName(e.target.value)} />
                        </div>
                        <div className="div-unit">
                            <label> CPF: </label>
                            <Input style={{width: 300}} value={cpf} placeholder="CPF" onChange={e=>setCpf(e.target.value)}/>
                        </div>
                        <div className="div-unit">
                            <label> Data Nascimento</label>
                            <Input style={{width: 300}} value={date} placeholder="Data Nascimento" type="date" onChange={e=>setDate(e.target.value)} />
                        </div> 
                        <div className="div-unit">
                            <label >Identidade: </label>
                            <Input style={{width: 300}} value={rg} placeholder="Identidade" onChange={e=>setRg(e.target.value)} />
                        </div>
                        <div className="div-unit">
                            <label >Órgão Expeditor: </label>
                            <Input style={{width: 300}} value={organ} placeholder="Órgão Expeditor" onChange={e=>setOrgan(e.target.value)} />
                        </div>
                        <div className="div-unit">
                            <label >UF Identidade: </label>
                            <Select style={{width: 300}}  placeholder="Selecione o Estado" onChange={handleChangeUf} >
                                {uf.map((item: { uf: string, name: string, id: number }) => (
                                        <Option key={item.id} value={item.uf} >{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className="div-unit">
                            <label >Nacionalidade: </label>
                            <Select style={{width: 300}} placeholder="--Selecione--">
                                <Option value="ce">Brasileira</Option>
                            </Select>
                        </div>
                        <div className="div-unit">
                            <label >Estado Civil: </label>
                            <Select style={{width: 300}} placeholder="--Selecione--" onChange={e=>handleChangeSelect(e)} >
                                <Option value="single">Solteiro</Option>
                                <Option value="married">Casado</Option>
                                <Option value="widower">Viúvo</Option>
                                <Option value="divorced">Divorciado</Option>
                            </Select>
                        </div>
                        <div className="div-unit">
                            <label >Profissão: </label>
                            <Input style={{width: 300}}/>
                        </div>
                        <div className="div-unit">
                            <label >CEP: </label>
                            <Input style={{width: 300}} onBlur={e=>handleCep(e.target.value)}/>
                        </div>
                        <div className="div-unit">
                            <label >Tipo de Logradouro: </label>
                            <Input style={{width: 300}} onBlur={e=>setLog_Type(e.target.value)} />
                        </div>
                        <div className="div-unit">
                            <label >Nome Logradouro: </label>
                            <Input style={{width: 300}} value={log}/>
                        </div>
                        <div className="div-unit">
                            <label >Número: </label>
                            <Input style={{width: 300}} onBlur={e=>setNumber(e.target.value)}/>
                        </div>
                        <div className="div-unit">
                            <label >Bairro: </label>
                            <Input style={{width: 300}} value={neighborhood}/>
                        </div>
                        <div className="div-unit">
                            <label >Complemento: </label>
                            <Input style={{width: 300}} onBlur={e=>setComp(e.target.value)}/>
                        </div>
                        <div className="div-unit">
                            <label >Município: </label>
                            <Input style={{width: 300}} value={city}/>
                        </div>
                        <div className="div-unit">
                            <label >UF: </label>
                            <Input style={{width: 300}} value={currentUf}/>
                        </div>
                        <div className="div-unit">
                            <label >Município Assinatura: </label>
                            <Select style={{width: 300}} placeholder="--Selecione--" onChange={handleChangeUf} >
                               {listCity.map((item: { id: number, county: string}) => (
                                   <Option key={item.id} value={item.county}>{item.county}</Option>
                               ))
                               }
                            </Select>
                        </div>
                    </div>
                    <Divider />
                    <div className="div-button">
                        <Button type="primary" danger icon={<FaTimes/>} onClick={closeModal}>Fechar</Button>
                        <Button type="primary" onClick={handleClick} style={{backgroundColor: '#27ae60', border: 'none'}} icon={<FaCheck/>} >Gerar Declaração</Button>
                    </div>
                </div>
        </Modal>
    )
}

export default Declaration;