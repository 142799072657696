import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Form, Input, Button, Alert } from 'antd';

import { FormEvents } from '../../SelectEvent/components/components.styled';
import { LabelInput } from '../../../../../../components';

import { useFormViability } from '../../../../../../hooks/formViability';

import api from '../../../../../../services/api';
import config from '../../../../../../services/auth';

const FormSociety = () =>{

    const { society, setSociety } = useFormViability();
    const [auxCpf, setAuxCpf] = useState<string>('');
    const [isPresent, setIsPresent] = useState<boolean>(false);
    let auxArray = [];

    async function compareCpf(){ //Comparar CPf digitado com algum que já existe no banco e setar no contexto
        const res = await api.get('/fictional-person', config);

        auxArray = res.data.slice(0);

        for(let i = 0; i < auxArray.length; i++){
            if(auxArray[i].cpf === auxCpf){
                setIsPresent(true);
                let info ={
                    ...society
                }

                info.cpf = auxCpf;
                info.name = auxArray[i].full_name;
                setSociety(info);
                return;
            }
        }
        if(isPresent === false){
            alert("CPF não encontrado!")
        }
    }

    useEffect(() => {
    }, [society])


    return (
        <Box display={'flex'} flexDirection={'column'}  width={'100%'}>
            <Alert message="Informe o CPF do Sócio" showIcon type="warning" />
            <FormEvents layout={'horizontal'} style={{width:'100%'}}>
                <Form.Item 
                    name={'society'}
                    style={{display: 'flex', flexDirection: 'column', width: '100%'}}
                    label={
                        <LabelInput 
                        style={{ color: '#333', fontWeight: 700, fontSize: '1.3em'}}
                        text={"Empreśario/Titular/Sócio(s)"} />
                    }
                    labelAlign={'left'}
                    rules={[
                        {
                        required: true,
                        message: 'obrigatório',
                        },
                    ]}
                    >
                        <Box 
                            display={'flex'} 
                            flexDirection={'row'} 
                            width={'100%'}
                        >
                            <Box display={'flex'} flexDirection={'column'} width={'35%'}>
                            <label htmlFor="" style={{fontWeight: 500}}>CPF: </label>
                                <Input
                                    required
                                    maxLength={11}
                                    size={'large'}
                                    style={{
                                    marginRight: '2em',
                                    width: '70%',
                                    maxWidth: '100%',
                                    }}
                                    onChange={e=>setAuxCpf(e.target.value)}
                                />
                            </Box>

                            <Box display={'flex'} flexDirection={'column'} width={'50%'}>
                                <label >Nome: </label>
                                <Input 
                                    size={'large'}
                                    style={{
                                    marginRight: '2em',
                                    width: '80%',
                                    maxWidth: '100%',
                                    }} 
                                    disabled
                                    value={society.name}
                                    />
                            </Box>
                            
                            <Button 
                            onClick={compareCpf}
                            size="large"
                            style={{ backgroundColor: '#27ae60',
                                    color: '#fff', 
                                    marginTop: '2%'
                            }}>
                                Adicionar Sócio
                            </Button>
                        
                        </Box>

                </Form.Item>
            </FormEvents>
            { isPresent && (
                <table>
                    <tr>
                        <th>CPF</th>
                        <th>Nome:</th>
                    </tr>
                    <tr>
                        <td>{society.cpf}</td>
                        <td>{society.name}</td>
                    </tr>
                </table>
            ) 
            
            }
        </Box>
    )
}

export {FormSociety};