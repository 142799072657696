interface DataProps{
    title: string;
    link: string;
    description: string;
}

export const Data:DataProps[] = [
    {
        title: 'Novo Registro',
        link: '/registro-digital/novo',
        description: 'Solicite novo processo de Registro Digital'
    },
    {
        title: 'Consultar Registro',
        link: '/registro-digital/',       
        description: ''
    },
    {
        title: 'Assinar Documentos',
        link: '/registro-digital/',       
        description: ''
    },
    {
        title: 'Enviar para a JUCEC',
        link: '/registro-digital/',       
        description: ''
    },
    {
        title: 'Validar Documentos',
        link: '/registro-digital/',       
        description: ''
    },
    {
        title: 'Consultar Solicitação',
        link: '/registro-digital/',       
        description: ''
    }
]