import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Events from '../Events';
import Info from '../Info';

const Routes = () => {
  return(
    <Switch>
      <Route 
        exact={true}
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc/'}
        component={Info}
      />

      <Route 
        exact={true}
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc/'}
        component={Events}
      />
    </Switch>
  )
}

export default Routes