import React, { useEffect, useState } from 'react'
import { ContainerCenter } from '../../../../../components/ContainerCenter';
import { Divider, Grid, makeStyles, Box } from '@material-ui/core';
import { Input, Collapse, Upload, Alert, Button, Modal } from 'antd';
import { FaArrowAltCircleRight, FaFile, FaPenSquare, FaSearch } from 'react-icons/fa';
import LabelInput from '../../../../../../components/LabelInput';
import './signature.css';
import { useHistory } from 'react-router-dom';
import { useProtocol } from '../../../../../../hooks/protocol';
import api from '../../../../../../services/api';
import config from '../../../../../../services/auth';
import { useFormIntegrator } from '../../../../../../hooks/formIntegrator';

const { Panel } = Collapse;
const { TextArea } = Input;


interface LabelI {
    text: string;
}
const LabelPanel = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#000000',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};

const useStyles = makeStyles(() => ({
    panel: {
        borderRadius: '2px',
        borderTop: '4px solid #28b3d6',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
    signature: {
        borderRadius: '2px',
        borderTop: '4px solid #27ae60',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
    table: {
        minWidth: 650,
    },
    input:{
        display: 'none',
    }
}));

const Message = () => {
    return(
        <Box display={'flex'} flexDirection={'column'}>
            <span>
                Nos casos em que este documento for apresentado assinado manualmente: ato empresarial visado por Órgão ou Entidade Governamental,
                transferência de cartório, ato empresarial oriundo de outra Junta Comercial, termos de renúncia, decisãoes judiciais e outros documentos de interesse
                deverá ser anexada a declaração, disponível automaticamente por este Sistema, atestando que o documento é verdadeiro e confere com o respectivo
                original, assinada digitalmente pelo empresário individual, titular da EIRELI, sócios, adminstradores ou requerente.
            </span>
        </Box>
    )
}

const Signature = () => {
    const classes = useStyles();
    const history = useHistory();
    const { protocol } = useProtocol();
    const { dae } = useFormIntegrator();
    const [ isSigned, setIsSigned ] = useState(false);
    const asigned = isSigned;
    const [nature, setNature] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [ cpf, setCpf ] = useState<string>('');
    const [ cnpj, setCnpj ] = useState<string>('');
    const [ personName, setPersonName ] = useState<string>('');

    async function handleData(){ //dados do integrador referente ao protocolo
        const res = await api.get(`/aikjunta/integrator/${protocol}`, config);
        setNature(res.data.legal_nature);
        setCompanyName(res.data.dbe.fantasy);
        setCpf(res.data.dbe.viability.fictional_person.cpf);
        setCnpj(res.data.dbe.viability.fictional_person.cnpj);
        setPersonName(res.data.dbe.viability.fictional_person.full_name);
        
    }

    function handleSigned(){ //função de assinar os documentos
        setIsSigned(true);
        if(cnpj)
        activeCompany();
        
    }

    async function activeCompany(){
        await api.put(`/fictional-company/update-is-active/${cnpj}`, { is_active: true }, config);
        
    }



    async function submitData(){ //POST do registro digital
        const data = {
            integrator_module_protocol: protocol,
            signature: isSigned,
            dae: dae,
            is_active: true
        }
        
        const response = await api.post(`/aikjunta/digital-registry/`, data, config)
        if(response.status === 200){
            Modal.success({
                content: "Documentos assinados com sucesso!"
            })
        }

        history.push('/registro-digital/novo/data/assinatura/complete')
    }

    //Data de anexo dos documentos
    const today = new Date();
    const month = today.getMonth() + 1;

    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(()=>{
        handleData()
        
    }, [cnpj])
    
    /* eslint-enable react-hooks/exhaustive-deps */

    return(
        <ContainerCenter title="Registro Digital - Assinantes" icon={FaPenSquare} buttonBack>
             <br />
            <p style={{marginLeft: 20, fontWeight: 'bold'}}>Protocolo Registro Digital: </p>
            <Input style={{width: 300, marginLeft: 20}}/>
            <br/> <br/>
            <Divider />
            <br />
            <div className="div-table">
            <b style={{ marginLeft: 10 }}>Dados: </b> <br />

           
            <table style={{ marginLeft: 10 }} className="first-table">
                <tr className="data-principal">
                    <td >Protocolo Módulo Integrador: &emsp;</td>
                    <td>{protocol}</td>
                </tr>
                <tr>
                    <td>Nome da Empresa: </td>
                    <td>{companyName}</td>
                </tr>
                <tr className="data-principal">
                    <td>Natureza Jurídica: </td>
                    <td>{nature}</td>
                </tr>
                <tr>
                    <td>Ato: </td>
                    <td>080 - Inscrição</td>
                </tr>
            </table>
            <br />
            <Divider /> <br/>
            <Grid item xs={12}>
                <Collapse defaultActiveKey={'1'}>
                    <Panel 
                        key={'1'}
                        header={<><FaPenSquare/> <LabelPanel text={'Assinar'}/>   </>}
                        className={classes.signature}
                    >
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Button size='large' icon={<FaPenSquare/>} type='primary' style={{backgroundColor: '#27ae60', border: 'none'}} onClick={handleSigned}> &emsp;Assinar</Button>
                        <Button size='large' icon={<FaPenSquare/>} type='default' onClick={handleSigned}>&emsp;Assinar com Java 64-bits</Button>
                    </div>

                    </Panel>
                </Collapse>
            </Grid> <br/>
            <Divider />
            <br/>
            <Grid item xs={12} >
                <Collapse defaultActiveKey={'1'}>
                    <Panel
                        key={'1'}
                        header={<><FaFile /> <LabelPanel text={'Capa Do Processo'} /></>}
                        className={classes.panel}
                    >
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                            <FaFile style={{ height: 60, width: 40, marginLeft: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                <p><strong>Usuário: </strong>{personName}</p>
                                <p><strong>Data anexo imagem: </strong>{' ' + today.getDate() + '/' + month + '/' + today.getFullYear() + ''}</p>
                                <p><strong>Observação: </strong> Este documento foi gerado pelo Módulo Integrador não podendo ser removido</p>
                            </div>
                        </div>
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginTop: -100 }}>
                            <Button size="middle" icon={<FaPenSquare />} type="primary" style={{ backgroundColor: '#27ae60', color: '#fff', border: 'none' }}>Adicionar Assinante</Button> &emsp;
                            <Button size="middle" icon={<FaSearch />} type="primary">Visualizar Documento</Button>
                        </div>
                        
                        <table>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Assinou</th>
                            </tr>
                            <tr>
                                <td>{personName}</td>
                                <td>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                <td style={{
                                    color: asigned === false ? '#cc1515' : 'rgb(77, 203, 77)',
                                    fontWeight: 'bold'
                                }}>{ asigned === false ? 'Não' : 'Sim' }</td>
                            </tr>
                        </table>

                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Divider/> <br/>
            <Grid item xs={12} >
                <Collapse defaultActiveKey={'1'}>
                    <Panel
                        key={'1'}
                        header={<><FaFile /> <LabelPanel text={'Documento Principal'} /></>}
                        className={classes.panel}
                    >
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                            <FaFile style={{ height: 60, width: 40, marginLeft: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                <p><strong>Usuário: </strong> {personName}</p>
                                <p><strong>Data anexo imagem: </strong>{' ' + today.getDate() + '/' + month + '/' + today.getFullYear() + ''}</p>
                                <p><strong>Observação: </strong> Este documento foi gerado pelo Módulo Integrador não podendo ser removido</p>
                            </div>
                        </div>
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginBottom: 100, marginTop: -110}}>   
                           <Upload >
                               <Button type="primary" size="middle" icon={<FaSearch />} style={{width: 200}}>Adicionar Arquivo</Button>
                           </Upload>
                           
                        </div>
                        <table>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Assinou</th>
                            </tr>
                            <tr>
                                <td>{personName}</td>
                                <td>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                <td style={{
                                    color: asigned === false ? '#cc1515' : 'rgb(77, 203, 77)',
                                    fontWeight: 'bold'
                                }}>{ asigned === false ? 'Não' : 'Sim' }</td>
                            </tr>
                        </table>

                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Divider/> <br/>
            <Grid item xs={12} >
                <Collapse defaultActiveKey={'1'}>
                    <Panel
                        key={'1'}
                        header={<><FaFile /> <LabelPanel text={'Declaração de Veracidade Documento Principal'} /></>}
                        className={classes.panel}
                    >
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', width: 900 }} >
                            <Alert showIcon type="info" message={<Message/>} />
                        </div>
                        <table>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Assinou</th>
                            </tr>
                            <tr>
                                <td>{personName}</td>
                                <td>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                <td style={{
                                    color: asigned === false ? '#cc1515' : 'rgb(77, 203, 77)',
                                    fontWeight: 'bold'
                                }}
                                >{ asigned === false ? 'Não' : 'Sim' }</td>
                            </tr>
                        </table>
                               
                        <br/>
                    </Panel>
                </Collapse>
            </Grid> <br/>
            <Divider /> <br/>
            <Grid item xs={12}>
                <Collapse defaultActiveKey={'1'}>
                    <Panel 
                        key={'1'}
                        header={<><FaFile/>  <LabelPanel text={'Anexo(s)'}/> </>}
                        className={classes.panel}
                    >
                         <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', width: 900 }} >
                            <Alert showIcon type="info" message={"Os arquivos devem ser enviados em formato PDF. Após o envio de todos os arquivos, será gerado um único documento."} />
                        </div>
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginTop: -30}}>   
                           <Upload >
                               <Button type="primary" size="middle" icon={<FaSearch />} style={{width: 200}}>Adicionar Arquivo</Button>
                           </Upload>
                        </div>

                        <table>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Assinou</th>
                            </tr>
                            <tr>
                                <td>{personName}</td>
                                <td>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                <td style={{
                                    color: asigned === false ? '#cc1515' : 'rgb(77, 203, 77)',
                                    fontWeight: 'bold'
                                }}>{ asigned === false ? 'Não' : 'Sim' }</td>
                            </tr>
                        </table>
                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Divider /> <br/>
            <Grid item xs={12}>
                <Collapse defaultActiveKey={'1'}>
                    <Panel 
                        key={'1'}
                        header={<><FaFile/>  <LabelPanel text={'Observação'}/> </>}
                        className={classes.panel}
                    >
                         <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', width: 900 }} >
                           <p>Utilize este campo, caso queira enviar alguma mensagem ou observação ao analista da Junta Comercial:</p>
                        </div>
                       
                        <TextArea rows={8} />
                        <br/>
                        <Button size="large" type="primary">Enviar</Button>
                        
                    </Panel>
                </Collapse>
            </Grid>
            </div>
            <Button 
                size="large" 
                type="primary" 
                style={{
                    backgroundColor:"#27aa3d", 
                    border: 'none', 
                    float: 'right', 
                    marginRight: 10
                }}
                icon={<FaArrowAltCircleRight/>}
                onClick={submitData}
            >&emsp;Enviar para a Junta</Button>
            <br/>
        </ContainerCenter>
    )
}

export default Signature;