import React from 'react';
import { FaCheck, FaRegCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useProtocol } from '../../../../../../hooks/protocol';
import { ContainerCenter } from '../../../../../components/ContainerCenter';

import './success.css';

const Success = () => {
    const history = useHistory();
    const { protocol } = useProtocol();

    
    return( 
        <ContainerCenter title={'Registro Digital - Dados da Solicitação'} icon={FaCheck}>
            <div className="div-check">
                <div className="div-icon">
                    <FaRegCheckCircle className="icon"  /> 
                </div>
                <div className="div-p">
                    <p>Processo enviado para a Junta Comercial</p>
                    <h5>Guarde este número para consultar o andamento da solicitação <br/> que poderá ser feita em ...</h5>
                </div>
                <div className="div-protocol">
                    <div className="protocol-bar">
                        <p>Protocolo Registro Digital</p>
                        <h1>{protocol}</h1>
                    </div>
                </div>
                
                <button className="btn" onClick={()=>history.push('/principal')}>Início</button>
            </div>
        </ContainerCenter>
    )
}

export default Success;