import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FaList } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../components/ContainerCenter';
import { DataCard } from './models/CardsData';
import { IconCards } from '../AlreadyExist/components/IconCards'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCards: {
    margin: theme.spacing(2),
    padding: 'unset',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));


const AlreadyExist = () => {
  const classes = useStyles();
  return (
    <ContainerCenter
      title={'Já Possuo Pessoa Jurídica'}
      icon={FaList}>

      <Grid container spacing={2} className={classes.gridCards}>
        {DataCard.map((item, index) => {
          return (
            <Grid item key={index.toString()}>
              <IconCards
                icon={item.icon}
                title={item.title}
                link={item.link}
              />
            </Grid>
          );
        })}
      </Grid>


    </ContainerCenter>
  )
}

export default AlreadyExist;