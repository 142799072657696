import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Grid, makeStyles, Box } from '@material-ui/core';
import { Button, Input, Collapse, Alert } from 'antd';
import { FaFile, FaPenSquare, FaSearch } from 'react-icons/fa';
import LabelInput from '../../../../../../components/LabelInput';
import { ContainerCenter } from '../../../../../components/ContainerCenter';
import Declaration from '../Declaration';
import { useStates } from '../../hooks/states';
import { useHistory } from 'react-router-dom';
import './styles.css';
import { useShowData } from '../../hooks/showData';
import { useProtocol } from '../../../../../../hooks/protocol';
import api from '../../../../../../services/api';
import config from '../../../../../../services/auth';

const { Panel } = Collapse;
const { TextArea } = Input;


interface LabelI {
    text: string;
}
const LabelPanel = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#000000',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};

const useStyles = makeStyles(() => ({
    panel: {
        borderRadius: '2px',
        borderTop: '4px solid #28b3d6',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
}));

const Message = () => {
    return(
        <Box display={'flex'} flexDirection={'column'}>
            <span>
                Nos casos em que este documento for apresentado assinado manualmente: ato empresarial visado por Órgão ou Entidade Governamental,
                transferência de cartório, ato empresarial oriundo de outra Junta Comercial, termos de renúncia, decisãoes judiciais e outros documentos de interesse
                deverá ser anexada a declaração, disponível automaticamente por este Sistema, atestando que o documento é verdadeiro e confere com o respectivo
                original, assinada digitalmente pelo empresário individual, titular da EIRELI, sócios, adminstradores ou requerente.
            </span>
        </Box>
    )
}



const DataRegister = () => {
    const classes = useStyles();
    const history = useHistory();
    const { state, setState  } = useStates();
    const { show } = useShowData();
    const { protocol } = useProtocol();
    const [ fileName, setFileName ] = useState('');
    const [ fileSize, setFileSize ] = useState<number>();
    const [ fileName2, setFileName2 ] = useState('');
    const [ fileSize2, setFileSize2 ] = useState<number>();
    const [nature, setNature] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [ cpf, setCpf ] = useState<string>('');
    const [ rg, setRg ] = useState<string>('');
    const [ personName, setPersonName ] = useState<string>('');


    async function handleData(){ //pegar dados do integrador referente ao protocolo
        const res = await api.get(`/aikjunta/integrator/${protocol}`, config);
    
        setNature(res.data.legal_nature);
        setCompanyName(res.data.dbe.fantasy);
        setPersonName(res.data.dbe.viability.fictional_person.full_name);
        setCpf(res.data.dbe.viability.fictional_person.cpf);
        setRg(res.data.dbe.viability.fictional_person.rg);
    }

    function openModal(){ //abrir modal de veracidade de assinatura
        setState(true);
    }

    function handleNext(){
        history.push('/registro-digital/novo/data/assinatura')
    }

    function handleUpload(e: any){ //pegar nome e tamanho do arquivo escolhido
        setFileSize(e.target.files[0].size / (1024 * 1024));
        setFileName(e.target.files[0].name);
    }

    function upload2(e: any){// pegar nome e tamanho do arquivo escolhido
        setFileSize2(e.target.files[0].size / (1024 * 1024));
        setFileName2(e.target.files[0].name);
    }

    useEffect(()=> {
        handleData()
    })
    
    return (
        <ContainerCenter 
            title="Registro Digital - Documentos/Assinantes" 
            icon={FaPenSquare}
            buttonBack   
        >

            <br />
            <Divider />
            <br />
            <b style={{ marginLeft: 30 }}>Dados: </b> <br />

            <table style={{ marginLeft: 30 }}>
                <tr className="data-principal">
                    <td >Protocolo Módulo Integrador: &emsp;</td>
                    <td>{protocol}</td>
                </tr>
                <tr>
                    <td>Nome da Empresa: </td>
                    <td>{companyName}</td>
                </tr>
                <tr className="data-principal">
                    <td>Natureza Jurídica: </td>
                    <td>{nature}</td>
                </tr>
                <tr>
                    <td>Ato: </td>
                    <td>080 - Inscrição</td>
                </tr>
            </table>
            <br />
            <Divider />
            <Grid item xs={12} >
                <Collapse defaultActiveKey={'1'}>
                    <Panel
                        key={'1'}
                        header={<><FaFile /> <LabelPanel text={'Capa Do Processo'} /></>}
                        className={classes.panel}
                    >
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                            <FaFile style={{ height: 60, width: 40, marginLeft: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                <p><strong>Usuário: </strong> {personName}</p>
                                <p><strong>Data anexo imagem: </strong>22/02/2021</p>
                                <p><strong>Observação: </strong> Este documento foi gerado pelo Módulo Integrador não podendo ser removido</p>
                            </div>
                        </div>
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginTop: -100 }}>
                            <Button size="middle" icon={<FaPenSquare />} type="primary" style={{ backgroundColor: '#27ae60', color: '#fff', border: 'none' }}>Adicionar Assinante</Button> &emsp;
                            <Button size="middle" icon={<FaSearch />} type="primary">Visualizar Documento</Button>
                        </div>

                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Divider/> <br/>
            <Grid item xs={12} >
                <Collapse defaultActiveKey={'1'}>
                    <Panel
                        key={'1'}
                        header={<><FaFile /> <LabelPanel text={'Documento Principal'} /></>}
                        className={classes.panel}
                    >
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row' }} className="div-file" >
                            <FaFile style={{ height: 60, width: 40, marginLeft: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15 }}>
                                <p><strong>Usuário: </strong> {personName}</p>
                                <p><strong>Data anexo imagem: </strong>22/02/2021</p>
                                {
                                    fileName &&
                                    <>
                                    <p>Nome: {fileName}</p>
                                    <p>Tamanho do arquivo: {fileSize?.toFixed(2)} MB</p>
                                    </>
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginBottom: 50, marginTop: -50}}>   
                           <label htmlFor="upload-btn1" className="up">Adicionar Arquivo</label>
                           <input type="file" id="upload-btn1" onChange={e=>handleUpload(e)}/>
                        </div>

                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Divider/> <br/>
            <Grid item xs={12} >
                <Collapse defaultActiveKey={'1'}>
                    <Panel
                        key={'1'}
                        header={<><FaFile /> <LabelPanel text={'Declaração de Veracidade Documento Principal'} /></>}
                        className={classes.panel}
                    >
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', width: 900 }} >
                            <Alert showIcon type="info" message={<Message/>} />
                        </div>
                        <div style={{ marginLeft: 5, display: 'flex', flexDirection: 'row', marginTop: 10 }} >
                            {
                                show &&
                                <table style={{ marginLeft: 30 }}>
                                    <tr className="data-principal">
                                        <th>Declaração: </th>
                                        <th>Nome: </th>
                                        <th>CPF: </th>
                                        <th>Identidade</th>
                                    </tr>
                                    <tr>
                                        <td>Declaração Documento Principal</td>
                                        <td>{personName}</td>
                                        <td>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</td>
                                        <td>{rg}</td>
                                    </tr>
                                </table>
                                
                            }
                        </div>
                        
                       
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginBottom: 200, marginTop: -190}}>   
                            <Button size="middle" icon={<FaPenSquare />} type="primary" style={{ backgroundColor: '#27ae60', color: '#fff', border: 'none', position: 'absolute' }} onClick={openModal}>Cadastrar Declaração</Button>
                            <Declaration isOpen={state}/>                             
                        </div><br/>
                    </Panel>
                </Collapse>
            </Grid> <br/>
            <Divider /> <br/>
            <Grid item xs={12}>
                <Collapse defaultActiveKey={'1'}>
                    <Panel 
                        key={'1'}
                        header={<><FaFile/>  <LabelPanel text={'Anexo(s)'}/> </>}
                        className={classes.panel}
                    >
                         <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', width: 900 }} >
                            <Alert showIcon type="info" message={"Os arquivos devem ser enviados em formato PDF. Após o envio de todos os arquivos, será gerado um único documento."} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, width: 700, float: 'left' }}>
                                {
                                    fileName2 &&
                                    <Fragment>
                                    <div style={{float: 'left'}}>
                                        <FaFile style={{ height: 60, width: 40, marginLeft: 10 }} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column',float: 'right' }}>
                                        <p><strong>Usuário: </strong> {personName}</p>
                                        <p><strong>Data anexo imagem: </strong>22/02/2021</p>
                                        <p>Nome: {fileName2}</p>
                                        <p>Tamanho do arquivo: {fileSize2?.toFixed(2)} MB</p>
                                    </div>
                                    </Fragment>
                                }
                            </div>
                        <div style={{ marginLeft: 1000, display: 'flex', flexDirection: 'column', marginTop: -30, float: 'right'}}>   
                        
                        <label htmlFor="upload-btn2" className="up">Adicionar Arquivo</label>
                        <input type="file" id="upload-btn2" onChange={e=>upload2(e)}/>
                            
                           
                        </div>
                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Divider /> <br/>
            <Grid item xs={12}>
                <Collapse defaultActiveKey={'1'}>
                    <Panel 
                        key={'1'}
                        header={<><FaFile/>  <LabelPanel text={'Observação'}/> </>}
                        className={classes.panel}
                    >
                         <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', width: 900 }} >
                           <p>Utilize este campo, caso queira enviar alguma mensagem ou observação ao analista da Junta Comercial:</p>
                        </div>
                       
                        <TextArea rows={8} />
                        <Button size="middle" type="primary">Enviar</Button>
                        
                    </Panel>
                </Collapse>
            </Grid>
            <br/>
            <Button size="large" 
                type="primary" 
                onClick={handleNext}
                style={{marginLeft: 1100, display: 'inline-flex', marginBottom: -200}}
            >Avançar</Button>

        </ContainerCenter>
    )
}

export default DataRegister;