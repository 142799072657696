import React from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Captcha from '../../../../../../../../assets/dbe/captcha.png'


const Container = styled.div`
  width: 100%;
  height: 30vh;
`

const LeftContainer = styled.div`
  width: 38%;
  display: inline-block;
  font-size: 18px;
  margin-left: 27px;
`
const RightContainer = styled.div`
  width: 38%;
  display: inline-block;
  float: right;
  font-size: 18px;
`

const Recover = () => {
  return(
    <ContainerCenter title={"Coletor Nacional"} icon={FaExchangeAlt}>
      <Container>
        <LeftContainer>
          <p>UF: &emsp;<strong> CE </strong></p>
          
          <p>Município: &emsp;<strong> CRATEUS </strong></p>
          
          <label> CNPJ: </label>
          <input type="text" name="cnpj"/>
        </LeftContainer>
        
        <RightContainer>
          <p>Digite os caracteres abaixo: </p>
          <img src={Captcha} alt="captcha"/> <br/> <br/>
          <input type="text"/>
        </RightContainer>        
      </Container>

      <Link to="/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc" style={{marginLeft: "500px", fontSize:"20px"}}>Iniciar</Link>
    </ContainerCenter>
  )
}

export default Recover