import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FaList } from 'react-icons/fa';
import { cardsData } from './models/cardsData';
import { CardPhotos } from './pages/business/components/CardPhotos';
import { ContainerCenter } from '../components/ContainerCenter';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCards: {
    margin: theme.spacing(2),
    padding: 'unset',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));

const Dbe = () => {
  const classes = useStyles();
  return (
    <ContainerCenter
      title={'Serviços Governamentais'}
      icon={FaList}
    >
      <Grid container spacing={2} className={classes.gridCards}>
        {cardsData.map((item, index) => {
          return (
            <Grid item key={index.toString()}>
              <CardPhotos
                backgroundImg={item.backgroundImg}
                description={item.description}
                title={item.title}
                link={item.link}
              />
            </Grid>
          );
        })}
      </Grid>
    </ContainerCenter>
  );
};

export default Dbe;
