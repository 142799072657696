import { FaBook, FaCheckCircle, FaCloudDownloadAlt, FaFile, FaSignOutAlt } from 'react-icons/fa';


interface Data{
  title: string;
  icon: React.FunctionComponent<any>;
  link: string;
}

export const MenuData: Data[]= [
  {
    title: "Instruções",
    icon: FaBook,
    link: '/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/'
  },
  {
    title: "Resumo do Documento",
    icon: FaFile,
    link: ''
  },
  {
    title: "Verificar Pendências",
    icon: FaCheckCircle,
    link: '/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/pendencies'
  },
  {
    title: "Finalizar Preenchimento",
    icon: FaCloudDownloadAlt,
    link: '/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/finalizar'
  },
  {
    title: "Sair",
    icon: FaSignOutAlt,
    link: ''
  }
]