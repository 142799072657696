import { Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { FaMapMarker } from 'react-icons/fa';
import styled from 'styled-components';
import { useCep } from '../../../../../../../../../../../../hooks/cep';
import { useFormViability } from '../../../../../../../../../../../../hooks/formViability';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';

import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`


const AdressPJ: React.FC = () => {

    const { address, setAddress } = useFormViability();
    const { protocol } = useProtocol();
    const { cep, setCep } = useCep();
    const [ log_type, setLog_Type ] = useState('');
    const [ log, setLog ] = useState('');
    const [ city, setCity ] = useState('');
    const [ number, setNumber ] = useState('');
    const [ comp, setComp ] = useState('');
    const [ uf, ] = useState('');
    const [ neighborhood,  ] = useState('');
    

    async function handleAddress(){
        const response = await api.get(`/aikjunta/viability/${protocol}`, config);
        
        let code = response.data.viability_address.postal_code;
        let logtype = response.data.viability_address.public_place_type;
        let Log = response.data.viability_address.public_place;
        let City = response.data.viability_address.city;
        let num = response.data.viability_address.num;
        let complement = response.data.viability_address.complement;

        setCep(code);
        setLog_Type(logtype);
        setLog(Log);
        setCity(City);
        setNumber(num);
        setComp(complement);
    }
    
    function handleLog(e: string){
        let lograd = {
            ...address
        }
        lograd.refLog = e;
        setAddress(lograd);
    }

    useEffect(()=> {
        handleAddress();
    })

    return (
        <ContainerCenter title={'Endereço da Pessoa Jurídica'} icon={FaMapMarker}>
            <Header />

            <DivLeft>
                <FCPJ />
            </DivLeft>

            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Endereço da Pessoa Jurídica</p>

                    <div style={{ marginLeft: 10 }}>
                        <Label>CEP: </Label> <br />
                        <Input disabled value={cep} style={{ width: 150 }} /> <br /> <br />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <Label>Tipo de Logradouro: </Label>
                            <Select disabled value={log_type} style={{ width: 100 }} /> <br />

                            <Label>Logradouro: </Label>
                            <Input disabled value={log} style={{ width: 300, marginRight: 10 }} /> <br />

                            <Label>Bairro/Distrito:  </Label>
                            <Input disabled value={neighborhood} style={{ width: 300 }} /> <br />

                            <Label>Distrito: </Label>
                            <Input disabled style={{ width: 100 }} /> <br />

                            <Label>Município: </Label>
                            <Input disabled value={city} style={{ width: 150 }} /> <br />

                            <Label>UF: </Label>
                            <Input disabled value={uf} style={{ width: 100 }} /> <br />

                            <Label>Número: </Label>
                            <Input disabled value={number} style={{ width: 100 }} /> <br />

                            <Label>Complemento: </Label>
                            <Input disabled value={comp} style={{ width: 200 }} /> <br />

                            <Label>Referência: </Label>
                            <TextArea value={address.refLog} onChange={e=> handleLog(e.target.value)} rows={3} /> <br /><br />
                        </div>
                    </div>


                </div>
            </DivRight>
        </ContainerCenter>
    );
}

export default AdressPJ;