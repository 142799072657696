import React from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
const { Title } = Typography;

interface SectionTitleProps extends TitleProps {
  text: string;
}
const SectionTitle = ({ text, ...props }: SectionTitleProps) => {
  return <Title {...props}>{text}</Title>;
};
export default SectionTitle;
