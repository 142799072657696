import React, { useEffect, useState } from 'react';
import { FaRegistered } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import jsPDF from 'jspdf'
import * as htmlToImage from 'html-to-image'
import { Modal, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { useCallback } from 'react';



const Receipt: React.FC = () => {
  const history = useHistory();
  const { protocol } = useProtocol();
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [UF, setUF] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  
  const onButtonClick = () => {
    const elem = document.getElementById("recipiet")!;
    htmlToImage.toPng(elem)
    .then(function(dataUrl){
      const pdf = new jsPDF()
      pdf.addImage(dataUrl, 'PNG', 0, 0, 0, 0)
      pdf.save('Recibo.pdf')
    })

    Modal.success({
      content: 'Protocolo REDESIM gerado com sucesso!'
    })
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  
  const getData = useCallback (async () => {
    let res = await api.get(`/aikjunta/dbe/${protocol}`,config)
    let {city} = res.data.viability.viability_address
    let uf = res.data.viability.viability_protocol
    uf = uf.slice(0,2)
    let {cpf} = res.data.viability.fictional_person
    let {company_name} = res.data.viability.fictional_person
    setCpf(cpf)
    setName(company_name)
    setCompanyCity(city)
    setUF(uf)
  },[])

  /* eslint-enable react-hooks/exhaustive-deps */
  
  useEffect(()=> {
    getData()
  })

  const hoje = new Date();
  const mes = hoje.getMonth() + 1;

  return(
      <ContainerCenter title={'Coletor Nacional'} icon={FaRegistered}>
          <div style={{marginLeft: 27}} id="recipiet">
            <p><strong>Recibo de Transmissão da Solicitação</strong></p>
            <p style={{backgroundColor: '#3bbaf5', width: 1180}}>Tipo de Documento</p>
            <p>Inscrição de Primeiro Estabelecimento</p>
            <p style={{backgroundColor: '#3bbaf5', width: 1180}}>CPF do Declarante: </p>
            <p>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</p>
            <p style={{backgroundColor: '#3bbaf5', width: 1180}}>Nome Empresarial</p>
            <p>{name}</p>
            <p style={{backgroundColor: '#3bbaf5', width: 1180}}>Município</p>
            <p>{companyCity.toLocaleUpperCase()}</p>
            <p style={{backgroundColor: '#3bbaf5', width: 1180}}>UF</p>
            <p>{UF}</p> <br/>

            <p><strong>Protocolo REDESIM: {protocol}</strong></p>

            <p>Solicitação recebido via Internet pelo Agente SERPRO em 
              {' '+ hoje.getDate() + "/" + mes + "/" + hoje.getFullYear() + " "} 
              às 
              {' ' + hoje.getHours() + ":" + hoje.getMinutes()} </p>
            
          </div>
          
          <Button onClick={()=>history.push('/principal')} type="primary" size='large' style={{marginLeft: 20}}>Início</Button>
          <Button onClick={onButtonClick} icon={<DownloadOutlined/>} size='large' type="primary" style={{marginLeft: 1000, marginBottom: 15, backgroundColor: '#37af19', border: 'none'}}>Baixar</Button>

          
      </ContainerCenter>
  );
}

export default Receipt;