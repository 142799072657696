import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`


const SocialObject: React.FC = () => {

    const { protocol } = useProtocol();
    const [object, setObject] = useState('');

    async function handleSocialObject(){
        const response = await api.get(`/aikjunta/viability/${protocol}`, config);     
        setObject(response.data.social_object)
    }

    useEffect(()=> {
        handleSocialObject();
    })


    return (
        <ContainerCenter title={'Objeto Social'} icon={FaPencilAlt}>
            <Header />
            <DivLeft>
                <FCPJ />
            </DivLeft>
            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>

                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Objeto Social</p>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                    <Label>Descrição do Objeto Social</Label>
                    <TextArea rows={5} disabled value={object}/>
                    </div>
                
                </div>
            </DivRight>
        </ContainerCenter>
    );
}

export default SocialObject;