import React, { Fragment } from 'react';
import { Grid,
    makeStyles,
    Paper,
    Box,
    Container,
    Divider,
} 
from '@material-ui/core';
import { Card } from '../../components';
import { Data } from '../digital-registry/models/Data'
import { FaInfoCircle, FaPenSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '100%',
      padding: theme.spacing(1),
    },
    box: {
      padding: theme.spacing(0),
    },
    container: {
      marginTop: theme.spacing(3),
      // padding: theme.spacing(3),
    },
    userBox: {
      margin: theme.spacing(2),
      },
  }));
const Digital = () => {
    const classes = useStyles();
    return (
        <Fragment>
        <Grid container className={classes.root}>
          <Grid item lg={8}>
            <Paper className={classes.paper} variant={'elevation'} elevation={2}>
              <Container className={classes.container}>
                <Box display={'flex'}>
                  <h1> <FaPenSquare/>  Registro Digital</h1>
                </Box>
                <p style={{marginLeft: '2%'}}>Você está em: <Link to="/principal">Portal de serviços</Link> / Integrador </p>

                <Divider style={{ marginBottom: '1.2em' }} />
                <Grid container spacing={3} className={classes.box}>
                  {Data.map((item, index) => {
                    return (
                      <Grid item key={index}>
                        <Card
                          description={item.description}
                          title={item.title}
                          url={item.link}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Container>
            </Paper>
          </Grid>
  
          <Grid item lg={2} className={classes.userBox}>
            <Paper>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 10}}>
                <FaInfoCircle style={{width: 30, height: 30, marginLeft: 20}}/> &emsp;
                <h3> <strong>Meu Cadastro</strong> </h3>
              </div>
              <Divider style={{width: '90%', marginLeft: 10, marginTop: 10}} />
              <br/>
              <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                <h3><strong>Nome: </strong> </h3>
                <p>Rafael Santos Sousa</p>
                <h3><strong>CPF: </strong></h3>
                <p>000.000.000-00</p>
                <h3><strong>E-mail: </strong></h3>
                <p>rhafa99ss@gmail.com</p>
                <h3><strong>Telefone: </strong></h3>
                <p>(88) 99999-9999</p>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    )
}

export default Digital;