import React, { useEffect, useState } from 'react';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';
import { Input, Select, Radio, Checkbox, Row, Col } from 'antd';
import styled from 'styled-components';
import { FaDollarSign } from 'react-icons/fa';
import api from '../../../../../../../../../../../../services/api';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import config from '../../../../../../../../../../../../services/auth';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`

const EconomicActivity: React.FC = () => {

    const [value, setValue] = useState([]);
    const [cnae, setCnae] = useState<string>();
    const [ radio, setRadio ] = useState<number>();
    const { protocol } = useProtocol();
    let arr:any = [];

    async function searchData(){
        const response = await api.get(`/aikjunta/viability/${protocol}`, config);
        const activity = response.data.viability_activity[0].id;
        crossCnae(activity);


        if(response.data.unit === 'produtiva'){
            setRadio(1)
        }else{
            setRadio(2);
        }

        arr = response.data.way_of_actings.map((item: any) => item.id);

        setValue(arr);
    }

    async function crossCnae(cnae: number){
        const res = await api.get(`/aikmei/economic-activity/${cnae}`, config)
        setCnae(res.data.cnae);
    }

    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(()=> {
        searchData();
    }, value)

    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <ContainerCenter title={'Atividade Econômica'} icon={FaDollarSign}>
            <Header />
            <DivLeft>
                <FCPJ />
            </DivLeft>

            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Atividade Econômica</p>

                    <div style={{ marginLeft: 10 }}>
                        <Label>Código CNAE Principal: </Label> <br />
                        <Input value={cnae} style={{ width: 100 }} disabled /> <br /><br />
                        <Label>Desdobramento da CNAE Principal: </Label> <br />
                        <Select disabled style={{ width: 50 }} /> <br /><br />
                        <hr />
                        

                        <Label>CNPJ do Administrador: </Label> <br />
                        <Input disabled style={{ width: 200 }} /> <br /> <br />

                        <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Tipo de Unidade</p>
                        <div style={{ display: 'flex' }}>
                            <Radio.Group value={radio} disabled>
                                <Radio value={1}>
                                    Unidade Produtiva
                                </Radio>

                                <Radio value={2}>
                                    Unidade Auxiliar
                                </Radio>
                                <Select disabled style={{ width: 100, marginBottom: 10 }} />
                            </Radio.Group>
                        </div>

                        <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Forma de Atuação</p>
                        <div>
                            <Checkbox.Group 
                                style={{ width: '100%', 
                                    display: 'flex', 
                                    flexDirection: 'column' 
                                }} 
                                value={value} 
                                disabled
                                >
                                <Row>
                                    <Col span={12}>
                                        <Checkbox value={1}>Estabelecimento Fixo</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={5}>Porta a Porta, Postos Móveis ou por Ambulantes</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={2}>Internet</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={6}>Televendas</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={3}>Em Local Fixo Fora da Loja</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={7}>Máquinas Automáticas</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={4}>Correio</Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value={8}>Atividade Desenvolvida for do Estabelecimento</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group> <br /><br />
                        </div>
                    </div>
                </div>
            </DivRight>
        </ContainerCenter>
    );
}

export default EconomicActivity;