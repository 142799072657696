import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { FaPlus } from 'react-icons/fa'
import { ContainerCenter } from '../../../../../components/ContainerCenter'
import { CardData } from './model/CardPJ'
import CardContainer from './components/CardContainer'

const useStyles = makeStyles((theme) => ({
  gridCards: {
    margin: theme.spacing(2),
    padding: 'unset',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  }
}));


const Open = () => {
  const classes = useStyles();
  return (
    <ContainerCenter title={'Abra sua Pessoa Jurídica'} icon={FaPlus}>
      <Grid container spacing={2} className={classes.gridCards}>
          {CardData.map((item, index) => {
            return(
              <Grid item key={index.toString()}>
                <CardContainer 
                  icon={item.icon}
                  title={item.title}
                  link={item.link}
                  />
              </Grid>
            )
          })}
      </Grid>
    </ContainerCenter>
  )
}

export default Open