import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FaList } from 'react-icons/fa';
import { cardsData } from './model/cardsData';
import { CardViability } from './components/CardViability';
import { ContainerCenter } from '../components/ContainerCenter';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCards: {
    margin: theme.spacing(2),
  },
  // divider: {
  //   '&::after': {
  //     content: 'T',
  //     display: 'block',
  //     height: '50%',
  //     width: '1px',
  //     backgroundColor: 'blue',
  //     position: 'absolute',
  //     top: '25%',
  //     left: 0,
  //     marginLeft: '38%',
  //   },
  // },
}));

const Viability = () => {
  const classes = useStyles();
  return (
    <ContainerCenter
      title={'Serviços disponíveis'}
      icon={FaList}
      message={
        'A Viabilidade é uma pesquisa antecipada nos registros da JUCEC sobre a existência de empresas constituídas com nomes empresariais idênticos ou semelhantes ao nome pesquisado, é realizada também a consulta prévia de endereço pela prefeitura nos municípios integrados.'
      }
    >
       <Grid item xs={12}>
         <br/> <p style={{marginLeft: '2%'}}>Você está em: <Link to="/principal">Portal de serviços</Link> / Viabilidade </p>
      </Grid>
      <Grid container spacing={2} className={classes.gridCards}>
        {cardsData.map((item, index) => {
          return (
            <Grid item key={index.toString()}>
              <CardViability
                component={item.component}
                title={item.title}
                url={item.link}
              />
            </Grid>
          );
        })}
      </Grid>
    </ContainerCenter>
  );
};

export default Viability;
