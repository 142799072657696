import { Modal } from 'antd'

function ModalMessage(message: string){

  return(
      Modal.success({
        content: `${message}`
      })
    );
}

export default ModalMessage;