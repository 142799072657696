// management screen flow for each form selected

 export interface FlowI  { 
  route:string;
  
}
interface FlowIdI { 
  id:number;
  flow:FlowI[];
}

const MATRIZ :FlowI[]= [ 
  { 
    route:'/viabilidade/eventos/coleta/natureza-juridica'
  }, 
  { 
    route:'/viabilidade/eventos/coleta/cep-viabilidade'
  }, 
  { 
    route:'/viabilidade/eventos/coleta/coleta-dados'
  },
  { 
    route:'/viabilidade/eventos/coleta/coleta-atividade' // identificação da empresa 
  }, 
  
  { 
    route:'/viabilidade/eventos/confirmação-dados'
  },
  
]

const FILIAL: FlowI[]=[
  {
    route: '/viabilidade/eventos/coleta/seleciona-evento'
  }
]
 const subscribeFlows :FlowIdI[]=[
  { 
    id:101,
    flow:MATRIZ
  },
  {
    id: 102,
    flow: FILIAL
  },
]

// const dbeOn : FlowI[]=[
//   {
//     route: '/dbe'
//   }
// ]

// const dbeFlow : FlowIdI[] = [
//   { 
//     id: 1,
//     flow: dbeOn
//   }
// ]

 function selectFlow (id:number) : Array<FlowI> { 

  const flow  = subscribeFlows.find((el)=>el.id ===id);
  //const dbe = dbeFlow.find((el) =>el.id === el.id);
  if(!flow) throw new  Error("not possible find a flow for this ID");
  //if(!dbe) throw new  Error("not possible find a flow for this ID");
 
  return flow.flow 
  //&& dbe.flow;

}

export {selectFlow}