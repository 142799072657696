import React, { useEffect, useState } from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';
import { Button, Modal } from 'antd'
import { useFormViability } from '../../../../../../../../../../../../hooks/formViability';
import { useCep } from '../../../../../../../../../../../../hooks/cep';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import { useCallback } from 'react';


const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`

const Finish: React.FC = () => {
  const history = useHistory();
  const { address, fantasyName, capital } = useFormViability()
  const { cep } = useCep();
  const { protocol } = useProtocol();
  const [ local, setLocal ] = useState<any>();
  const [ personId, setPersonId ] = useState<any>();
  const [ person, setPerson ] = useState<any>();
  const [ unmaskCapital,  setUnmaskCapital] = useState<string>('');
  const [ companyName, setCompanyName] = useState('')

  /* eslint-disable react-hooks/exhaustive-deps */

  const getCompanyName = useCallback(async ()=> {
      const res = await api.get(`/aikjunta/viability/${protocol}`, config);
      setCompanyName(res.data.first_name_option);
    
  },[])

  /* eslint-enable react-hooks/exhaustive-deps */
 
  function getUsername(){ //buscar username no localStorage
    let getPerson;

    getPerson = sessionStorage.getItem(('username'));
    if(getPerson ===  null) return null;

    let decryptPerson = atob(getPerson)

  
    
    setPerson(decryptPerson);

    if(person !== undefined){
      handleId();
    }
  }
  function handleRemoveCapitalMask(capital: any){
    let newValue = capital.value.replace(/[^\d]/g,'').replace(/.{0,2}$/, '');
    setUnmaskCapital(newValue)
  }

  async function handleId(){ //buscar Id do user
    const res: any = await api.get(`/user/username/${person}`, config);
  
    setPersonId(res.data.id);
  }


  function arrayAddress() { //montar objeto de endereço
    const addresses = {
        city: `${address.city}`,
        postal_code: `${cep}`,
        public_place: `${address.log}`,
        public_place_type: `${address.logType}`,
        complement: `${address.complement}`,
        num: `${address.numLog}`,
        type: "comercial",
        reference_point: "Hospital",
        neighborhood: "Centro"
    };
    setLocal(addresses);
    
  }

  async function handleSubmit(){ //objeto a ser enviado pro back-end
    const data ={
      fantasy: `${fantasyName.name}`,
      capital_value: `${unmaskCapital}`,
      user: personId,
      dbe_protocol: `${protocol}`,
      address: local,
      viability_protocol: `${protocol}`
    }
  
    const response = await api.post('/aikjunta/dbe/',data, config);
    if(response.status === 200){
      Modal.success({
        content: `DBE cadastrado com sucesso! Protocolo ${protocol}`
      })
      history.push('/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/recibo')
    }
  }
  
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(()=> {
    arrayAddress();
  }, local)

  
  useEffect(() => {
    getUsername();
  
  }, personId)
  useEffect(() => {
    handleRemoveCapitalMask(capital);
    getCompanyName()
  }, [])

  /* eslint-enable react-hooks/exhaustive-deps */


  return (
    <ContainerCenter title={"Finalizar Preenchimento"} icon={FaCloudDownloadAlt}>
      <Header />
      <DivLeft>
        <FCPJ />
      </DivLeft>

      <DivRight>
        <div style={{
          width: 700,
          height: 300,
          border: '1px solid black',
          marginBottom: 10,
          overflowY: 'scroll',
          scrollBehavior: 'auto'
        }}>
          <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Finalizar Preenchimento</p>
          <div style={{marginLeft: 10}}>
            <p>Não há pendências no preenchimento do cadastro da empresa <strong>{companyName.toLocaleUpperCase()}.</strong></p><br/>
            <p>Para efetivar o cadastro, clique em <strong>TRANSMITIR</strong></p>

            <Button 
              onClick={handleSubmit}
              type="primary"
              style={{marginLeft:250}}
            >
              Transmitir
            </Button>
          
          </div>
       
        </div>
      </DivRight>
    </ContainerCenter>
  );
}

export default Finish;