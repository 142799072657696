import React from 'react';
import { Link } from 'react-router-dom'
import { FaBook } from 'react-icons/fa';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';


const ContainerText = styled.div`
  display:flex;
  position:relative;
  flex-direction: column;
  width: auto;
  height: auto;
  margin-left: 45px;
`

const Previous = () => {
  return (
    <ContainerCenter
      title={"Preciso de consulta prévia? "}
      icon={FaBook}
    >
      <p style={{marginLeft: '27px'}}> <strong>Nem sempre.</strong> Apenas se um dos atos cadastrais abaixo forem solicitados </p> 
      <br/>
      <ContainerText>
        <ul>
          <li>Abertura (inclusive de filiais)</li>
          <li>Alteração de endereço</li>
          <li>Alteração do nome empresarial</li>
          <li>Alteração da natureza jurídica</li>
          <li>Alteração de atividades econômicas</li>
          <li>Alteração de tipo de unidade</li>
          <li>Alteração de forma de atuação</li>
        </ul>
      </ContainerText>

      <p style={{marginLeft: '27px'}}><strong>Atenção!</strong> Algumas naturezas jurídicas não necessitam ralizar consulta prévia em nenhum ato cadastral. Conheça: </p>
      <br/>
      <ContainerText>
        <ol>
          <li>Pela inexistência de um estabelecimento físico próprio a ser vistoriado: 119-8 Comissão Polinacional; 128-7 Fundo Público da Administração Indireta <br/> 
          Federal; 129-5 Fundo Público da Administração Indireta Estadual ou do Distrito Federal; 130-9 Fundo Público da Administração Indireta Municipal; 131-7 <br/> 
          Fundo Público da Administração Direta Federal; 132-5 Fundo Público da Administração Direta Estadual ou do Distrito Federal; 133-3 Fundo Público da <br/> 
          Administração Direta Municipal; 212-7 Sociedade em Conta de Participação; 215-1 Consórcio de Sociedades; 216-0 Grupo de Sociedades; 222-4 <br/> 
          Clube/Fundo de Investimento; 228-3 Consórcio de Empregadores; 310-7 Comissão de Conciliação Prévia; 323-9 Comunidade Indígena; 324-7 Fundo <br/> 
          Privado; 328-0 Comitê Financeiro de Partido Político; 329-8 Frente Plebiscitária ou Referendária; 401-4 Empresa Individual Imobiliária; 409-0 Candidato <br/> 
          a Cargo Político Efetivo; e 412-0 Produtor Rural.
          </li>
          <br/>
          <li>
          Por não possuírem um estabelecimento físico no Brasil: 221-6 Empresa Domiciliada no Exterior e 321-2 Fundação ou Associação Domiciliada no Exterior.
          </li>
          <br/>
          <li>
          Por não haver necessidade de aprovação do local onde funcionam: 308-5 Condomínio Edilício.
          </li>
        </ol>
      </ContainerText>

    
        <Link to="/viabilidade" style={{width: '200px', fontSize: '20px', marginLeft: '500px'}}>Iniciar Consulta Prévia</Link>
      
      


    </ContainerCenter>
  )
}

export default Previous