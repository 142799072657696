import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../pages/login';
import Home from '../pages/home';
import Viability from '../pages/viability';
import SelectEvent from '../pages/viability/pages/SubscribeFlow/SelectEvent';
import LegalNature from '../pages/viability/pages/SubscribeFlow/LegalNature';
import CepViability from '../pages/viability/pages/SubscribeFlow/CepViability';

import DataCollect from '../pages/viability/pages/SubscribeFlow/DataCollect';
import CompanyIdentify from '../pages/viability/pages/SubscribeFlow/CompanyIdentify';
import FinalReport from '../pages/viability/pages/SubscribeFlow/FinalReport';
//import IdentifiedCompany from '../pages/viability/pages/SubscribeChild/IdentifiedCompany';
import Dbe from '../pages/dbe';
import Redesim from '../pages/dbe/pages/redesim'
import AlreadyExist from '../pages/dbe/pages/redesim/pages/AlreadyExist';
import AlterCad from '../pages/dbe/pages/redesim/pages/Alter/pages/AlterCad';
import Collector from '../pages/dbe/pages/redesim/pages/Alter/pages/CollectorDataAlter';
import Previous from '../pages/dbe/pages/redesim/pages/Alter/pages/Previous';
import Recover from '../pages/dbe/pages/redesim/pages/Alter/pages/Recover';
import NationalCollector from '../pages/dbe/pages/redesim/pages/Alter/pages/NationalCollector';
import Info from '../pages/dbe/pages/redesim/pages/Alter/pages/NationalCollector/pages/Info';
import Events from '../pages/dbe/pages/redesim/pages/Alter/pages/NationalCollector/pages/Events';
import PreviousConsult from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/PreviousConsult';
import Open from '../pages/dbe/pages/redesim/pages/OpenPJ/';
import DataCollector from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector';
import Create from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ';
import InfoCollect from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/InfoCollect';
import InfoRegistry from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/InfoRegistry';
import FlowEvents from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Events'
import Principal from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Principal';
import Header from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/components/header';
import Identification from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Identification';
import EconomicActivity from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/EconomicActivity';
import SocialObject from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/SocialObject';
import AdressPJ from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/AdressPJ';
import Contact from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Contact';
import Represents from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Represents';
import CompanyPort from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/CompanyPort';
import Contabilist from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Contabilist';
import Finish from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Finish';
import Receipt from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Receipt';
import AddresContabilist from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/AddresContabilist';
import Pendencies from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Pendencies';
import Integrador from '../pages/integrador';
import NewFCN from '../pages/integrador/pages/New';
import Matriz from '../pages/integrador/pages/New/pages/Matriz';
import Empresario from '../pages/integrador/pages/New/pages/Empresario';
import Docs from '../pages/integrador/pages/New/pages/Docs';
import Process from '../pages/integrador/pages/New/pages/PdfDocs/Process';
import Remp from '../pages/integrador/pages/New/pages/PdfDocs/Remp';
import Brief from '../pages/dbe/pages/redesim/pages/OpenPJ/pages/DataCollector/pages/CreatePJ/pages/Brief';
import Digital from '../pages/digital-registry';
import NewRegister from '../pages/digital-registry/pages/New';
import DataRegister from '../pages/digital-registry/pages/New/pages/DataRegister';
import Signature from '../pages/digital-registry/pages/New/pages/Signature';
import Success from '../pages/digital-registry/pages/New/pages/Success';
import SuccessViability from '../pages/viability/pages/SubscribeFlow/Success';
import SearchViability from '../pages/viability/pages/SubscribeFlow/SearchViability';

const Pages = () => {
  return (
    <Switch>
      <Route exact={true} path="/login/:id"  >
        <Login />
      </Route>
      <Route exact={true} path="/principal" component={Home} />
      <Route exact={true} path="/viabilidade" component={Viability} />

      {/* Viabilidade steps pages */}
      <Route exact path="/viabilidade/eventos" component={SelectEvent} />
     
      <Route exact path="/viabilidade/consulta" component={SearchViability} />

      {/* <Route
        exact={true}
        path="/viabilidade/eventos/coleta"
        component={SelectedEvent}
      /> */}

      {/* Routes Viabilidade */}

      <Route
        exact={true}
        path="/viabilidade/eventos/coleta/natureza-juridica"
        component={LegalNature}
      />

      <Route
        exact={true}
        path="/viabilidade/eventos/coleta/cep-viabilidade"
        component={CepViability}
      />
      <Route
        exact={true}
        path={'/viabilidade/eventos/coleta/coleta-dados'}
        component={DataCollect}
      />
      <Route
        exact={true}
        path={'/viabilidade/eventos/coleta/coleta-atividade'}
        component={CompanyIdentify}
      />
      <Route
        exact={true}
        path={'/viabilidade/eventos/confirmação-dados'}
        component={FinalReport}
      />

      <Route 
        exact
        path={'/viabilidade/eventos/final'}
        component={SuccessViability}
      />

      <Route
      exact={true} 
      path={'/dbe'}
      component={Dbe}
      />

      <Route
        exact={true} 
        path={'/redesim'}
        component={Redesim}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica'}
        component={Open}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica/consulta-previa'}
        component={PreviousConsult}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica/coletar-dados'}
        component={DataCollector}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/info-juridica'}
        component={InfoCollect}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/info-registro'}
        component={InfoRegistry}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional'}
        component={Create}
      />

      <Route 
        exact={true}
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal'}
        component={Principal}
      />
      
      <Route 
        exact 
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/eventos'} 
        component={FlowEvents}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/identificacao'}
        component={Identification}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/atividade'}
        component={EconomicActivity}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/objeto'}
        component={SocialObject}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-pj'}
        component={AdressPJ}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/contact'}
        component={Contact}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/representante'}
        component={Represents}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/porte'}
        component={CompanyPort}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/contabilista'}
        component={Contabilist}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-contabilista'}
        component={AddresContabilist} 
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/brief'}
        component={Brief}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/finalizar'}
        component={Finish}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/pendencies'}
        component={Pendencies}
      />

      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/recibo'}
        component={Receipt}
      />



      <Route 
        exact
        path={'/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/identificacao'}
        component={Header}
      />


      <Route
      exact={true} 
      path={'/redesim/ja-possuo-pessoa-juridica'}
      component={AlreadyExist}
      />

      <Route
      exact={true} 
      path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao'}
      component={AlterCad}
      />

      <Route
        exact={true} 
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/coleta-de-dados'}
        component={Collector}
      />

      <Route
        exact={true}
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/preciso-de-consulta-previa'}
        component={Previous}
      />

      <Route 
        exact={true}
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/recupera-parametros'}
        component={Recover}
      />

      <Route 
      exact={true}
      path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc'}
      component={NationalCollector}
      />

      <Route
        exact={true}
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc/info'}
        component={Info}
      />

      <Route 
        exact={true}
        path={'/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc/events'}
        component={Events}
      />

      <Route 
        exact
        path={'/integrador'}
        component={Integrador}
      />

      <Route 
        exact
        path={'/integrador/novofcn'}
        component={NewFCN}
      />

      <Route 
        exact
        path={'/integrador/novocfn/matriz'}
        component={Matriz}
      />

      <Route 
        exact
        path={'/integrador/novocfn/empresario'}
        component={Empresario}
      />

      <Route 
        exact
        path={'/integrador/novocfn/docs'}
        component={Docs}
      />

      <Route 
        exact
        path={'/integrador/novofcn/docs/proccess'}
        component={Process}
      />

      
      
      <Route 
        exact
        path={'/integrador/novofcn/docs/remp'}
        component={Remp}
      />

      <Route 
        exact
        path={'/registro-digital'}
        component={Digital}
      />

      <Route 
        exact
        path={'/registro-digital/novo'}
        component={NewRegister}
      />

      <Route 
        exact
        path={'/registro-digital/novo/data'}
        component={DataRegister}
      />

      <Route 
        exact
        path={'/registro-digital/novo/data/assinatura'}
        component={Signature}
      />

      <Route 
        exact
        path={'/registro-digital/novo/data/assinatura/complete'}
        component={Success}
      />
      



     <Route exact={true} render={() => <Redirect to={'/login/:id'} />} />
    </Switch>
  );
};
export default Pages;
