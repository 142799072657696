import React from 'react';
import {
    Grid,
    makeStyles,
} from '@material-ui/core';
import { cardsData } from './models/cardData';
import { Card } from '../../components';
import { Fragment } from 'react';
import { ContainerCenter } from '../components/ContainerCenter';
import { FaBarcode } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(1),
    },
    box: {
        padding: theme.spacing(0),
    },
    container: {
        marginTop: theme.spacing(3),
        // padding: theme.spacing(3),
    },
    userBox: {
        margin: theme.spacing(2),
    },
    gridCards: {
        margin: theme.spacing(2),
    }
}));


const Integrador: React.FC = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <ContainerCenter title={'Integrador'} icon={FaBarcode} >
            <Grid item xs={12}>
                <p style={{marginLeft: '2%'}}>Você está em: <Link to="/principal">Portal de serviços</Link> / Integrador </p>
            </Grid>
            <Grid container spacing={3} className={classes.gridCards}>
                {cardsData.map((item, index) => {
                    return (
                        <Grid item key={index}>
                            <Card
                                title={item.title}
                                url={item.link}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            </ContainerCenter>
        </Fragment>
    );
}

export default Integrador;