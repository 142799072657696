import { FaChalkboardTeacher } from "react-icons/fa";

interface DataI{
  title: string;
  link: string;
  icon: React.FunctionComponent<any>;
}

export const DataCard: DataI[] = [
  {
    title: 'Criar sua Pessoa Jurídica',
    link: '/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional',
    icon: FaChalkboardTeacher
  }
]