import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FaFile, FaHome } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../components/ContainerCenter';
import { Button, Collapse } from 'antd';
import { LabelInput } from '../../../../../../components';
import { Link, useHistory } from 'react-router-dom';

const { Panel } = Collapse;

const useStyles = makeStyles((theme) => ({
    panel: {
        borderRadius: '2px',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    }
}));
interface LabelI {
    text: string;
}
const Label = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#333333',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};

const Docs: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    function subLine(e: any){ //sublinhar com o mouse
        e.target.style.textDecoration = 'underline'
    }
    function toggleSubLine(e: any){//tirar sublinha com o mouse
        e.target.style.textDecoration = 'none'
    }

    function handleHome(){
        history.push('/principal');
    }
  return(
      <ContainerCenter title={'Documentos'} icon={FaFile} buttonBack={true}>
          <Grid item xs={12} style={{width: 1150, marginLeft: 30}}>
              <Collapse defaultActiveKey={'1'} style={{width: '100%'}}>
                <Panel
                     className={classes.panel}
                     style={{borderTop: '4px solid #28b3d6', borderRadius: 5}}
                     key={'1'}
                     showArrow
                     header={<Label text={'Documentos'}/>}
                >
                <div style={{border: '1px solid #494848'}}>
                    <div style={{backgroundColor: '#d6cfcf', width: 'auto', height: 30}}>
                        <Link to="/integrador/novofcn/docs/proccess" style={{marginLeft: 10, color: 'green'}} onMouseOver={subLine} onMouseOut={toggleSubLine} >Capa Processo</Link>
                    </div> 
                    <div style={{backgroundColor: '#f1f0f0', width: 'auto', height: 30}}>
                        <Link to="/integrador/novofcn/docs/remp" style={{marginLeft: 10, color: 'green'}} onMouseOver={subLine} onMouseOut={toggleSubLine} >REMP Matriz</Link>
                    </div>
                </div>
                </Panel>
              </Collapse>
                <Button 
                onClick={handleHome} 
                icon={<FaHome/>} 
                type="primary" 
                size="large"
                style={{marginLeft: 1064}}
                >
                    Início
                </Button>
          </Grid>
      </ContainerCenter>
  );
}

export default Docs;