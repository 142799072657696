import { ColumnType } from 'antd/lib/table';


interface ActivityI { 
  description:string;
  local:boolean;
  cnae:string;
  id: number
}
export const columnsDevelopment: ColumnType<any>[] = [
  {
    title: 'Pergunta',
    dataIndex: 'question',
    key: 'question',
  },
  {
    title: 'Resposta',
    dataIndex: 'answer',
    key: 'answer',
  
  },
  
];
export const columnsActivityPrincipal:ColumnType<any>[] = [ 
  { 
    title:'Descrição da Atividade',
    dataIndex:'description'
  }, 
  { 
    title:'Exercida no local?',
    dataIndex:'local',
  }, 
  { 
    title:'Complemento',
    dataIndex:'cnae'
  }
]
export const columnsActivitySecondary:ColumnType<any>[] =[ 
  { 
    title:'Descrição da Atividade',
    dataIndex:'description'
  }, 
  { 
    title:'Exercida no local?',
    dataIndex:'local',
  }, 
  { 
    title:'Complemento',
    dataIndex:'cnae'
  }
]


export const EconomicActivityPrincipalData:  ActivityI[] =[ 
  { 
    description:'',
    cnae:'',
    local:true,
    id: 0
  }, 
 
]
export const EconomicActivitySecondaryData:ActivityI[] =[ 
  { 
    description:'',
    cnae:'',
    local:true,
    id: 0
  }, 
 
]
export const columnsSocialObject:ColumnType<any>[] = [ 
  { 
    title:'Descrição do Objeto Social',
    dataIndex:'description'
  }
]


export const columnsDevelopmentName:ColumnType<any>[] = [ 
  { 
    title:'Opções',
    dataIndex:"option"
  },
  { 
    title:'Nome',
    dataIndex:'name'
  }
]



interface DevelopmentI{ 
  question:string;
  answer:any;

}

export let DevelopmentData: DevelopmentI[]= [ 
  { 
    question:'Empresa:',
    answer: ''
  }, 
  { 
    question:'Município:', 
    answer:'',
  }, 
  { 
    question:'CNPJ:',
    answer:'',
  },
  { 
    question:'NIRE:',
    answer:'',
  }, 
  { 
    question:'Natureza Jurídica:',
    answer:'',
  },
  { 
    question:'Enquadramento:',
    answer:'',
  }
  ,
  { 
    question:'Eventos:',
    answer:'',
  }
]

interface Society {
  question: string,
  answer: string;
}

export const SocietyData : Society[] = [
  {
    question: "Nome",
    answer: ''
  }, 
  {
    question: 'CPF',
    answer: ''
  }
]

export const LocationData : DevelopmentI[] = [ 
  { 
    question:'Tipo Logradouro:',
    answer:'TESTE'
  },
  { 
    question:'Logradouro:',
    answer:'TESTE'
  },
  { 
    question:'Número:',
    answer:'TESTE'
  },
  { 
    question:'Complemento:',
    answer:'TESTE'
  },
  { 
    question:'Índice do IPTU ou ITR:',
    answer:'TESTE'
  },
  { 
    question:'CEP:',
    answer:'TESTE'
  },
  { 
    question:'Referência do Logradouro:',
    answer:'TESTE'
  },
  { 
    question:'Área Total do Empreendimento:',
    answer:'TESTE'
  },
  { 
    question:'Área Utilizada:',
    answer:'TESTE'
  },
  
  
] 


interface ObjectDescription { 
  description:string;
}
export const SocialObjectData: ObjectDescription[] = [ 
   { 
     description:''
   }
]

interface DevelopmentName{  
  option:string;
  name:string;

}
export const DevelopmentNameData: DevelopmentName[] = [ 
  { 
    option:'Primeiro Nome:',
    name:''
  },
  { 
    option:'Segundo Nome:',
    name:''
  },
  { 
    option:'Terceiro Nome:',
    name:''
  },
]

export const UnityDevelopment: DevelopmentI[] =[ 
  { 
    question:'Tipo de Unidade do Empreendimento:',
    answer:''
  },
  { 
    question:'Forma de Atuação/Tipo de Unidade:',
    answer:''
  },
]