import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Input, Divider, Button, Modal, Alert } from 'antd';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FileTextFilled } from '@ant-design/icons';

import { ContainerCenter } from '../../../../components/ContainerCenter';
import { FormAddress } from './components/FormAddress';
import { FormSociety } from './components/FormSociety';
import { FormUnityDevelopment } from './components/FormUnityDevelopment';

import { useFlow } from '../../../../../hooks/flow';
import { useFormViability } from '../../../../../hooks/formViability';


import api from '../../../../../services/api';

import './styles/styles.css'
import config from '../../../../../services/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  item: {
    margin: 0,
    width:'100%'
    // height: 88,
    // maxHeight: '100%',
  },
  error: {
    color: '#e01818',
    fontSize:17,
    fontWeight: 400
  }
}));

const DataCollect = () => {
  const { routes, setNextLink } = useFlow();
  const { setSocialObject, setNames, names, socialObject, address, society } = useFormViability();
  const [ socialObjectOk, setSocialObjectOk ] = useState<boolean>(false);
  const [ nameOneOk, setNameOneOk ] = useState<boolean>(false);
  const [ nameTwoOk, setNameTwoOk ] = useState<boolean>(false);
  const [ nameThreeOk, setNameThreeOk ] = useState<boolean>(false);
  const [ buttonDisabled, setButtonDisabled ] = useState<boolean>(false);


  const history = useHistory();
  const classes = useStyles();

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => { //controle de rotas protegidas
    const routeNext = routes.length - (routes.length - 3); // flow 101
    if (routeNext > routes.length) {
      history.replace('/viabilidade/eventos');
    }
    setNextLink(routes[routeNext]?.route); // position 2
  },[address, routes, history]);

  /* eslint-enable react-hooks/exhaustive-deps */

  async function getName1(){ //verificar primeira opção de nome 
    //Ver com o Johnny essa questão, fiz baseado no sistema Jucec
    const res = await api.get('/aikjunta/viability/', config);
    let is_equal;
    let found = [];
    for(let i = 0; i < res.data.length; i++){
      found.push(res.data[i].first_name_option);
      is_equal = found[i].includes(names.name1);  
    }

    if(is_equal === true){
      Modal.error({
        content: 'Ocupado'
      })
    }else{
      Modal.success({
        content: 'Disponível'
      })
    }
  }

  async function getName2(){ //verificar segunda opção de nome
    const res = await api.get('/aikjunta/viability', config);
    let is_equal;
    let found = [];
    for(let i = 0; i < res.data.length; i++){
      found.push(res.data[i].second_name_option);
      is_equal = found[i].includes(names.name2);
      
    }
    if(is_equal === true){
      Modal.error({
        content: 'Ocupado'
      })
    }else{
      Modal.success({
        content: 'Disponível'
      })
    }
  }
  async function getName3(){ //verificar terceira opção de nome
    const res = await api.get('/aikjunta/viability', config);
    let is_equal;
    let found = [];
    for(let i = 0; i < res.data.length; i++){
      found.push(res.data[i].third_name_option);
      is_equal = found[i].includes(names.name3);
    }

    if(is_equal === true){
      Modal.error({
        content: 'Ocupado'
      })
    }else{
      Modal.success({
        content: 'Disponível'
      })
    }
  }


  function handleObject(e:string){ //setar o objeto social
    let object = {
      ...socialObject,
    }
    object.body = e;
    setSocialObject(object);
  }

  function handleName1(e: string){ //setar primeira opção de nome
    let name = {
        ...names
    }
    name.name1 = e;
    setNames(name);
  }

  function handleName2(e: string){ //setar segunda opção de nome
    let name = {
        ...names
    }
    name.name2 = e;
    setNames(name)
  }

  function handleName3(e: string){ //setar terceira opção de nome
    let name = {
        ...names
    }
    name.name3 = e;
    setNames(name)
  }

  //Funções para observar mudanças e deixar de mostrar mensagens de erro

  function watchField(e: string){
    e !== '' ? setSocialObjectOk(true) : setSocialObjectOk(false);
  }

  function watchNameOne(e: string){
    e !== '' ? setNameOneOk(true) : setNameOneOk(false);
  }

  function watchNameTwo(e: string){
    e !== '' ? setNameTwoOk(true) : setNameTwoOk(false);
  }

  function watchNameThree(e: string){
    e !== '' ? setNameThreeOk(true) : setNameThreeOk(false);
  }

  useEffect(() => {
    Modal.info({
      content: 'Certifique-se de preencher os campos necessários para avançar!'
    })
  },[])

/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    
    if( society === undefined){
      setButtonDisabled(false)
    }else{
      setButtonDisabled(true)
    }


  }, [society.cpf])
  
/* eslint-enable react-hooks/exhaustive-deps */

  return (
    <ContainerCenter
      title={'Coleta de dados'}
      message={`O objeto social informado aqui deverá ser reproduzido fielmente ao se preencher a Coleta Web do Cadastro Sincronizado. Não poderá ser acrescida ao nome empresarial a expressão ME ou EPP na consulta de viabilidade. A pesquisa de nome empresarial destina-se apenas a verificação 
    da existência de nome idêntico ou semelhante quando da constituição da empresa, alteração do nome, transferência de cartório para JUCEC, transferência da sede de outra UF para CE, reativação de empresa e proteção ao nome empresarial.`}
      icon={FileTextFilled}
      buttonBack={true}
      buttonNext={buttonDisabled}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12} style={{marginTop: '1%'}}>
          <Alert message="Preencha todos os campos que contenham o '*'. " type="warning" showIcon/>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          
        <Typography variant={'h5'}>*Objeto Social</Typography>
          <Input.TextArea onBlur={e=>watchField(e.target.value)}  required={true} rows={8} onChange={e=>handleObject(e.target.value)} value={socialObject.body} />
        { socialObjectOk === false  && (
          <p className={classes.error}> Objeto social obrigatório </p>
        )}

          
        </Grid>
        <Divider />
        <Typography
          variant={'body2'}
          style={{
            fontSize: '1.1em',
            fontWeight: 700,
            maxWidth: '100%',
            marginBottom: '1em',
          }}
        >
          Preencha as seguintes opções:
        </Typography>

        <Grid item xs={12} className={classes.item}>
        <Typography
              variant={'body2'}
              style={{
                fontSize: '1.0em',
                fontWeight: 700,
                maxWidth: '100%',
                marginBottom: '1em',
              }}
            >
             *Primeira opção de nome:
            </Typography>
          <Input style={{
            width: '80%',
            height: 40,
            marginBottom: 10
          }} onBlur={e=>watchNameOne(e.target.value)} required name={'name-one'} onChange={e=>handleName1(e.target.value)} value={names.name1}/>
          <Button type='primary' size='large' onClick={getName1}>Pesquisar</Button>
          {
            nameOneOk === false && (
              <p className={classes.error}>Primeira opção de nome obrigatória</p>
            )
          }
        </Grid>
        <Grid item xs={12} className={classes.item}>
        <Typography
              variant={'body2'}
              style={{
                fontSize: '1.0em',
                fontWeight: 700,
                maxWidth: '100%',
                marginBottom: '1em',
              }}
            >
             *Segunda opção de nome:
            </Typography>
          <Input style={{
            width: '80%',
            height: 40,
            marginBottom: 10
          }} onBlur={e=>watchNameTwo(e.target.value)} required name={'name-two'} onChange={e=>handleName2(e.target.value)} value={names.name2}/>
        <Button type='primary' size='large' onClick={getName2}>Pesquisar</Button>
        {
            nameTwoOk === false && (
              <p className={classes.error}>Segunda opção de nome obrigatória</p>
            )
          }
        </Grid>
        <Grid item xs={12} className={classes.item}>
        <Typography
              variant={'body2'}
              style={{
                fontSize: '1.0em',
                fontWeight: 700,
                maxWidth: '100%',
                marginBottom: '1em',
              }}
            >
              *Terceira opção de Nome: 
            </Typography>
          <Input style={{
            width: '80%',
            height: 40,
            marginBottom: 10
          }} onBlur={e=>watchNameThree(e.target.value)} required name={'name-three'} onChange={e=>handleName3(e.target.value)} value={names.name3}/>
        <Button type='primary' size='large' onClick={getName3}>Pesquisar</Button>
        {
            nameThreeOk === false && (
              <p className={classes.error}>Terceira opção de nome obrigatória</p>
            )
          }
        </Grid>
       

        <Grid container>
          <Grid item xs={12} lg={8}>
            <Typography
              variant={'body2'}
              style={{
                fontSize: '1.1em',
                fontWeight: 700,
                maxWidth: '100%',
                marginBottom: '1em',
              }}
            >
              Caso exista processo pendente com o nome empresarial pretendido,
              informe o protocolo:
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Input
              size={'large'}
              style={{
                marginRight: '2em',
                width: '100%',
                maxWidth: '100%',
              }}
            />
          </Grid>
        </Grid>
        <Divider />

        <Grid item xs={12}>
          <FormAddress />
        </Grid>

        <Grid item xs={12}>
          <FormUnityDevelopment />
        </Grid>
        <Grid item xs={12}>
            <FormSociety/>
        </Grid>
        <Grid item xs={12} style={{marginTop: '1%'}}>
        </Grid>
      </Grid>
    </ContainerCenter>
  );
};

export default DataCollect;
