import React, { Fragment, useEffect, useState } from 'react';
import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { FaMinusCircle, FaPlus, FaSearch } from 'react-icons/fa';
import { ContainerCenter } from '../../../components/ContainerCenter';
import { Input, Button, Alert, Collapse, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';

import './styles/new.css';
import LabelInput from '../../../../components/LabelInput';
import { useProtocol } from '../../../../hooks/protocol';
import api from '../../../../services/api';
import { useFormIntegrator } from '../../../../hooks/formIntegrator';
import config from '../../../../services/auth';

const { Panel } = Collapse;

const useStyles = makeStyles((theme) => ({
    panel: {
        borderRadius: '2px',
        borderTop: '4px solid #28b3d6',
        marginBottom: 3,
        background: ' #f7f7f7',
        border: 0,
        overflow: 'hidden',
    },
}));

const Message = () => {
    return (
        <Box display={'flex'} flexDirection={'column'}>
          <span>
            Para processos de Registro Digital o DAE deve estar pago, caso ainda não tenha sido emitido seu DAE clique aqui.
          </span>
        </Box>
      );
}

interface LabelI {
    text: string;
}
const Label = ({ text }: LabelI) => {
    return (
        <LabelInput
            style={{
                color: '#000000',
                maxWidth: '100%',
                fontWeight: 700,
                fontSize: '1.1em',
            }}
            text={text}
        />
    );
};


const NewRegister = () => {
    const classes = useStyles();
    const history = useHistory();
    const {protocol, setProtocol} = useProtocol();
    const { setDae } = useFormIntegrator();
    const [ clicked, setClicked ] = useState(false);
    const [ count, setCount ] = useState(0);
    const [ value, setValue ] = useState('');
    const [nature, setNature] = useState('');
    const [companyName, setCompanyName] = useState('');

    async function handleData(){
        const res = await api.get(`/aikjunta/integrator/${protocol}`, config);
        setNature(res.data.legal_nature);
    
        
        setCompanyName(res.data.dbe.fantasy);
    }
    

    function handleClicked(){
        setClicked(true);
    }

    function handleChange(e: string){
        setValue(e);
    }

    function handleClick(){
        setCount(count+1);
    }

    function handleNext(){
        history.push('/registro-digital/novo/data')
    }

    useEffect(()=>{
        if(protocol){
            handleData();
        }
    })

    
    return(
        <ContainerCenter title={'Registro Digital - Novo'} icon={FaPlus} buttonBack>
            <Divider/>
            <b style={{marginLeft: 30}}>Protocolo Módulo Integrador: </b> <br/> <br/>
            <Input style={{width: 500, marginLeft: 30}} onChange={e=>setProtocol(e.target.value)}/> &emsp;
            <Button type='primary' onClick={handleClicked}> {<FaSearch/>}Pesquisar</Button>
            <br/>
            { clicked === true &&
               <Fragment>
                   <br/>
                <Divider/>
                <br/>
                <b style={{marginLeft: 30}}>Dados: </b> <br/>
                
                   <table style={{marginLeft: 30}}>
                       <tr className="data-principal">
                        <td >Protocolo Módulo Integrador: &emsp;</td>
                        <td>{protocol}</td>
                       </tr>
                       <tr>
                        <td>Nome da Empresa: </td>
                        <td>{companyName}</td>
                       </tr>
                       <tr className="data-principal">
                           <td>Natureza Jurídica: </td>
                           <td>{nature}</td>
                       </tr>
                       <tr>
                           <td>Ato: </td>
                           <td>080 - Inscrição</td>
                       </tr>
                   </table>
               </Fragment>
            }
            <br/><br/> <Divider /> <br/><br/>
            {
                count === 1 &&
                <Fragment>
                    <Alert type="info" message={<Message/>} showIcon/>
                    <br/>
                    <Grid item xs={12} >
                        <Collapse defaultActiveKey={'1'}>
                            <Panel 
                                key={'1'} 
                                showArrow 
                                header={<Label text={'Documento de Arrecadação Estadual - DAE:'}/>}
                                className={classes.panel}
                            >
                                <label><strong>Número do DAE: </strong></label>
                                <Input style={{width: 350}} onChange={e=> setDae(e.target.value)}/> &emsp;
                                <Button size="middle" type='primary' danger ><FaMinusCircle/></Button>
                            </Panel>
                        </Collapse>

                    </Grid>
                </Fragment>
            }
            {
                count === 2 &&
                <Fragment>
                    <Grid item xs={12} >
                        <Collapse defaultActiveKey={'1'}>
                            <Panel 
                                key={'1'} 
                                showArrow 
                                header={'O ato a ser registrado será assinado por procurador?'}
                                className={classes.panel}
                            >
                                <Radio.Group onChange={e=>handleChange(e.target.value)}>
                                    <Radio value="yes">Sim</Radio>
                                    <Radio value="no">Não</Radio>
                                </Radio.Group>
                                
                            </Panel>
                        </Collapse>

                    </Grid>
                </Fragment>
            }
                <Button 
                    type="primary" 
                    icon={<ArrowRightOutlined/>}
                    size={'large'}
                    style={{marginLeft: 1100, marginTop: 50}}
                    onClick={value === 'no' ? handleNext : handleClick}
                    disabled={protocol ? false : true}
                    >Avançar
                </Button>
        </ContainerCenter>
    )
}

export default NewRegister;