import React, { createContext, useContext, useState } from 'react';

interface showContextData {
  show: boolean;
  setShow: React.Dispatch<any>;
}
const ShowDataContext = createContext({} as showContextData);

const ShowProvider: React.FC = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <ShowDataContext.Provider value={{ show, setShow }}>
      {children}
    </ShowDataContext.Provider>
  );
};

const useShowData = () => {
  const context = useContext(ShowDataContext);
  if (!context) throw new Error('UseCep must be used within a Provider');
  const { show, setShow } = context;
  return { show, setShow };
};
export { ShowProvider, useShowData };