import {createMuiTheme} from "@material-ui/core"



const theme = createMuiTheme({ 
  palette:{ 
    primary:{ 
      main:'#009e50'
    } ,
    secondary:{ 
      main:'#1e793f',
    },
    
  }
});
export {theme}