import React from 'react';
import { Paper, Box, makeStyles } from '@material-ui/core';
import { Divider, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';

interface InformationContainerProps {
  icon?: React.FunctionComponent<any>;
  title: string;
  columns: Array<ColumnType<any>>;
  dataList: Array<any>;
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    borderTop: '3px solid #d2d2d2',
  },
}));

const InformationContainer = ({
  icon: Icon,
  columns,
  dataList,
  title,
}: InformationContainerProps) => {
  const classes = useStyles();
  
  return (
    <Paper
      elevation={1}
      className={classes.paper}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box display={'flex'} justifyContent={'row'}>
        {Icon && (
          <Icon
            style={{ fontSize: 24, marginRight: '0.5em', color: '#9a9aa0' }}
          />
        )}

        <h2 style={{ fontSize: 24, margin: 0 }}>{title}</h2>
      </Box>
      <Divider />
      <Box>
        
      <Table
        bordered
        style={{ minHeight: '30vh', width: '100%', backgroundColor: 'white' }}
        columns={columns}
        dataSource={dataList}
        pagination={false}
      />
        
        
      </Box>
    </Paper>
  );
};

export { InformationContainer };