import { Input, Modal, Radio, Select } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { useFormDbe } from '../../../../../../../../../../../../hooks/formDbe';
import { cpfValidate } from '../../../../../../../../../../../../services/cpfAuth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`
const { Option } = Select;

const Represents: React.FC = () => {
  const { representant, setRepresentant } = useFormDbe();

  const modalCaller = useCallback(() => {
    Modal.info({
      content: 'Preencha nome de acordo com o registro que consta no cadastro do CPF'
    })
  },[]);

  useEffect(() => {
    modalCaller()
  },[modalCaller]);

  function handleCPFMask(e: any){
    let value = e;
    value = value.replace(/\D/g,'');
    value = value.replace(/(\d{1})(\d{8})$/,'$1.$2');  
    value = value.replace(/(\d{1})(\d{5})$/,'$1.$2');  
    value = value.replace(/(\d{1})(\d{1,2})$/,'$1-$2');
    setRepresentant({
      ...representant, 
      cpf: value,
    })
  }
  function teste(e: string){
    cpfValidate(e);
  }


  return(
      <ContainerCenter title={'Representante/Preposto'} icon={FaUser}>
        <Header />
        <DivLeft>
            <FCPJ />
        </DivLeft>
        <DivRight>
    <div style={{
      width: 700,
      height: 450, 
      border: '1px solid black', 
      marginBottom: 10,
      overflowY: 'scroll',
      scrollBehavior: 'auto'
    }}>
      <p style={{fontWeight:'bold', textAlign: 'center', backgroundColor: '#ccc'}}>Identificação do Representante da Pessoa Jurídica para este ato de cadastro</p>
        <div style={{marginLeft: 10}}>
            <Radio.Group value={1} >
                <Radio value={1}>Representante</Radio>
                <Radio value={2}>Preposto</Radio>
            </Radio.Group>
            <br/><br/>
            <hr/>
            <br/><br/>
            <Label>Nome do Representante da Pessoa Jurídica: </Label> <br/>
            <Input 
                style={{width: 500}} 
                type={'text'}
                value={representant.name}
                onChange={e => setRepresentant({...representant, name : e.target.value})}
            /> <br/> <br/>
            <Label>CPF: </Label> <br/>
            <Input 
                style={{width: 200}}
                type={'text'}
                maxLength={14}
                value={representant.cpf}
                onChange={e => handleCPFMask(e.target.value)} 
                onBlur={e => teste(e.target.value) }
            /> <br/>  <br/>
            <Label>Qualificação do Representante da Pessoa Jurídica: </Label> <br/>
            <Select defaultValue="50 - Empresário" style={{width: 150}}> 
                <Option value="50">50 - Empresário</Option>
            </Select>
        </div>


    </div> 
        </DivRight>
      </ContainerCenter>
  );
}

export default Represents;