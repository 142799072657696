import styled from 'styled-components';
import { Form, Button } from 'antd';

export const FormLogin = styled(Form)`
  min-width: 35vw;
  box-shadow: 0px 0px 5px -2px;
  background-color: #fff;
  top: 0;
  padding: 40px 50px;
  border-radius: 8px;
  animation-name: fadeIn;
  animation-duration: 1000ms;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  margin: 5% 0 auto;

  @media (max-width: 800px) {
    margin: 10% 0 auto;
  }
`;

interface LabelProps {
  alignItems?: string;
  justifyContent?: string;
}
export const Label = styled.div<LabelProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(p) => (p.alignItems ? p.alignItems : 'center')};
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'center')};
  h4 {
    margin-bottom: 0;
  }
`;
export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonLogin = styled(Button)`
  background-color: #009d72;
  color: white;
  border: none;
  transition: background-color 1s;
  &:hover {
    background-color: #09bb8c;
  }
`;
