interface Event  { 
  name:string;
  code:string;
}
 const subscribeEvents:Event[] =  [  
  { 
    name:"Inscrição de primeiro estabelecimento (Matriz)",
    code:"101"
  },
  {   
    name:"Inscrição dos demais estabelecimentos (Filial)",
    code:"102"
  },{ 
    name:"Proteção de Nome Empresarial",
    code:"150"
  },
  { 
    name:"Inscrição de missões dipl./repart. consul./repres. de órgãos internacionais",
    code:"106"
  }
]
const AlterEvents : Event[] = [ 
  {
    name:"Alteração do nome empresarial (firma ou denominação)",
    code:"220"
  },
   { 
     name:"Alteração de atividades econômicas (principal e secundárias)",
     code:"244"
   },
  { 
      name:"Alteração de endereço dentro do mesmo município",
      code:"211"
  }, 
  { 
    name:"Alteração de endereço entre municípios dentro do mesmo estado",
    code:"209"
  }, 
  { 
    name:"Alteração de endereço entre estados",
    code:"210"
  },
  { 
    name:"Alteração da natureza jurídica",
    code:"225"
  },

  { 
    name:"Alteração da forma de atuação",
    code:"249"
  }, 
  { 
    name:"Alteração do tipo de unidade",
    code:"248"
  },
  { 
    name:"Reativação - Artigo 60 Lei 8.934/94",
    code:"052"
  },
  { 
    name:"Licenciamento de Estabelecimento anteriormente registrado(Legado)",
    code:"999"
  }
]

export {subscribeEvents,AlterEvents}