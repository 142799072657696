import React, { useEffect, useState } from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Collapse, Form, Radio, Divider } from 'antd';

import { ContainerCenter } from '../../../../components/ContainerCenter';
import { FormEvents } from '../SelectEvent/components/components.styled';
import { LabelInput } from '../../../../../components';

import { FaCrosshairs } from 'react-icons/fa';

import {
  aob,
  atoLegal,
  cartorioRegistro,
  juntaComercial,
  outros,
} from './model.data';

import { useFlow } from '../../../../../hooks/flow';
import { useHistory } from 'react-router-dom';
import { useFormViability } from '../../../../../hooks/formViability'

const { Panel } = Collapse;

const useStyles = makeStyles((theme) => ({
  panel: {
    borderRadius: '2px',
    marginBottom: 3,
    background: ' #f7f7f7',
    border: 0,
    overflow: 'hidden',
  },
  radio: {
    display: 'block',
    // height: '300px',
    lineHeight: '2.4em',
    letterSpacing: '0.5px',
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
}));
interface TypesI {
  name: string;
}
interface LabelI {
  text: string;
}
const Label = ({ text }: LabelI) => {
  return (
    <LabelInput
      style={{
        color: '#333333',
        maxWidth: '100%',
        fontWeight: 700,
        fontSize: '1.1em',
      }}
      text={text}
    />
  );
};





const LegalNature = () => {

  const { setPortCompany, setLegalNature } = useFormViability();
  const [portChecked, setPortChecked] = useState <boolean>(false);
  const [natureChecked, setNatureChecked] = useState(false)
  const [enable, setEnable] = useState<boolean>(false);

  function handleName(id: number){
    let port = id;
    setPortCompany({type: port});
    setPortChecked(true);
  }

  function handleNature(name: string){
    let legal = name;

    setLegalNature({nature: legal});
    setNatureChecked(true);
  }

  useEffect(()=>{
    if(portChecked === true && natureChecked === true){
      setEnable(true);
    }
  },[portChecked, natureChecked])

  

  const classes = useStyles();
  const { routes, setNextLink } = useFlow();
  const history = useHistory();

  const [companyTypes] = useState<Array<TypesI>>([
    {
      name: 'Micro Empresa',
    },
    {
      name: 'Empresa Pequeno Porte',
    },
    {
      name: 'Outros',
    },
  ]);


  

  useEffect(() => {
    const routeNext = routes.length - (routes.length - 1); // flow 101
    if (routeNext > routes.length) {
      
      history.replace('/viabilidade/eventos');
    }
    setNextLink(routes[routeNext]?.route); // position 1
  });
  return (
  

    <ContainerCenter
      title={'Selecione a Natureza jurídica e o Órgão de registro'}
      icon={FaCrosshairs}
      buttonBack={true}
      buttonNext={enable}
    >
      <Grid
        className={classes.container}
        container
        alignItems={'center'}
        justify={'flex-start'}
      >
        <Grid item xs={12}>
          <Collapse activeKey={'1'} style={{ width: '100%' }}>
            <Panel
              className={classes.panel}
              style={{ borderLeft: '4px solid #666', borderRadius: 5 }}
              key={'1'}
              showArrow={false}
              header={<Label text={'Selecione o Enquadramento:'} />}
            >
              <FormEvents layout={'horizontal'}>
                <Form.Item style={{ padding: 4, margin: 4 }}>
                  <Radio.Group>
                    {companyTypes.map((el, index) => (
                      <Radio key={index++} value={el.name} onChange={()=>handleName(index)}>
                        {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
          <Divider />
        </Grid>
        <Typography
          variant={'body2'}
          style={{
            fontSize: '1.1em',
            fontWeight: 700,
            maxWidth: '100%',
            marginBottom: '1em',
          }}
        >
          Clique sobre o nome do orgão para visualizar as opções:
        </Typography>
      </Grid>

      <Grid
        className={classes.container}
        container
        alignItems={'center'}
        justify={'flex-start'}
        spacing={0}
      >
        <Grid item xs={12}>
          <Collapse defaultActiveKey={['1']} style={{ width: '100%' }}>
            <Panel
              key={'1'}
              className={classes.panel}
              style={{ borderLeft: '4px solid #66' }}
              header={
                <LabelInput
                  style={{ fontSize: '1.2em' }}
                  text={'Junta Comercial'}
                />
              }
            >
              <FormEvents layout={'vertical'}>
                <Form.Item style={{ padding: 4, margin: 4 }}>
                  <Radio.Group>
                    {juntaComercial.map((el, index) => (
                      <Radio
                        className={classes.radio}
                        key={index}
                        value={el.name}
                        onChange={e=> handleNature(e.target.value)}
                      >
                        {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
        </Grid>

        {/*  CARTORIO DE REGISTRO DE PESSOA JURÍDICA */}
        <Grid item xs={12}>
          <Collapse style={{ width: '100%' }}>
            <Panel
              key={'1'}
              className={classes.panel}
              style={{ borderLeft: '4px solid #66' }}
              header={
                <LabelInput
                  style={{ fontSize: '1.2em' }}
                  text={'Cartório de Registro de Pessoa Jurídica'}
                />
              }
            >
              <FormEvents layout={'vertical'}>
                <Form.Item style={{ padding: 4, margin: 4 }}>
                  <Radio.Group>
                    {cartorioRegistro.map((el, index) => (
                      <Radio
                        className={classes.radio}
                        key={index}
                        value={el.name}
                        onChange={e=>handleNature(e.target.value)}
                      >
                        {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
        </Grid>

        {/*  AOB */}
        <Grid item xs={12}>
          <Collapse style={{ width: '100%' }}>
            <Panel
              key={'1'}
              className={classes.panel}
              style={{ borderLeft: '4px solid #66' }}
              header={<LabelInput style={{ fontSize: '1.2em' }} text={'OAB'} />}
            >
              <FormEvents layout={'vertical'}>
                <Form.Item style={{ padding: 4, margin: 4 }}>
                  <Radio.Group>
                    {aob.map((el, index) => (
                      <Radio
                        className={classes.radio}
                        key={index}
                        value={el.name}
                        onChange={e=>handleNature(e.target.value)}
                      >
                        {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
        </Grid>

        {/*  ATO LEGAL - ENTIDADES CRIADAS POR LEI  */}
        <Grid item xs={12}>
          <Collapse style={{ width: '100%' }}>
            <Panel
              key={'1'}
              className={classes.panel}
              style={{ borderLeft: '4px solid #66' }}
              header={
                <LabelInput
                  style={{ fontSize: '1.2em' }}
                  text={'Ato Legal - Entidades criadas por Lei'}
                />
              }
            >
              <FormEvents layout={'vertical'}>
                <Form.Item style={{ padding: 4, margin: 4 }}>
                  <Radio.Group>
                    {atoLegal.map((el, index) => (
                      <Radio
                        className={classes.radio}
                        key={index}
                        value={el.name}
                        onChange={e=>handleNature(e.target.value)}
                      >
                        {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
        </Grid>
        {/*  ATO LEGAL - ENTIDADES CRIADAS POR LEI  */}
        <Grid item xs={12}>
          <Collapse style={{ width: '100%' }}>
            <Panel
              key={'1'}
              className={classes.panel}
              style={{ borderLeft: '4px solid #66' }}
              header={
                <LabelInput style={{ fontSize: '1.2em' }} text={'Outros'} />
              }
            >
              <FormEvents layout={'vertical'}>
                <Form.Item style={{ padding: 4, margin: 4 }}>
                  <Radio.Group>
                    {outros.map((el, index) => (
                      <Radio
                        className={classes.radio}
                        key={index}
                        value={el.name}
                        onChange={e=>handleNature(e.target.value)}
                      >
                        {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
        </Grid>
      </Grid>
    </ContainerCenter>
  
  );
};

export default LegalNature;
