import React, { useEffect, useState } from 'react';
import { Form, Input, Divider, Modal } from 'antd';
import {
  FormLogin,
  Label,
  ActionButton,
  ButtonLogin,
} from './components/components.styled';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LabelInput, SectionTitle } from '../../components';
import api from '../../services/api';


const Login = () => {
  const history = useHistory();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [machine_id, setMachine_id] = useState('');

  const { id }: any = useParams();

  function getMachineId(){
    
    if(id !== null){
      setMachine_id(id);
    }
  }

  async function login(){
    try{
      const response = await api.post("/login",{
        username,
        password,
        id: machine_id
      })
      if(response.status === 200){
        localStorage.setItem('token_junta', btoa(response.data.token));
        localStorage.setItem('@machine_id', btoa(machine_id));
        sessionStorage.setItem('username', btoa(username));
        history.push('/principal');
        //Criptografar btoa(string) | Descriptografar atob(string)
      }
    }catch(err: any){
        Modal.error({
          'content': `${err.response.data.message}`
        })
    }
  }

  const disabledClick = (e: any) =>{
    e.preventDefault();
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    getMachineId();
  }, [machine_id])

  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div
      className={'page-content'}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormLogin onFinish={() => login()} >
        <Label justifyContent={'flex-start'}>
          <UserOutlined style={{ fontSize: 20, marginRight: '5px' }} />
          <SectionTitle
            level={4}
            style={{ fontWeight: 300 }}
            text={'Acessar Portal de Serviços'}
          />
        </Label>

        <Divider />

        <Form.Item
          style={{ display: 'flex', flexDirection: 'column' }}
          label={<LabelInput text={'Informe o e-mail'} />}
          name={'cpf'}
          labelAlign={'left'}
          rules={[
            {
              required: true,
              message: 'preenchimento obrigatório',
            },
          ]}
          hasFeedback

        >
          <Input
            prefix={<UserOutlined color={'#9a9aa0'} />}
            disabled={false}
            size={'large'}
            onChange={e=> setUserName(e.target.value)}
            value={username}
          />
        </Form.Item>

        <Form.Item
          name={'password'}
          label={<LabelInput text={'Senha'} />}
          style={{ display: 'flex', flexDirection: 'column' }}
          labelAlign={'left'}
          rules={[
            {
              required: true,
              message: 'preenchimento obrigatório',
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} size={'large'} onChange={e=>setPassword(e.target.value)} value={password} />
        </Form.Item>

        <ActionButton>
          <Form.Item>
            <Link to={'/'} onClick={e=>disabledClick(e)} >Esqueceu a senha?</Link>
          </Form.Item>
          <Form.Item>
            <ButtonLogin
              size={'large'}
              htmlType={'submit'}
              type={'primary'}
              icon={<LoginOutlined />}
            >
              Entrar
            </ButtonLogin>
          </Form.Item>
        </ActionButton>       
      </FormLogin>
    </div>
  );
};

export default React.memo(Login);
