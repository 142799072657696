import Hero4 from '../../../../../assets/dbe/office.jpg'
import Hero5 from '../../../../../assets/dbe/woman.jpg'


interface DataProps { 
  title:string;
  link:string;
  backgroundImg: string;
}
export const Data: DataProps[] = [
  {
    title: "Já possuo Pessoa Jurídica",
    link: '/redesim/ja-possuo-pessoa-juridica',
    backgroundImg: `${Hero4}`,
  },
  {
  
    title: "Abra sua Pessoa Jurídica",
    link: '/redesim/abrir-pessoa-juridica',
    backgroundImg: `${Hero5}`,
  }
]