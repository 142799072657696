import React from 'react';
import {
  AppBar,
  makeStyles,
  Toolbar,
  Theme,
  createStyles,
  // Button,
} from '@material-ui/core';

// import HeaderImage from '../../assets/headerImage.svg';
// import LogoRede from '../../assets/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },

    logos: {
      // flexGrow: 0.2,
      alignItems: 'center',
      '& img': {
        margin: '0 10px',
        maxWidth: '200px',
      },
      display: 'flex',
      backgroundColor: 'white',
      transform: 'skewX(-15deg)',
      height: '100px',
      marginLeft: '5rem',
      padding: '0 20px',
      width: 'auto',
    },
  })
);
const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.logos}>
            <div>
              {/* <img src={LogoRede} alt={'logo2'} /> */}
              {/* <img src={HeaderImage} alt={'logo'} /> */}
            </div>
          </div>

          {/* <div style={{ flexGrow: 1 }} /> */}
          {/* <Button color="inherit">MENU</Button> */}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
