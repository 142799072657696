import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { Card } from 'antd';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '20em',
    height: '10em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#f7f7f9',
    padding: '20px 15px',
  },
  icon: {
    color: '#02c765',
    fontSize: 32,
    margin: '0 1em',
    marginTop: '0.3em',
    '&:hover': {
      color: '#009d72',
    },
  },
}));

interface CardViabilityProps {
  title: string;
  url: string;
  component: React.FunctionComponent<any>;
}
const CardContainer = styled(Card)`
  width: 20em;
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #f7f7f9;
  padding: 20px 15px;
  transition: background-color 1s;
  &:hover {
    color: #009d72;
  }
`;

const CardViability = ({
  title,
  url,
  component: Component,
}: CardViabilityProps) => {
  const history = useHistory();
  const classes = useStyles();
  function pageNavigate(url: string) {
    history.push(url);
  }

  function disabledNavigate(){
    history.block(() => {});
  }

  return (
    <CardContainer hoverable onClick={ url ?  () => pageNavigate(url) : ()=> disabledNavigate()}>
      <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
        <Component className={classes.icon} />

        <Box
          style={{
            display: 'flex',
            width: '1px',
            backgroundColor: '#cecece',
            marginRight: '1em',
          }}
        />
        <Box>
          <Typography component={'p'}>{title}</Typography>
        </Box>
      </Box>
    </CardContainer>
  );
};
export { CardViability };
