import React, {createContext, useContext, useState} from 'react'
import { SetStateAction } from 'react';

interface PendenciesContextData{
  mandatoryPendencies: MandatoryPendencies;
  setMandatoryPendencies: React.Dispatch<SetStateAction<any>>;

  optionalPendencies: OptionalPendencies;
  setOptionalPendencies: React.Dispatch<SetStateAction<any>>;
}

interface MandatoryPendencies {
  fantasyName: string; 
  capitalValue: string;
  phone: string;
}

interface OptionalPendencies {
  phone2: string;
  complementPJ: string;
  complementContabilist: string
}

const PendenciesContext = createContext({} as PendenciesContextData);

const PendenciesProvider: React.FC = ({children}) => {
  const [mandatoryPendencies, setMandatoryPendencies] = useState<MandatoryPendencies>({} as MandatoryPendencies);
  const [optionalPendencies, setOptionalPendencies] = useState<OptionalPendencies>({} as OptionalPendencies);
  
  return(
    <PendenciesContext.Provider 
      value={{
        mandatoryPendencies, 
        setMandatoryPendencies,
        optionalPendencies,
        setOptionalPendencies,
      }}>
      {children}
    </PendenciesContext.Provider>
  )
}

const usePendencies = () => {
  const context = useContext(PendenciesContext);
  if(!context) throw new Error('UsePendencies must be used within a provider');

  const {
    mandatoryPendencies,
    setMandatoryPendencies,
    optionalPendencies,
    setOptionalPendencies,
  } = context;

  return {
   mandatoryPendencies,
   setMandatoryPendencies,
   optionalPendencies,
   setOptionalPendencies,
  }
}
export {usePendencies, PendenciesProvider}
export default PendenciesContext;
