import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Card } from 'antd';
import styled from 'styled-components';

interface CardProps {
  title: string;
  link: string;
  description?: string;
  backgroundImg?: string;
}


const CardContainer = styled(Card)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 60px 50px 190px 0;
  :hover{
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
`


const CardPhotos = ({
  title,
  link,
  description,
  backgroundImg,
}: CardProps) => {

  function pageNavigate(link: string) {
      global.window.location.href = link;
      // history.push(link);
  }
  return (

    
    <Container>

    <CardContainer onClick={() => pageNavigate(link)} 
      style={{ 
      backgroundImage: `url(${backgroundImg})`,
      backgroundPosition: "center",
      height: '350px',
      width: '350px',
      backgroundSize: 'cover',
      }}>

      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'left',
        marginTop: '380px'
      }}>
       
        <Box >
          <Typography component={'h1'} style={{color: 'white', fontSize: '16px', fontWeight: 'bold'}}>{title}</Typography>
        </Box>

        <Box>
          <Typography component={'h3'} style={{color: '#ffffff', fontSize: '12px', fontWeight: 'bold'}}>{description}</Typography>
        </Box>

      </Box>

    </CardContainer>
    </Container>
  );
};


export { CardPhotos }