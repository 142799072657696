// Endereço do Estabelecimento
// *Tipo de Unidade do Empreendimento

import React, { useState } from 'react';

import { Box, Typography, Grid } from '@material-ui/core';
import { Radio, Form, Divider, Input, Alert } from 'antd';


import { FormEvents } from '../../SelectEvent/components/components.styled';
import { LabelInput } from '../../../../../../components';

import { useCep } from '../../../../../../hooks/cep';
import { useFormViability } from '../../../../../../hooks/formViability'

import { AddressOptions } from './model.data';
interface FormAddressDataProps {
  iptuMandatory?: boolean;
}

const FormAddressData = ({ iptuMandatory }: FormAddressDataProps) => {
  const { cep } = useCep();
  const { 
    iptu,
    setIptu, 
    address,
    setAddress,
    areaT,
    setAreaT
  } = useFormViability();


  function handleIptu(e: string){ //setar iptu no contexto
    let iptr = {
      ...iptu,
    }
    iptr.iptu = e;
    setIptu(iptr);
  }

  function handleTotal(e: string){ //setar área total no contexto
    let area = {
      totalArea: areaT.totalArea,
      utilArea: areaT.utilArea,
    }

    area.totalArea = parseInt(e);
    setAreaT(area);
  }
  function handleUtil(e: string){ //setar área utilizada no contexto
    let area = {
      totalArea: areaT.totalArea,
      utilArea: areaT.utilArea,
    }

    area.utilArea = parseInt(e);
    setAreaT(area)
}

  function handleComp(e: string){ //setar complemento OPCIONAL
    let addressAux = {
      neighborhood: address.neighborhood ,
      city: address.city,
      complement: address.complement,
      uf: address.uf,
      logType: address.logType,
      numLog: address.numLog,
      log: address.log,
      refLog: address.refLog
    }

    addressAux.complement = e;
    setAddress(addressAux);
  }
 
  function handleNumLog(e: string){ //setar número do estabelecimento
    let logAux = {
      neighborhood: address.neighborhood ,
      city: address.city,
      complement: address.complement,
      uf: address.uf,
      logType: address.logType,
      numLog: address.numLog,
      log: address.log,
      refLog: address.refLog
    }
    logAux.numLog = parseInt(e);
    setAddress(logAux);

  } 

  function handleRefLog(e: string){ //setar referência NÃO EXISTE NO BANCO
    let logAux = {
      neighborhood: address.neighborhood ,
      city: address.city,
      complement: address.complement,
      uf: address.uf,
      logType: address.logType,
      numLog: address.numLog,
      log: address.log,
      refLog: address.refLog
    }
    logAux.refLog = e
    setAddress(logAux);

  } 

  return (
  
    <Form layout={'horizontal'} >
      <Grid container spacing={1}>
        <Alert message="Informe todos os dados solicitados" type="warning" showIcon style={{width:'100%'}} />
        <Grid container spacing={2} xs={12}>
          {' '}
          
          <Grid item xs={4}>
            <Form.Item
              name={'iptu'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'IPTU'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
              rules={[
                {
                  required: iptuMandatory,
                  message: 'preenchimento obrigatório',
                }
              ]}
            >
              <Input type="text" maxLength={15} placeholder="Apenas números" size={'large'} onChange={e=>handleIptu(e.target.value)} value={iptu.iptu}/>
              { 
                !iptu.iptu &&(
                  <p style={{color: '#ec2222', fontSize: 16, fontWeight: 400}}>Iptu obrigatório</p>
                )
              }
            </Form.Item>
          </Grid>
          <Grid item xs={4}>
            <Form.Item
              name={'cep'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'CEP'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
            >
              <Input size={'large'} disabled placeholder={`${cep}`} value={cep} style={{fontWeight: 'bold', color: '#353434'}}/>
            </Form.Item>
          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12}>
          
          <Grid item xs={4}>
            <Form.Item
              name={'type-place'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Tipo de Logradouro'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
              rules={[
                {
                  required: true,
                  message: 'preenchimento obrigatório',
                },
              ]}
            >
              <Input size={'large'} disabled   defaultValue={address.logType} />
              
            </Form.Item>
          </Grid>
          <Grid item xs={5}>
            <Form.Item
              name={'place'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Logradouro'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
              rules={[
                {
                  required: true,
                  message: 'preenchimento obrigatório',
                },
              ]}
            >
              <Input size={'large'} disabled   defaultValue={address.log} />
              
            </Form.Item>
          </Grid>
          <Grid item xs={3}>
            <Form.Item
              name={'number-place'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Nº Logradouro'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              rules={[
                {
                  required: true,
                  message: 'preenchimento obrigatório',
                },
              ]}
              labelAlign={'left'}
            >
              <Input size={'large'} placeholder="Número do logradouro" onChange={e=>handleNumLog(e.target.value)} value={address.numLog} />
              { 
                !address.numLog &&(
                  <p style={{color: '#ec2222', fontSize: 16, fontWeight: 400}}>Número de logradouro obrigatório</p>
                )
              }
            </Form.Item>
          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12}>
         
          <Grid item xs={4}>
            <Form.Item
              name={'neighborhood '}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Bairro/Distrito'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
              rules={[
                {
                  required: true,
                  message: 'preenchimento obrigatório',
                },
              ]}
            >
              
            <Input 
              size={'large'} 
              defaultValue={address.neighborhood} 
              style={{fontWeight: 'bold', color: '#353434'}} 
              disabled
            />
              
    
            </Form.Item>
          </Grid>
          <Grid item xs={3}>
            <Form.Item
              name={'city'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Munícipio'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
            >
              <Input size={'large'} disabled defaultValue={address.city} style={{fontWeight: 'bold', color: '#353434'}} />
                
            </Form.Item>
          </Grid>
          <Grid item xs={5}>
            <Form.Item
              name={'complement'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Complemento'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
            >
              <Input size={'large'} placeholder="Complemento" onChange={e=>handleComp(e.target.value)} value={address.complement} />
            </Form.Item>
          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12}>
          {' '}
          
          <Grid item xs={12}>
            <Form.Item
              name={'ref-place '}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Referência do Logradouro'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
            >
              <Input size={'large'} placeholder="Referência" onChange={e=>handleRefLog(e.target.value)} value={address.refLog} />
            </Form.Item>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={2}>
          
          <Grid item xs={6}>
            <Form.Item
              name={'area-total '}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Área Total da Edificação: (m²)'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              labelAlign={'left'}
              rules={[
                {
                  required: true,
                  message: 'preenchimento obrigatório',
                },
              ]}
            >
              <Input size={'large'} placeholder="Área total" onChange={e=>handleTotal(e.target.value)} value={areaT.totalArea} />
              { 
                !areaT.totalArea &&(
                  <p style={{color: '#ec2222', fontSize: 16, fontWeight: 400}}>Área total obrigatório</p>
                )
              }
            </Form.Item>
          </Grid>
          <Grid item xs={6}>
            <Form.Item
              name={'area-util'}
              style={{ display: 'flex', flexDirection: 'column' }}
              label={
                <LabelInput
                  text={'Área Utilizada : (m²)'}
                  style={{ color: '#333', fontWeight: 700 }}
                />
              }
              rules={[
                {
                  required: true,
                  message: 'preenchimento obrigatório',
                },
              ]}
              labelAlign={'left'}
            >
              <Input size={'large'} placeholder={'Área Utilizada'} onChange={e=>handleUtil(e.target.value)} value={areaT.utilArea}/>
              { 
                !areaT.utilArea &&(
                  <p style={{color: '#ec2222', fontSize: 14, fontWeight: 400}}>Área útil obrigatório</p>
                )
              }
            </Form.Item>
          </Grid>
        </Grid>
      </Grid>
    </Form>
    
  );
};

const FormAddress = () => {
  const [valueRender, setValueRender] = useState();
  const {  
    localNature, 
    setLocalNature
  } = useFormViability();


  function handleLocalNature(e:any){
    const { value } = e.target;
    setValueRender(value);
    let local = {
      ...localNature
    }
    local.local = e;
    setLocalNature(value);
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography
        variant={'body2'}
        style={{
          fontSize: '1.3em',
          fontWeight: 700,
          maxWidth: '100%',
          marginBottom: '1em',
        }}
      >
        *Endereço do Estabelecimento
      </Typography>
      <FormEvents layout={'horizontal'} onChange={handleLocalNature}>
        <Form.Item
          style={{ display: 'flex', flexDirection: 'column' }}
          label={
            <LabelInput
              style={{ color: '#333', fontWeight: 700 }}
              text={'*Natureza do imóvel'}
            />
          }
          labelAlign={'left'}
          rules={[
            {
              required: true,
              message: 'obrigatório',
            },
          ]}
        >
          <Radio.Group>
            {AddressOptions.map((el, index) => (
              <Radio key={index} value={index} >
                {el.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </FormEvents>

      

      {valueRender &&
        (Number(valueRender) === 0 || Number(valueRender) === 1) && (
          <FormAddressData iptuMandatory={true} />
        )}

      {valueRender && Number(valueRender) === 2 && (
        <FormAddressData iptuMandatory={false} />
      )}

      <Divider />
    </Box>
  );
};

export { FormAddress };
