import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Card } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom'

interface CardProps {
  title: string;
  link: string;
  icon: React.FunctionComponent<any>;
}


const CardContainer = styled(Card)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 60px 50px 190px 0;
  :hover{
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
`


const IconCards = ({
  title,
  link,
  icon: Icon,
}: CardProps) => {

  const history = useHistory();
  function pageNavigate(link: string) {
    global.window.location.href = link;
    history.push(link);
  }
  return (
    <Container style={{height: '350px'}}>
      <CardContainer onClick={() => pageNavigate(link)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '300px',
          height: '300px',
          padding: '150px',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#e7dfdf',
          boxShadow: '7px 5px 2px black'
        }}>

        <Box style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}>

          <Icon style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '50px',
            color: 'darkBlue',
          }}/>


          <Box >
            <Typography component={'h1'} style={{ 
              color: 'darkBlue', 
              fontSize: '22px', 
              
              width: '200px',
              }}>{title}</Typography>
          </Box>

        </Box>

      </CardContainer>
    </Container>
  );
};


export { IconCards }