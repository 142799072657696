import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { FiFileText } from 'react-icons/fi';
import { FaExchangeAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';
import UFForm  from '../../../Components/UFForm'

const InputBox = styled.div`
  width: 1150px;
  height: auto;
  background-color: #f9f8f8;
  padding: 30px 50px;
  margin-left: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 18px;
  display:'inline-block'; 
  margin-bottom: '5px';
`

const BoxRadio = styled.div`
  display: inline-block;
  flex-direction: row;
  width: auto;
  align-items: middle;
`

// const Continue = styled.button`
//   background-color: #337ab7;
//   color: #fff;
//   width: 90px;
//   height: 40px;
//   margin-left: 70px;
//   border-radius: 3px;
//   :hover{
//     background-color: #1e5585;
//     transition: background-color 0.2s;
//     cursor: pointer;
//   }
// `



const Collector = () => {

  const [option, setOption] = useState('');
  const [disabled, setDisabled] = useState(false);
  
  useEffect(() => {
    setOption('sim')
  },[])

  function disableToggle(){
      setDisabled(true);
  }

  function enableToggle(){
    setDisabled(false);
  }

  return(
      <ContainerCenter 
        title={"Coletor Nacional → Alterar Cadastro"}
        icon={FaExchangeAlt}>
        
      <form action="" method="PUT">

      <InputBox>
          <UFForm />
      </InputBox>
      <br/>
      <br/>
      <InputBox>
      <BoxRadio>
        <Label style={{paddingRight: '15px'}}>Alteração de endereço para outra UF/Município?</Label>
        <input type="radio" name="option-1" value="sim" id="sim" onClick={enableToggle}/>
        <Label>Sim</Label>

        <input type="radio" name="option-1" value="nao" id="nao" onClick={disableToggle}/>
        <Label>Não</Label>
      </BoxRadio>
      <br/>
      <Label>UF de Destino: </Label>
      <input type="text" name="destiny_state" style={{width: '200px', height: '25px'}} disabled={disabled}/>
      <br/>
      <Label>Município de destino: </Label>
      <input type="text" name="destiny_city" style={{width: '300px', height: '25px'}} disabled={disabled}/>
      </InputBox>
      <br/>
      <br/>
      <br/>

      <InputBox>
          <Label>Foi informado um dos eventos de viabilidade abaixo? </Label>
          <br/>
          <Label>Alteração de endereço dentro do mesmo município</Label>
          <Label>Alteração do nome empresarial</Label>
          <Label>Alteração de natureza jurídica</Label>
          <Label>Alteração de atividades econômicas (principal e secundária)</Label>
          <Label>Alteração do tipo de unidade</Label>
          <Label>Alteração da forma de atuação</Label> 
          <br/>
          <BoxRadio>
            <input type="radio" name="option-2" value="sim" id="sim" onChange={(e) => {
              setOption(e.target.value)}} 
              onClick={enableToggle}
              />
            <Label>Sim</Label>

            <input type="radio" name="option-2" value="nao" id="nao" onChange={(e)=> {
              setOption(e.target.value)
            }}  onClick={disableToggle}/> 
            <Label>Não</Label>
          </BoxRadio>
          <br/>
          <Label>Protocolo de Viabilidade</Label>
          <input type="text" name="protocol" style={{width: '200px', height: '25px'}} disabled={disabled}/>

          <br/>
          <Label>Caso escolha assinar o Documento Básico de Entrada - DBE utilizando o Certificado Digital, selecione a opção abaixo: </Label>
          <br/>
          <BoxRadio>
          <input type="checkbox" name="digital_certificate"/> 
          <Label>Transmitir com Certificado Digital</Label>
          <FiFileText size="50" color="#337ab7" />
          </BoxRadio>
      </InputBox>


          <Link to="/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/recupera-parametros"
            style={{marginLeft: '120px', fontSize: '20px'}}
          >
            { option === 'sim' && 
                'Recuperar'

            }
            { option === 'nao' && 
              'Continuar'
            }
          </Link>
      </form>
      </ContainerCenter>

  )
}

export default Collector