import React from 'react'

const Info = () => {
  return (
    <>
      <h1>Instruções de Preenchimento e Navegação</h1>
      <hr />

      <p>O número do Protoloco REDESIM é <strong>SPN00000</strong></p>

      <p><strong>Guarde</strong> este número, pois será utilizado caso seja necessário <strong>recuperar</strong> a solicitação.</p><br />
      <p>Você pode <strong>imprimir</strong> ou fazer o <strong>download</strong> do seu protocolo.</p>
    </>
  )
}

export default Info