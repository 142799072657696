import React, { createContext, useContext, useState } from 'react';

interface Events{
  act: string,
  objEvent: any[]
}
interface FormIntegratorContextData {
  dbe_protocol: string;
  setDbe_protocol: React.Dispatch<any>;
  dbe_receipt: string;
  setDbe_receipt: React.Dispatch<any>;
  dbe_identifier: string;
  setDbe_identifier: React.Dispatch<any>;
  legal_nature: string;
  setLegal_nature: React.Dispatch<any>;
  nominal_value_shares: number;
  setNominal_value_shares: React.Dispatch<any>;
  paidin_capital: number;
  setPaidin_capital: React.Dispatch<any>;
  declaration_signing_date: Date;
  setDeclaration: React.Dispatch<any>;
  company_email: string;
  setCompanyEmail: React.Dispatch<any>;
  dae: string;
  setDae: React.Dispatch<any>;
  events: Events;
  setEvents: React.Dispatch<any>;
  issuing_agency: string;
  setIssuing_agency: React.Dispatch<any>;
}
const FormIntegratorContext = createContext({} as FormIntegratorContextData);

const FormIntegratorProvider: React.FC = ({ children }) => {
  const [dbe_protocol, setDbe_protocol] = useState<string>('');
  const [dbe_receipt, setDbe_receipt] = useState<string>('');
  const [dbe_identifier, setDbe_identifier] = useState<string>('');
  const [legal_nature, setLegal_nature] = useState<string>('');
  const [nominal_value_shares, setNominal_value_shares] = useState<number>(Number);
  const [paidin_capital, setPaidin_capital] = useState<number>(Number);
  const [declaration_signing_date, setDeclaration] = useState<Date>(new Date());
  const [company_email, setCompanyEmail] = useState<string>('');
  const [dae, setDae] = useState<string>('');
  const [events, setEvents] = useState({} as Events);
  const [issuing_agency, setIssuing_agency ] = useState('');


  return (
    <FormIntegratorContext.Provider value={{ 
        dbe_protocol,
        setDbe_protocol,
        dbe_receipt,
        setDbe_receipt,
        dbe_identifier,
        setDbe_identifier,
        legal_nature,
        setLegal_nature,
        nominal_value_shares,
        setNominal_value_shares,
        paidin_capital,
        setPaidin_capital,
        declaration_signing_date,
        setDeclaration,
        company_email,
        setCompanyEmail,
        dae,
        setDae,
        events,
        setEvents,
        issuing_agency,
        setIssuing_agency,
     }}>
      {children}
    </FormIntegratorContext.Provider>
  );
};

const useFormIntegrator = () => {
  const context = useContext(FormIntegratorContext);
  if (!context) throw new Error('UseFormIntegrator must be used within a Provider');
  const { 
      dbe_protocol,
    setDbe_protocol,
    dbe_receipt,
    setDbe_receipt,
    dbe_identifier,
    setDbe_identifier,
    legal_nature,
    setLegal_nature,
    nominal_value_shares,
    setNominal_value_shares,
    paidin_capital,
    setPaidin_capital,
    declaration_signing_date,
    setDeclaration,
    company_email,
    setCompanyEmail,
    dae,
    setDae,
    events,
    setEvents,
    issuing_agency,
    setIssuing_agency,
   } = context;
  return { 
    dbe_protocol,
    setDbe_protocol,
    dbe_receipt,
    setDbe_receipt,
    dbe_identifier,
    setDbe_identifier,
    legal_nature,
    setLegal_nature,
    nominal_value_shares,
    setNominal_value_shares,
    paidin_capital,
    setPaidin_capital,
    declaration_signing_date,
    setDeclaration,
    company_email,
    setCompanyEmail,
    dae,
    setDae,
    events,
    setEvents,
    issuing_agency,
    setIssuing_agency,
   };
};
export { FormIntegratorProvider, useFormIntegrator };
