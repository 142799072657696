import React, { createContext, useContext, useState } from 'react';

interface UserContextData {
  user: string;
  setUser: React.Dispatch<any>;
}
const UserContext = createContext({} as UserContextData);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<string>('');
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) throw new Error('UseUser must be used within a Provider');
  const { user, setUser } = context;
  return { user, setUser };
};
export { UserProvider, useUser };
