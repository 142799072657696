import React, { createContext, useContext, useState } from 'react';

interface CepContextData {
  state: boolean;
  setState: React.Dispatch<any>;
}
const StateContext = createContext({} as CepContextData);

const StateProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<boolean>(false);
  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};

const useStates = () => {
  const context = useContext(StateContext);
  if (!context) throw new Error('UseCep must be used within a Provider');
  const { state, setState } = context;
  return { state, setState };
};
export { StateProvider, useStates };
