import { Modal } from 'antd';
import React, { useContext, useState, SetStateAction } from 'react';
import { ActivityRow, AddressEstablishment, Cep, EstablishmentType, NameOption, PortCompany, Fantasy, Capital, SocialObject, IIptu, Areas, ILegalNature, SubEvent, NatureLocal, Society } from '../../types';

// Contexto para gerenciar os dados do formulário
// Só foram adicionados alguns dados
export interface FormContextData {
  subEvent: SubEvent;
  setSubEvent: React.Dispatch<SetStateAction<any>>;
  activitiesSecondary: ActivityRow[];
  setActivitiesSecondary: React.Dispatch<SetStateAction<any>>;
  removeActivitySecondary: (activity: ActivityRow) => void;
  activityPrincipal: ActivityRow;
  setActivityPrincipal: React.Dispatch<SetStateAction<any>>;
  port: PortCompany;
  setPortCompany: React.Dispatch<SetStateAction<any>>;
  localNature: NatureLocal;
  setLocalNature: React.Dispatch<SetStateAction<any>>;
  nature: ILegalNature;
  setLegalNature: React.Dispatch<SetStateAction<any>>;
  society: Society;
  setSociety: React.Dispatch<SetStateAction<any>>;
  fantasyName: Fantasy;
  setFantasyName: React.Dispatch<SetStateAction<any>>;
  capital: Capital;
  setCapital: React.Dispatch<SetStateAction<any>>;
  cep: Cep;
  setCep: React.Dispatch<SetStateAction<any>>;
  socialObject: SocialObject;
  setSocialObject: React.Dispatch<SetStateAction<any>>;
  names: NameOption;
  setNames: React.Dispatch<SetStateAction<any>>;
  iptu: IIptu;
  setIptu: React.Dispatch<SetStateAction<any>>;
  address: AddressEstablishment;
  setAddress: React.Dispatch<SetStateAction<any>>;
  areaT: Areas;
  setAreaT: React.Dispatch<SetStateAction<any>>;
  establishment: EstablishmentType;
  setEstablishment: React.Dispatch<SetStateAction<any>>;
  finishForm: () => void;
}

const FormContext = React.createContext({} as FormContextData);

const FormProviderViability: React.FC = ({ children }) => {
  const [subEvent, setSubEvent] = useState<SubEvent>({} as SubEvent)
  const [activitiesSecondary, setActivitiesSecondary] = useState<ActivityRow[]>(
    []
  );
  const [activityPrincipal, setActivityPrincipal] = useState<ActivityRow>(
    {} as ActivityRow
  );

  const [port, setPortCompany] = useState<PortCompany>({} as PortCompany)
  const [localNature, setLocalNature] = useState<NatureLocal>({} as NatureLocal)
  const [nature, setLegalNature] = useState<ILegalNature>({} as ILegalNature);
  const [society, setSociety] = useState<Society>({} as Society)
  const [fantasyName, setFantasyName] = useState<Fantasy>({} as Fantasy);
  const [capital, setCapital] = useState<Capital>({} as Capital)
  const [cep, setCep] = useState<Cep>({} as Cep)
  const [socialObject, setSocialObject] = useState<SocialObject>({} as SocialObject)
  const [names, setNames] = useState<NameOption>({} as NameOption)
  const [iptu, setIptu] = useState<IIptu>({} as IIptu)
  const [address, setAddress] = useState<AddressEstablishment>({
    uf: '',
    city: '',
    complement: '',
    neighborhood: ''
  } as AddressEstablishment)
  const [areaT, setAreaT] = useState<Areas>({} as Areas)
  const [establishment, setEstablishment] = useState<EstablishmentType>({
    type: '',
    option: []
  } as EstablishmentType)


  function removeActivitySecondary(activity: ActivityRow) {
    let activitiesFiltered = activitiesSecondary.filter(
      (el) => el !== activity
    );
    setActivitiesSecondary(activitiesFiltered);
  }
  // pega todos os dados e faz o termino do formulário;

  function finishForm() {
   Modal.success({
     content: 'Viabilidade Concluída!'
   })
  }
  return (
    <FormContext.Provider
      value={{
        subEvent, 
        setSubEvent,
        activitiesSecondary,
        setActivitiesSecondary,
        removeActivitySecondary,
        activityPrincipal,
        setActivityPrincipal,
        port,
        setPortCompany,
        nature, 
        setLegalNature,
        society,
        setSociety,
        localNature, 
        setLocalNature,
        fantasyName,
        setFantasyName,
        capital,
        setCapital,
        cep,
        setCep,
        socialObject,
        setSocialObject,
        names,
        setNames,
        iptu,
        setIptu,
        address,
        setAddress,
        areaT,
        setAreaT,
        establishment,
        setEstablishment,
        finishForm,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useFormViability = () => {
  const context = useContext(FormContext);
  if (!context) throw new Error('UseContext must be used within a Provider');
  const {
    subEvent, 
    setSubEvent,
    activitiesSecondary,
    setActivitiesSecondary,
    removeActivitySecondary,
    activityPrincipal,
    setActivityPrincipal,
    port,
    setPortCompany,
    nature, 
    setLegalNature,
    localNature, 
    setLocalNature,
    society,
    setSociety,
    fantasyName,
    setFantasyName,
    capital,
    setCapital,
    cep,
    setCep,
    socialObject,
    setSocialObject,
    names,
    setNames,
    iptu,
    setIptu,
    address,
    setAddress,
    areaT,
    setAreaT,
    establishment,
    setEstablishment,
    finishForm,
  } = context;
  return {
    subEvent, 
    setSubEvent,
    activitiesSecondary,
    setActivitiesSecondary,
    removeActivitySecondary,
    activityPrincipal,
    setActivityPrincipal,
    port,
    setPortCompany,
    nature, 
    setLegalNature,
    localNature, 
    setLocalNature,
    society,
    setSociety,
    fantasyName,
    setFantasyName,
    capital,
    setCapital,
    cep,
    setCep,
    socialObject,
    setSocialObject,
    names,
    setNames,
    iptu,
    setIptu,
    address,
    setAddress,
    areaT,
    setAreaT,
    establishment,
    setEstablishment,
    finishForm,
  };
};
export { FormProviderViability, useFormViability };
export default FormContext;
