import { Alert, Input, Radio, Select } from 'antd';
import React, { useState } from 'react';
import { FaCalculator } from 'react-icons/fa';
import styled from 'styled-components';
import { useFormDbe } from '../../../../../../../../../../../../hooks/formDbe';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`

const { Option } = Select;


const Contabilist: React.FC = () => {
    const [value, setValue] = useState(2)
    const { contabilist, setContabilist } = useFormDbe();

    const onChange = (e:any)=> {
        setValue(e.target.value)
    }

    function getCnpj(e: any) {
        setContabilist({
          ...contabilist,
          cpf: e,
        });
      }
      
    function getClassification(e: any) {
    setContabilist({
        ...contabilist,
        classificationCrc: e,
    });
    }
      
    function getSeqCrc(e: any) {
    setContabilist({
        ...contabilist,
        sequenceCrc: e,
    });
    }
      
    function getTypeCrc(e: any) {
        setContabilist({
          ...contabilist,
          crcType: e,
        });
    }

    function getEnterpriseName(e: any) {
        setContabilist({
          ...contabilist,
          enterpriseName: e,
        });
      } 

    function handleCPFMask(e: any){
        let value = e;
        if(e.length <= 14){
            value = value.replace(/\D/g,'');
            value = value.replace(/(\d{1})(\d{8})$/,'$1.$2');  
            value = value.replace(/(\d{1})(\d{5})$/,'$1.$2');  
            value = value.replace(/(\d{1})(\d{1,2})$/,'$1-$2');
        }else{
            value = value.replace(/\D/g,'');
            value = value.replace(/(\d{1})(\d{12})$/,'$1.$2');  
            value = value.replace(/(\d{1})(\d{9})$/,'$1.$2');  
            value = value.replace(/(\d{1})(\d{6})$/,'$1/$2');  
            value = value.replace(/(\d{1})(\d{1,2})$/,'$1-$2');
        }
        getCnpj(value);
    }

    return (
        <ContainerCenter 
            title={'Contabilista'} icon={FaCalculator}>
            <Header />
            <DivLeft>
                <FCPJ />
            </DivLeft>

            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Contabilista</p>
                <Alert 
                    message="Verifique junto à Secretaria da Fazenda Estadual de seu Estado se as informações desta Ficha são de preenchimento obrigatório. Sua solicitação poderá ser indeeferida caso o preenchimento seja necessário. Confirma o preenchimento?"
                    style={{marginLeft: 10}}
                >
                </Alert>
                    <Radio.Group value={value} onChange={onChange} style={{marginLeft: 10}}>
                        <Radio value={1}>Sim</Radio> 
                        <Radio value={2}>Não</Radio>
                    </Radio.Group>
                    <br/><br/>
                <div style={{marginLeft: 10}}>
                    <Label>CPF/CNPJ: </Label> <br/>
                    <Input 
                        style={{width: 150}}
                        type={'text'}
                        maxLength={18}
                        value={contabilist.cpf}
                        onChange={e => handleCPFMask(e.target.value)}
                    /> <br/><br/>

                    <Label>Classificação CRC: </Label> <br/>
                    <Select defaultValue={contabilist.classificationCrc} style={{width: 200}} onChange={e=> getClassification(e)} > 
                        <Option value='profissional'>Profissional</Option>
                        <Option value="esc_society">Escritório Sociedade</Option>
                        <Option value="esc_unity">Escritório Individual</Option>
                    </Select> <br/> <br/>

                    <Label>UF CRC: </Label> <br/>
                    <Select defaultValue='CE' style={{width: 80}}> 
                        <Option value='uf'>CE</Option>
                    </Select> <br/> <br/>

                    <Label>SEQ CRC:</Label> <br/>
                    <Input style={{width: 200}} value={contabilist.sequenceCrc} onChange={e=> getSeqCrc(e.target.value)} /> <br/> <br/>

                    <Label>Tipo CRC:</Label> <br/>
                    <Select defaultValue={contabilist.crcType} style={{width: 200}} onChange={e => getTypeCrc(e)}>
                        <Option value="transfer">Transferido</Option>
                        <Option value="filial">Filiais</Option>
                        <Option value="temp">Provisório</Option>
                        <Option value="secundary">Secundário</Option>
                        <Option value="origin">Originário</Option>
                    </Select> <br/><br/>

                    <Label>Nome/Nome Empresarial: </Label><br/>
                    <Input style={{width: 400}} value={contabilist.enterpriseName} onChange={e=> getEnterpriseName(e.target.value)} /> <br/><br/>

                </div>
                </div>
            </DivRight>
        </ContainerCenter>
    );
}

export default Contabilist;