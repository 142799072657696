import React, { createContext, useContext, useState } from 'react';

interface CepContextData {
  cep: string;
  setCep: React.Dispatch<any>;
}
const CepContext = createContext({} as CepContextData);

const CepProvider: React.FC = ({ children }) => {
  const [cep, setCep] = useState<string>('');
  return (
    <CepContext.Provider value={{ cep, setCep }}>
      {children}
    </CepContext.Provider>
  );
};

const useCep = () => {
  const context = useContext(CepContext);
  if (!context) throw new Error('UseCep must be used within a Provider');
  const { cep, setCep } = context;
  return { cep, setCep };
};
export { CepProvider, useCep };
