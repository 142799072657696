import { ColumnType } from 'antd/lib/table'

interface ActivityI { 
    code:string;
    principal:boolean;
    description:string;
  }

export const PrincipalActivites: ColumnType<any>[] = [
    {
        title: 'Código',
        dataIndex: 'code'
    },
    {
        title: 'Descrição',
        dataIndex: 'description'
    },
    {
        title: 'Atividade Principal',
        dataIndex: 'principal'
    }
]

export const EconomicActivitySecondaryData:  ActivityI[] =[ 
    { 
      code:'TESTE',
      description:'TESTE',
      principal:true
    }, 
   
  ]