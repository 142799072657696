import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, makeStyles, Box } from '@material-ui/core';
import { Input, Button, Form, Modal } from 'antd';

import { useFlow } from '../../../../../hooks/flow';
import { useCep } from '../../../../../hooks/cep';
import { useFormViability } from '../../../../../hooks/formViability';

import { FaMapMarkerAlt } from 'react-icons/fa';
import { SearchOutlined } from '@ant-design/icons';

import { ContainerCenter } from '../../../../components/ContainerCenter';
import { LabelInput } from '../../../../../components';

import api from '../../../../../services/api';
import config from '../../../../../services/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  
}));

const CepViability = () => {
  const { routes, setNextLink } = useFlow();
  const history = useHistory();
  const classes = useStyles();
  const { setCep, cep } = useCep();
  const { setAddress, address } = useFormViability();
  const [data, setData] = useState<any>([]);
  const [isExist, setisExist] = useState<boolean>(false) //state para saber se existe esse CEP
  
  async function getData(){ //chamada a api para pegar os dados da fictional-person
    const res = await api.get('/fictional-person', config);
      setData(res.data);
      
  }


  function handleSearch() {
    const foundData = data.find((item: any) => cep === item.zip_code);
  
    if (foundData) {
      const info = {
        ...address,
        complement: foundData.complement,
        city: foundData.city,
        uf: foundData.state,
        neighborhood: foundData.neighborhood,
        log: foundData.public_place,
        logType: foundData.public_place.split(" ", 1),
      };
  
      setAddress(info);
      setisExist(true);
    } else {
      Modal.error({
        content: 'CEP não encontrado no sistema!'
      });
    }
  }
  
  /* eslint-disable react-hooks/exhaustive-deps */
  
  useEffect(() => {
    getData();
    

    const routeNext = routes.length - (routes.length - 2); // flow 101
    if (routeNext > routes.length) {
      
      history.replace('/viabilidade/eventos');
    }

    setNextLink(routes[routeNext]?.route); // position 1
  },[]);

  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <ContainerCenter
      icon={FaMapMarkerAlt}
      title={'Local da Viabilidade'}
      message={
        <span>
          <strong>Informe o município</strong> da localidade do estabelecimento
        </span>
      }
      buttonBack={true}
      buttonNext={isExist}
    >
      <Grid
        container
        alignItems={'center'}
        justify="flex-start"
        className={classes.container}
      >
        <Grid item xs={12}>
          <Box flexDirection={'row'}>
            <Form >
              <Form.Item
                name={'cep'}
                style={{ display: 'flex', flexDirection: 'column' }}
                label={
                  <LabelInput
                    style={{ color: '#333', fontWeight: 700 }}
                    text={'Informe o CEP do local da Viabilidade'}
                  />
                }
                labelAlign={'left'}
                rules={[
                  {
                    required: true,
                    message: 'preenchimento obrigatório',
                  },
                ]}
                // hasFeedback
              >
                <Box>
                  <Input
                    maxLength={8}
                    size={'large'}
                    placeholder={'cep'}
                    style={{
                      marginRight: '1em',
                      width: '30em',
                      maxWidth: '100%',
                    }}
                    onChange={e=>setCep(e.target.value)}
                  />
                  <Button
                    htmlType={'submit'}
                    size={'large'}
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={handleSearch}
                  >
                    Pesquisar
                  </Button>
                </Box>
               
                
              </Form.Item>
            </Form>
          </Box>
        </Grid>

        {isExist && (
          <div style={{display: 'flex', flexDirection: 'column'}}>
          
          <label style={{fontWeight: 'bold', fontSize: 15}}>Município: </label> 
          <Input disabled value={address.city} style={{width: 900, height: 40, marginBottom: 20, fontWeight: 'bold', color: '#202020'}} /> <br/>
          <label style={{fontWeight: 'bold', fontSize: 15}}>Bairro/Distrito: </label> 
          <Input disabled value={address.neighborhood} style={{width: 900, height: 40, fontWeight: 'bold', color: '#202020'}} />
          </div>
        )}

      </Grid>
    </ContainerCenter>
  );
};
export default CepViability;
