import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, makeStyles, Box } from '@material-ui/core';
import { Alert, Divider, Checkbox, Modal, Button } from 'antd';

import { ContainerCenter } from '../../../../components/ContainerCenter';
import {
  UnorderedListOutlined,
  ContainerOutlined,
  FileTextFilled,
  FlagOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { FaMapMarkerAlt } from 'react-icons/fa';

import { InformationContainer } from './components/InformationContainer';

import FormContext, { useFormViability } from '../../../../../hooks/formViability';
import { useCep } from '../../../../../hooks/cep';
import { useProtocol } from '../../../../../hooks/protocol';

import {
  columnsActivityPrincipal,
  columnsActivitySecondary,
  columnsDevelopment,
  columnsSocialObject,
  DevelopmentData,
  EconomicActivityPrincipalData,
  EconomicActivitySecondaryData,
  LocationData,
  SocialObjectData,
  columnsDevelopmentName,
  DevelopmentNameData,
  UnityDevelopment,
  SocietyData,
} from './fakeData';

import api from '../../../../../services/api';
import config from '../../../../../services/auth';
import { ActivityRow } from '../../../../../../types';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  item: {
    margin: 0,
  },
  boxWarning: {
    padding: 15,
    marginTop: 10,
    borderLeft: '4px solid #e6c025',
  },
  boxSucess: {
    padding: 15,
    marginTop: 10,
    borderLeft: '4px solid #27ae60',
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    borderTop: '3px solid #d2d2d2',
  },
}));

interface MessageAlertProps {
  title: string;
  onChangeCheckbox: () => void;
  checkBox: boolean;
}


const endereco = {
  city: '',
  nature_property: '',
  postal_code: '',
  public_place: '',
  // public_place_type: '',
  // complement: '',
  num: 0,
  type: 'comercial',
  total_area_m2: 0,
  used_area_m2: 0,
  state: 0
}

const MessageAlert = ({
  title,
  onChangeCheckbox,
  checkBox,
}: MessageAlertProps) => {
  return (
    <Box>
      <h2 style={{ color: checkBox ? '#27ae60' : '#c6a10a' }}>{title}</h2>
      <h4 style={{ color: checkBox ? '#1e8449' : '#8a6d3b' }}>
        Confirmo que verifiquei todos os dados informados e estou ciente de que
        não poderei alterá-los após a conclusão da Viabilidade.
      </h4>
      <Divider />
      <Box display={'flex'} flexDirection="row" alignItems={'center'}>
        <Checkbox onChange={onChangeCheckbox} />
        <h5
          style={{
            color: checkBox ? '#1e8449' : '#8a6d3b',
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
            marginLeft: 10,
          }}
        >
          Confirmo que li e estou de acordo com as declarações acima.{' '}
        </h5>
      </Box>
    </Box>
  );
};
const FinalReport = () => {
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const history = useHistory();

  const classes = useStyles();
  const {
    port,
    setPortCompany,
    nature,
    setLegalNature,
    socialObject,
    setSocialObject,
    names,
    setNames,
    activityPrincipal,
    setActivityPrincipal,
    activitiesSecondary,
    setActivitiesSecondary,
    establishment,
    setEstablishment,
    iptu,
    setIptu,
    address,
    setAddress,
    areaT,
    setAreaT,
    subEvent,
    setSubEvent,
    localNature,
    setLocalNature,
    society
  } = useFormViability()
  const rest = useFormViability();
  const { cep } = useCep();
  const { protocol, setProtocol } = useProtocol();
  const [local, setLocal] = useState(endereco);
  const [actions, setActions] = useState<any>([]);
  const [activityPrincipalS, setActivityPrincipalS] = useState<any>();
  const [ufId, setUfId] = useState<number>(0);
  const [person, setPerson] = useState<string>('');
  const [userId, setUserId] = useState<number>(0);

  function randomProtocol() { //gerar protocolo

    const ano = new Date();
    const anoCorrente = String(ano.getFullYear());
    let arr: any = [];

    let initial: string = address.uf;

    let min = Math.ceil(0);
    let max = Math.floor(99999);

    arr = anoCorrente.charAt(2) + anoCorrente.charAt(3);

    let protocolo = initial + "P" + arr + min + Math.floor((max - min) * Math.random());
    setProtocol(protocolo);

  }

  async function handleUf() {


    const res = await api.get('/state', config);

    res.data.map((item: any) => {
      if (address.uf === item.uf) {
        setUfId(item.id);
      }
      return null;
    });
    
  }


  function arrayAddress() { //montar objeto de endereço
    let property = localNature.local === 1 ? 'Rural' : 'Urbana';


    if (ufId !== 0) {

      const addresses = {
        city: address.city,
        nature_property: property,
        postal_code: cep,
        public_place: address.log,
        public_place_type: address.logType,
        complement: address.complement,
        num: address.numLog,
        total_area_m2: areaT.totalArea,
        used_area_m2: areaT.utilArea,
        type: 'comercial',
        state: ufId

      };
      setLocal(addresses);
    } else {

    }
  }


  function wayActing() { //montar objeto de formas de atuação
    const array = establishment.option.map((name, index) => {
      const trimmedName = establishment.option[index].trim().split('-')[1].trimLeft();
      return { name: trimmedName };
    });
    
    setActions(array);
  }

  function primaryActvity() { //montar objeto de atividade principal

    const primary = {
      is_principal: true,
      economic_activity: {
        id: activityPrincipal.id
      }
    }
    setActivityPrincipalS(primary);
  }

  function getUsername() {
    let getPerson;

    getPerson = sessionStorage.getItem(('username'));
    if (getPerson === null) {

      return null;
    }

    let decryptPerson = atob(getPerson)



    setPerson(decryptPerson);

    if (person) {
      getUserData();
    }
  }


  async function getUserData() {
    const res: any = await api.get(`/user/username/${person}`, config);

    setUserId(res.data.id);

  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    getUsername();

  }, [person])

  /* eslint-enable react-hooks/exhaustive-deps */

  async function sendData() { //objeto a ser enviado ao back-end


    const data = {
      first_name_option: `${names.name1}`,
      second_name_option: `${names.name2}`,
      third_name_option: `${names.name3}`,
      establishment: "matriz",
      unit: "produtiva",
      social_object: `${socialObject.body}`,
      rf_regularization: false,
      viability_address: local,
      fictional_person: {
        full_name: `${society.name}`
      },
      port: port.type,
      viability_activity: [
        activityPrincipalS,
      ],
      way_of_actings: actions,
      viability_protocol: `${protocol}`,
      user: {
        id: userId
      }
    }


    const response = await api.post('/aikjunta/viability/', data, config); //requisição de POST
    if (response.status === 200) {

      Modal.success({
        content: "Viabilidade cadastrada com sucesso!"
      })
    } else {

    }

    //Tentativa de resetar states do contexto
    //Resolver isso
    setPortCompany({});
    setLegalNature({});
    setSocialObject({});
    setNames({});
    setActivityPrincipal({});
    setActivitiesSecondary({
      cnae: '',
      local: false,
      occupation: '',
      id: 0
    });
    setEstablishment({
      type: '',
      option: []
    });
    setIptu({});
    setAddress({
      uf: '',
      city: '',
      complement: '',
      neighborhood: ''
    });
    setAreaT({});
    setSubEvent({});
    setLocalNature({});

    history.push('/viabilidade/eventos/final');


  }

  function triggerMethods() { //disparar as funções ao clicar no checkbox
    randomProtocol();
    arrayAddress();
    wayActing();
    primaryActvity();
    setCheckbox(!checkbox)
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    //Preenchimento de cada tabela da página final da viabilidade



    handleUf();


    DevelopmentData.forEach(item => {
      if (item.question === 'Enquadramento:') {
        if (port.type === 1) {
          item.answer = "Microempresa";
        } else if (port.type === 2) {
          item.answer = "Empresa de pequeno porte"
        } else if (port.type === 3) {
          item.answer = "Outros"
        }
      } else if (item.question === 'Município:') {
        item.answer = address.city;
      } else if (item.question === 'Natureza Jurídica:') {
        item.answer = nature.nature;

      } else if (item.question === 'Eventos:') {
        item.answer = subEvent.code;
      }
    })

    SocietyData.forEach(item => {
      if (item.question === 'Nome') {
        item.answer = society.name;
      } else if (item.question === 'CPF') {
        item.answer = society.cpf;
      }
    })

    LocationData.forEach(item => {
      if (item.question === 'Tipo Logradouro:') {
        item.answer = address.logType;
      } else if (item.question === 'CEP:') {
        item.answer = cep;
      }
      else if (item.question === 'Logradouro:') {
        item.answer = address.log
      }
      else if (item.question === 'Número:') {
        item.answer = address.numLog;
      }
      else if (item.question === 'Complemento:') {
        item.answer = address.complement;
      }
      else if (item.question === 'Índice do IPTU ou ITR:') {
        item.answer = iptu.iptu;
      }
      else if (item.question === 'Referência do Logradouro:') {
        item.answer = address.refLog
      }
      else if (item.question === 'Área Total do Empreendimento:') {
        item.answer = areaT.totalArea;
      }
      else if (item.question === 'Área Utilizada:') {
        item.answer = areaT.utilArea;
      }
    })

    SocialObjectData.forEach(item => {
      item.description = socialObject.body
    })

    EconomicActivityPrincipalData.forEach((item) => {
      item.description = activityPrincipal.description;
      item.cnae = activityPrincipal.cnae;
    })


    EconomicActivitySecondaryData?.forEach((item: ActivityRow, i: number) => {
      item.cnae = activitiesSecondary[i]?.cnae;
      item.description = activitiesSecondary[i]?.description;




    })


    DevelopmentNameData.forEach(item => {
      if (item.option === 'Primeiro Nome:') {
        item.name = names.name1;
      }
      else if (item.option === 'Segundo Nome:') {
        item.name = names.name2;
      }
      else if (item.option === 'Terceiro Nome:') {
        item.name = names.name3;
      }
    })

    UnityDevelopment.forEach(item => {
      if (item.question === 'Tipo de Unidade do Empreendimento:') {
        item.answer = establishment.type;
      } else if (item.question === 'Forma de Atuação/Tipo de Unidade:') {
        item.answer = establishment.option;
      }
    })

  }, [activityPrincipalS])

  /* eslint-enable react-hooks/exhaustive-deps */

  return (

    <FormContext.Provider value={{ ...rest }} >
      <ContainerCenter
        title={'Resumo e confirmação de Dados'}
        message={
          'Verifique todos os dados abaixo, caso estejam corretos aceite o termo e clique em concluir '
        }
        icon={UnorderedListOutlined}
        buttonBack={true}
        buttonFinish={false}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <InformationContainer
              title={'Dados do Empreendimento'}
              icon={ContainerOutlined}
              columns={columnsDevelopment}
              dataList={DevelopmentData}

            />
          </Grid>
          <Grid item xs={12}>
            <InformationContainer
              title={'Dados da Localidade'}
              icon={FaMapMarkerAlt}
              columns={columnsDevelopment}
              dataList={LocationData}
            />
          </Grid>
          <Grid item xs={12}>
            <InformationContainer
              title={'Atividade Econômica Primária'}
              icon={FlagOutlined}
              columns={columnsActivityPrincipal}
              dataList={EconomicActivityPrincipalData}
            />
          </Grid>
          <Grid item xs={12}>
            <InformationContainer
              title={'Atividade(s) Econômica(s) Secundária(s)'}
              icon={FlagOutlined}
              columns={columnsActivitySecondary}
              dataList={EconomicActivitySecondaryData}
            />
          </Grid>
          <Grid item xs={12}>
            <InformationContainer
              title={'Objeto Social'}
              icon={FileTextFilled}
              columns={columnsSocialObject}
              dataList={SocialObjectData}
            />
          </Grid>
          <Grid item xs={12}>
            <InformationContainer
              title={'Opções de Nome do Empreendimento'}
              columns={columnsDevelopmentName}
              dataList={DevelopmentNameData}
            />
          </Grid>
          <Grid item xs={12}>
            <InformationContainer
              title={'Dados Sócio(s)'}
              columns={columnsDevelopment}
              dataList={SocietyData}
            />
          </Grid>

          <Grid item xs={12}>
            <InformationContainer
              title={'Dados Tipo de Unidade do Empreendimento'}
              icon={UnorderedListOutlined}
              columns={columnsDevelopment}
              dataList={UnityDevelopment}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert
              type={checkbox ? 'success' : 'warning'}
              className={checkbox ? classes.boxSucess : classes.boxWarning}
              message={
                <MessageAlert
                  onChangeCheckbox={triggerMethods}
                  title={'Termos e Condições:'}
                  checkBox={checkbox}

                />
              }
            />
          </Grid>
        </Grid>
        <Button
          disabled={!checkbox}
          onClick={sendData}
          size={'large'}
          style={{
            backgroundColor: '#27ae60',
            color: '#fff',
            borderColor: '#229955',
            marginLeft: 1099,
            position: 'absolute',
            marginTop: 40,

          }}
          icon={<CheckOutlined />}
        >
          Concluir
        </Button>
      </ContainerCenter>
    </FormContext.Provider>
  );
};
export default React.memo(FinalReport);
