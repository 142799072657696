import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useFormIntegrator } from '../../../../../hooks/formIntegrator';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 400,
      height: 400,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

function not(a: any[], b: any[]) { //valores de A que não estão em B
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]) { //valores de A que estão em B
  return a.filter((value) => b.indexOf(value) !== -1);
}


export default function TransferList() {
  const eventsJucec: any[] = ([
    {
      name: '2211 - ALTERAÇÃO DE ENDERECO DENTRO DO MESMO MUNICÍPIO',
      id: 2211
    },
    {
      name: '027 - ALTERACAO DE FILIAL EM OUTRA UF',
      id: 27
    },
    {
      name: '024 - ALTERACAO DE FILIAL NA UF DA SEDE',
      id: 24
    },
    {
      name: '2015 - ALTERACAO DE OBJETO SOCIAL',
      id: 2015
    },
    {
      name: '2221 - ALTERACAO DO TITULO DO ESTABELECIMENTO (NOME FANTASIA)',
      id: 2221
    },
    {
      name: '046 - TRANSFORMACAO',
      id: 46
    },
    {
      name: '032 - ABERTURA DE FILIAL EM OUTRO PAIS',
      id: 32
    },
    {
      name: '208 - EMANCIPACAO (QUANDO INSERIDA PROCESSO)',
      id: 208
    },
    {
      name: '316 - ENQUADRAMENTO DE EPP',
      id: 316
    },
    {
      name: '315 - ENQUADRAMENTO DE MICROEMPRESA',
      id: 315
    },
    {
      name: '1501 - PEDIDO DE RECONSIDERAÇÃO',
      id: 1501
    },
    {
      name: '206 - PRICURACAO (QUANDO INSERIDA PROCESSO)',
      id: 206
    },
    {
      name: '309 - REENQUADRAMENTO DE EPP COM MICROEMPRESA',
      id: 309
    },
    {
      name: '307 - REENQUADRAMENTO DE ME COMO EPP',
      id: 307
    },
    {
      name: '026 - ABERTURA DE FILIAL EM OUTRA UF',
      id: 26
    },
    {
      name: '023 - ABERTURA DE FILIAL NA UF DA SEDE',
      id: 23
    },
    {
      name: '2244 - ALTERACAO DE ATIVIDADES ECONOMICAS (PRINCIPAL E SECUNDARIAS)',
      id: 2244
    },
    {
      name: '2209 - ALTERACAO DE ENDERECO ENTRE MUNICIPIOS DENTRO DO MESMO ESTADO',
      id: 2209
    },
    {
      name: '2247 - ALTERACAO DE CAPITAL SOCIAL',
      id: 2247
    }
  ])

  const classes = useStyles();
  const [checked, setChecked] = React.useState<any[]>([]);
  const [left, setLeft] = React.useState<any[]>(eventsJucec);
  const [right, setRight] = React.useState<any[]>([]);
  const {events, setEvents} = useFormIntegrator();  

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  
  const handleChangeEvents = () => {
    setEvents({ ...events, objEvent: [...right] })
  }

  const handleToggle = (value: any) => () => {


    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];


    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    handleChangeEvents();
  },[right])
  
  /* eslint-enable react-hooks/exhaustive-deps */
  
  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));   
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: any[]) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((item, index) => {
          const labelId = `transfer-list-item-${item.name}-label`;

          return (
            <ListItem key={item.id} role="listitem" button onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={item.id} primary={`${item.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item >{customList(left)}</Grid>
      <Grid item >
        <Grid container direction="column" alignItems="center" >
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            Adicionar todos
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            Adicionar
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            Remover
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            Remover todos
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}