import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

const FCPJCard = styled.div`
  width: 200px;
  height: 270px;
  background-color: #faf6f6;
  border: 1px solid #000;
  margin-bottom: 10px;
`

const FCPJ = () => {

  return(
    <FCPJCard>
    <p style={{textAlign: 'center', backgroundColor: '#a3a2a2'}}>FCPJ</p>
    <ul style={{listStyle: 'none', textAlign: 'left', marginLeft: 5}}>
      <li><Link to="/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/eventos" >Eventos</Link></li>
      <li><Link to="/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/identificacao">Identificação</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/atividade'>Atividade Econômica</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/objeto'>Objeto Social</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-pj'>Endereço PJ</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/contact'>Dados para Contato</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/representante'>Representante/Preposto</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-pj'>Endereço Representante PJ</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/porte'>Porte da Empresa</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/contabilista'>Contabilista</Link></li>
      <li><Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-contabilista'>Endereço do Contabilista - PF</Link></li>
    </ul>
    </FCPJCard>
  )
}

export default FCPJ;