import { Button } from 'antd';
import React from 'react';
import { FaBook } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../../../components/ContainerCenter';


const ContainerText = styled.div`
  width: auto;
  height: auto;
  margin-left: 27px;
`

const InfoRegistry = () => {
  const history = useHistory()
  return(
    <ContainerCenter title={'O que são "Registro" e "Inscrições Tributárias"'} icon={FaBook}>
      <ContainerText>
        <p>O <strong>Registro</strong> consiste na análise e arquivamento do ato de constituição de uma pessoa jurídica pelo órgão de registro. Os órgãos de registro podem ser a 
        <br/> Junta Comercial, os Cartórios de Registro Civil de Pessoa Jurídica ou a OAB.</p>
        <br/>
        <p>As <strong>Inscrições Tributárias</strong> são feitas no âmbito das Administrações Tributárias: Receita Federal, Secretarias de Fazenda Estaduais e Municipais. </p>
      </ContainerText>
      <Button onClick={()=> history.push('/redesim/abrir-pessoa-juridica/coletar-dados')} type="primary" style={{marginLeft: 25}}>
        Voltar
      </Button>
    </ContainerCenter>
  )
}

export default InfoRegistry