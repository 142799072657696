import React, { useState } from 'react';
import { useEffect } from 'react';
import { FaFile, FaIdCard } from 'react-icons/fa';
import styled from 'styled-components';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';


const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`

const DivBrief = styled.div`
    width: 800px;
    height: 300px;
    border: 1px solid #000;
    background-color: #eee;
    margin-bottom: 20px;
`

const DivData = styled.div`
    width: auto;
    height: 300px;
    background-color: #fff;
    border: 1px solid #000;
    overflow-y: scroll;
    scroll-behavior: auto;
    
`

const Brief = () => {
    const { protocol } = useProtocol();
    const [ name, setName ] = useState('');
    const [ cnae, setCnae ] = useState<number>();
    const [ unit, setUnit ] = useState<string>('');
    const [ object, setObject ] = useState('');
    const [ code, setCode ] = useState('');
    const [ typePlace, setTypePlace ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ city, setCity ] = useState('');
    const [ num, setNum ] = useState<number>();
    const [ comp, setComp ] = useState('');
    const [ cnaeNum, setCnaeNum ] = useState<string>();
    const [ phone, setPhone ] = useState<string>();
    const [ capital, setCapital ] = useState<number>();
    const [ email, setEmail ] = useState<string>();
    const [ cpf, setCpf ] = useState<string>('')
    const [ personaName, setPersonaName ] = useState<string>('')
    const [person,setPerson] = useState<any>();


    async function getData(){
        const res = await api.get(`/aikjunta/viability/${protocol}/`, config);
        // console.log(res.data);
        setName(res.data.first_name_option);
        setCnae(res.data.viability_activity[0].id);
        setCapital(res.data.fictional_person.capital_value);
        setPhone(res.data.fictional_person.contact_phone);
        setCpf(res.data.fictional_person.cpf);
        setPersonaName(res.data.fictional_person.full_name);
        setEmail(res.data.fictional_person.email);
        setUnit(res.data.unit);
        setObject(res.data.social_object);
        setCode(res.data.viability_address.postal_code)
        setTypePlace(res.data.viability_address.public_place_type)
        setPlace(res.data.viability_address.public_place)
        setCity(res.data.viability_address.city);
        setNum(res.data.viability_address.num);
        setComp(res.data.viability_address.complement);

        getCnae();
        getUserData();
    }

    function getUsername(){
        let getPerson;

        getPerson = sessionStorage.getItem(btoa('username'));
        if(getPerson === null) return null;
     
        let decrypted = atob(getPerson);
      
        setPerson(decrypted);

        if(person !== undefined){
            getUserData();
        }
        
    }

    async function getCnae(){
        const res = await api.get(`/aikmei/economic-activity/${cnae}`, config)
        setCnaeNum(res.data.cnae);
    }



    async function getUserData(){
        const res:any = await api.get(`/user/username/username/${person}`, config);
        console.log(res.data);
    }
    
/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(()=> {
        getData();
        getUsername();
    }, [])

/* eslint-enable react-hooks/exhaustive-deps */

    return(
      <ContainerCenter title={'Resumo do Documento'} icon={FaFile}>
          <Header/>

          <DivLeft>
              <FCPJ />
          </DivLeft>

          <DivRight>
              <DivBrief>
                <DivData>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Resumo do Documento</strong> </p>
                        <hr style={{marginLeft: -10}}/>
                        <p><strong>Protocolo REDESIM: {protocol}</strong></p>
                    </div>
                    <br/>
                    <hr />
                    <br/>
                    <div style={{backgroundColor: '#eee'}}>
                        <b style={{marginLeft: 10}}>{<FaIdCard/>} &emsp;FCPJ</b>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Eventos</strong></p>
                        <p>101 - Inscrição de Primeiro Estabelecimento </p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Identificação da Pessoa Jurídica</strong></p>
                        <Label>Número do CNPJ</Label>
                        <br/>
                        <br/>
                        <Label>Nome Empresarial (firma ou denominação)</Label>
                        <p>{name.toLocaleUpperCase()}</p>
                        
                        <Label>Natureza Jurídica</Label>
                        <p>213-5 Empresário (Individual)</p>
                        <Label>Capital Social</Label>
                        <p>R$ {capital?.toLocaleString('pt-BR')}</p>
                        <Label>Título do Estabelecimento (Nome Fantasia)</Label>
                        <p>{name}</p>
                        <Label>Tipo de Órgão</Label>
                        <p>Junta Comercial</p>
                        <Label>Nire </Label>
                        <br/>
                        <br/>
                        <Label>Número de Registro</Label>
                        <br/>
                        <br/>
                        <Label>CNPJ do órgão de registro</Label>
                        <br/>
                        <br/>
                        <Label>Ente Federativo Responsável</Label>
                        <p>União</p>
                        <Label>Tipo de Estatal (Dependência Orçamentária)</Label>
                        <br/>
                        <br/>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Atividade Econômica</strong></p>
                        <Label>Código CNAE principal</Label>
                        <p>{cnaeNum}</p>
                        <Label>Desdobramento da CNAE Principal</Label>
                        <br/>
                        <br/>
                        <Label>CNPJ do Administrador</Label>
                        <br/>
                        <br/>
                        <Label>Tipo de Unidade</Label>
                        <p>{unit}</p>
                        <Label>Forma de atuação</Label>
                        <p>Estabelecimento Fixo</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Objeto Social</strong></p>
                        <Label>Objeto Social</Label>
                        <p>{object}</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}> <strong>Endereço da Pessoa Jurídica</strong> </p>
                        <Label>CEP</Label>
                        <p>{code}</p>
                        <Label>Tipo de Logradouro</Label>
                        <p>{typePlace}</p>
                        <Label>Logradouro</Label>
                        <p>{place}</p>
                        <Label>Bairro/Distrito</Label>
                        <p>São Vicente</p>
                        <Label>Distrito</Label>
                        <p>São Vicente</p>
                        <Label>Município</Label>
                        <p>{city}</p>
                        <Label>UF</Label>
                        <p>CE</p>
                        <Label>Número</Label>
                        <p>{num}</p>
                        <Label>Complemento</Label>
                        <p>{comp}</p>
                        <Label>Referência</Label>
                        <p>Hospital</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Dados para contato</strong></p>
                        <Label>Telefone</Label>
                        <p>{phone}</p>
                        <Label>Correio Eletrônico</Label>
                        <p>{email}</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Representante/Preposto</strong></p>
                        <Label>Identificação do representante</Label>
                        <p>Representante</p>
                        <Label>Nome do Representante</Label>
                        <p>{personaName}</p>
                        <Label>CPF</Label>
                        <p>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</p>
                        <Label>Qualificação do representante</Label>
                        <p>50 - Empresário</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}> <strong>Endereço da Pessoa Jurídica</strong> </p>
                        <Label>CEP</Label>
                        <p>63700-070</p>
                        <Label>Tipo de Logradouro</Label>
                        <p>Rua</p>
                        <Label>Logradouro</Label>
                        <p>Moura Fé</p>
                        <Label>Bairro/Distrito</Label>
                        <p>São Vicente</p>
                        <Label>Distrito</Label>
                        <p>São Vicente</p>
                        <Label>Município</Label>
                        <p>Crateús</p>
                        <Label>UF</Label>
                        <p>CE</p>
                        <Label>Número</Label>
                        <p>924</p>
                        <Label>Complemento</Label>
                        <p>Casa</p>
                        <Label>Referência</Label>
                        <p>Hospital</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Porte da Empresa</strong></p>
                        <Label>Porte</Label>
                        <p>Micro Empresa</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}><strong>Contabilista</strong></p>
                        <Label>CPF / CNPJ</Label>
                        <p>000.000.000-00</p>
                        <Label>Classificação CRC</Label>
                        <p>Profissional</p>
                        <Label>UF CRC</Label>
                        <p>CE</p>
                        <Label>SEQ CRC</Label>
                        <br/>
                        <br/>
                        <Label>Tipo CRC</Label>
                        <p>Transferido</p>
                        <Label>Nome / Nome Empresarial</Label>
                        <p>Johnny Rosa</p>
                    </div>
                    <hr/>
                    <br/>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <p style={{backgroundColor: '#ddd'}}> <strong>Endereço da Pessoa Jurídica</strong> </p>
                        <Label>CEP</Label>
                        <p>63700-070</p>
                        <Label>Tipo de Logradouro</Label>
                        <p>Rua</p>
                        <Label>Logradouro</Label>
                        <p>Moura Fé</p>
                        <Label>Bairro/Distrito</Label>
                        <p>São Vicente</p>
                        <Label>Distrito</Label>
                        <p>São Vicente</p>
                        <Label>Município</Label>
                        <p>Crateús</p>
                        <Label>UF</Label>
                        <p>CE</p>
                        <Label>Número</Label>
                        <p>924</p>
                        <Label>Complemento</Label>
                        <p>Casa</p>
                        <Label>Referência</Label>
                        <p>Hospital</p>
                    </div>

                   
                </DivData>
              </DivBrief>
          </DivRight>

      </ContainerCenter>
    )
}

export default Brief;