import { Select } from 'antd';
import React from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';


const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`


const FlowEvents = () => {
  return(
    <ContainerCenter title={'Eventos'} icon={FaExchangeAlt}>
      <Header />
      <DivLeft>
        <FCPJ />
      </DivLeft>

    <DivRight>
    <div style={{
      width: 700,
      height: 250, 
      border: '1px solid black', 
      marginBottom: 10}}>
      <p style={{fontWeight:'bold', textAlign: 'center', backgroundColor: '#ccc'}}>Evento (Motivo do Preenchimento)</p>
      <Select style={{width: 200, marginLeft: 250}} value={'101 - Inscrição de Matriz'} disabled={true}></Select>
    
    </div>
    </DivRight>
    </ContainerCenter>

  )
};

export default FlowEvents;