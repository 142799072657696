import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import jsPDF from 'jspdf'
import * as htmlToImage from 'html-to-image'
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useProtocol } from '../../../../../../../hooks/protocol';
import api from '../../../../../../../services/api';
import axios from 'axios';
import { useCep } from '../../../../../../../hooks/cep';
import config from '../../../../../../../services/auth';
import { useFormIntegrator } from '../../../../../../../hooks/formIntegrator';



const Header = styled.div`
  width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const BodyPdf = styled.div`
  width: 1100px;
  height: auto;
  border: 1px solid #000;
  display: flex;
  flex-direction: row;
`

const Remp: React.FC = () => {
  const history = useHistory();
  const { protocol } = useProtocol();
  const {cep, setCep} = useCep()
  const [name, setName] = useState('');
  const [civil_state, setCivil_state] = useState('');
  const [ father, setFather ] = useState('');
  const [mother, setMother] = useState('');
  const [birth, setBirth] = useState('');
  const [rg, setRg] = useState('');
  const [uf, setUf] = useState('');
  const [cpf, setCpf] = useState('');
  const [street, setStreet] = useState('');
  const [house_number, setHouse_number] = useState(0);
  const [neighborhood, setNeihborhood] = useState('');
  const [postal_code, setPostal_code] = useState('');
  const [city, setCity] = useState('');
  const [esta_street, setEsta_street] = useState('');
  const [number, setNumber] = useState(0);
  const [comp, setComp] = useState('');
  const [esta_city, setEsta_city] = useState('');
  const [district, setDistrict] = useState('');
  const [cnae1, setCnae1] = useState('');
  const [cnae2, setCnae2] = useState('');
  const [cnaeOccupation1, setCnaeOccupation1] = useState('');
  const [cnaeOccupation2, setCnaeOccupation2] = useState('');
  const [date, setDate] = useState('');
  const [capital, setCapital] = useState(0);
  const [sex, setSex] = useState('');
  const [compName, setCompName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const {events, company_email, issuing_agency} =useFormIntegrator();
  function handleBack(){
    history.push('/integrador/novocfn/docs');
  }

  async function getCep(){
    try{
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
      console.log(cep);
      console.log(response.data);
      setDistrict(response.data.bairro);
    }catch(err){
      alert('Atualiza a página, por favor');
    }
    
  }

  async function getData(){
    const res = await api.get(`/aikjunta/integrator/${protocol}`, config);
    console.log(res.data);
    setCapital(res.data.dbe.capital_value)
    console.log(res.data.dbe.viability.fictional_person);
    setName(res.data.dbe.viability.fictional_person.full_name)
    setCivil_state(res.data.dbe.viability.fictional_person.marital_status);
    setFather(res.data.dbe.viability.fictional_person.father_name)
    setMother(res.data.dbe.viability.fictional_person.mother_name);
    setBirth(res.data.dbe.viability.fictional_person.birth_date);
    setRg(res.data.dbe.viability.fictional_person.rg);
    setUf(res.data.dbe.viability.fictional_person.state);
    setCpf(res.data.dbe.viability.fictional_person.cpf);
    setStreet(res.data.dbe.viability.fictional_person.public_place);
    setHouse_number(res.data.dbe.viability.fictional_person.house_number);
    setNeihborhood(res.data.dbe.viability.fictional_person.neighborhood);
    setPostal_code(res.data.dbe.viability.fictional_person.zip_code);
    setCity(res.data.dbe.viability.fictional_person.city);
    console.log(res.data.dbe.viability);
    setEsta_street(res.data.dbe.viability.viability_address.public_place);
    setNumber(res.data.dbe.viability.viability_address.num);
    setComp(res.data.dbe.viability.viability_address.complement);
    setEsta_city(res.data.dbe.viability.viability_address.city);
    setCep(res.data.dbe.viability.viability_address.postal_code);
    setCnae1(res.data.dbe.viability.viability_activity[0].economic_activity.cnae);
    setCnaeOccupation1(res.data.dbe.viability.viability_activity[0].economic_activity.description);
    if(res.data.dbe.viability.viability_activity.legth > 1){
      setCnae2(res.data.dbe.viability.viability_activity[1].economic_activity.cnae);
      setCnaeOccupation2(res.data.dbe.viability.viability_activity[1].economic_activity.occupation);

    }
    getCep();
    setDate(res.data.declaration_signing_date);
    setSex(res.data.dbe.viability.fictional_person.sex);
    setCompName(res.data.dbe.viability.fictional_person.company_name);
    setCnpj(res.data.dbe.viability.fictional_person.cnpj);
    console.log(events)
    //const response = await api.get(`/aikjunta/integrator/${protocol}`);
    //console.log(response.data);
    //console.log(typeof(date));
  }
  let data = new Date(date);
  let birth_date = new Date(birth);
  

  const onButtonClick = () => {
    const elem = document.getElementById("divPDF")!;
    htmlToImage.toPng(elem)
    .then(function(dataUrl){
      const pdf = new jsPDF()
      pdf.addImage(dataUrl, 'JPEG', 0, 0, 200, 297)
      pdf.save('REMP.pdf')
    })
  }

  useEffect(()=> {
    getData();
  })


  return (
    <div>
      <div id="divPDF">
        <Header>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>Ministério do Desenvolvimento, Indústria e Comércio Exterior</p>
            <p>Secretaria de Comércio e Serviços</p>
            <p>Departamento Nacional de Registro do Comércio</p>
          </div>
          <div>
            <p style={{ fontSize: 25 }}><strong>REQUERIMENTO DE EMPRESÁRIO</strong></p>
          </div>
        </Header>
        <BodyPdf>
            <div style={{width: 550, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Número de indentificação do registro de empresa - NIRE da Sede</p>
            </div>
            <div style={{width: 550, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>NIRE da Filial (preencher somente se ato referente a filial)</p>
            </div>
        </BodyPdf>
        <BodyPdf>
            <div style={{width: 1100, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Nome do Empresário (completo sem abreviatura)</p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{name}</strong></p>
            </div>
        </BodyPdf>
        <BodyPdf>
        <div style={{width: 550, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Nacionalidade</p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>Brasileiro</strong></p>
            </div>
            <div style={{width: 550, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Estado Civil</p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{civil_state}</strong></p>
            </div>
        </BodyPdf>
        <BodyPdf>
        <div style={{width: 200, height: 100, border: '1px solid #000', display: 'flex'}}>
                <p style={{marginLeft: 10}}>Sexo</p>
                <div style={{width: 20, height: 20, border: '1px solid #000', marginLeft: 10, textAlign: 'center'}}>
                  {sex === 'masculino' && (
                    <strong>X</strong>
                  )}
                </div>
                <p style={{marginLeft: 10, fontWeight: 'bold'}}>M</p>
                <div style={{width: 20, height: 20, border: '1px solid #000', marginLeft: 10, textAlign: 'center'}}>
                  {sex === 'feminino' && (
                    <strong>X</strong>
                  )}
                </div>
                <p style={{marginLeft: 10, fontWeight: 'bold'}}>F</p>
            </div>
            <div style={{width: 950, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Regime de Bens <span style={{fontSize: 12}}>(se casado)</span></p>
            </div>
        </BodyPdf>
        <BodyPdf>
            <div style={{width: 550, height: 100, border: '1px solid #000', display: 'flex', flexDirection: 'column'}}>
                <p style={{marginLeft: 10}}>Filho de (pai)</p> 
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{father}</strong></p>
            </div>
            <div style={{width: 550, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>(mãe)</p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{mother}</strong></p>
            </div>
        </BodyPdf>
        <BodyPdf>
            <div style={{width: 250, height: 100, border: '1px solid #000', display: 'flex', flexDirection: 'column'}}>
                <p style={{marginLeft: 10}}>Nascido em (data de nascimento)</p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{birth_date.toLocaleDateString()}</strong></p>
            </div>
            <div style={{width: 250, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Identidade (número)</p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{rg}</strong></p>
            </div>
            <div style={{width: 250, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Órgão Emissor: </p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{issuing_agency}</strong></p>
            </div>
            <div style={{width: 150, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>UF: </p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{uf}</strong></p>
            </div>
            <div style={{width: 200, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>CPF: </p>
                <p style={{marginLeft: 10, fontSize: 16}}><strong>{cpf}</strong></p>
            </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 1100, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Emancipado por (forma de emancipação - somente no caso de menor)</p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 900, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Domiciliado na (logradouro - rua, av, etc.)</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{street}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Número: </p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{house_number}</strong></p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 500, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Complemento</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{comp}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Bairro/Distrito </p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{neighborhood}</strong></p>
          </div>
          <div style={{width: 200, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>CEP</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{postal_code}</strong></p>
          </div>
          <div style={{width: 150, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Cód. do Município(uso da junta comercial) </p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 900, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Município</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{city}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>UF </p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{uf}</strong></p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 1100, height: 100, border: '1px solid #000'}}>
            <p style={{fontSize: 20, fontWeight: 'bold', marginLeft: 10}}>declara, sob as penas da lei, não estar impedido de exercer atividade empreśario, que não possui outro registro de empresário e requer à Junta Comercial do Ceará</p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 200, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Cód do Ato</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{events.act.split('-')[0]}</strong></p>
          </div>
          <div style={{width: 400, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Descrição do Ato</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{events.act.split('-')[1]}</strong></p>
          </div>
          <div style={{width: 200, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Cód do Evento</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{events.objEvent[0].name.split('-')[0]}</strong></p>
          </div>
          <div style={{width: 500, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Descrição do Evento</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{events.objEvent[0].name.split('-')[1]}</strong></p>
          </div>
        </BodyPdf>
        <BodyPdf>
            <div style={{width: 1100, height: 100, border: '1px solid #000'}}>
                <p style={{marginLeft: 10}}>Nome Empresarial</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{compName}</strong></p>
            </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 900, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Logradouro - (rua, av, etc).</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{esta_street}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Número: </p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{number}</strong></p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 500, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Complemento</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{comp}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Bairro/Distrito </p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{district}</strong></p>
          </div>
          <div style={{width: 200, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>CEP</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{cep}</strong></p>
          </div>
          <div style={{width: 150, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Cód. do Município(uso da junta comercial) </p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 400, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Município</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{esta_city}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>UF </p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{uf}</strong></p>
          </div>
          <div style={{width: 300, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>País</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>Brasil</strong></p>
          </div>
          <div style={{width: 200, height: 100, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Correio Eletrônico (E-mail)</p>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{company_email}</strong></p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 350, height: 100, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Valor do Capital - R$</p>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{capital.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</strong></p>
          </div>
          <div style={{width: 750, height: 100, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Valor do Capital - por extenso</p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 350, height: 200, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Cód atividade Econômica - CNAE</p>
            <h4 style={{marginLeft: 10}}>Atividade principal</h4>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{cnae1}</strong></p>
            <h4 style={{marginLeft: 10}}>Atividade secundária</h4> <br/>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{cnae2}</strong></p>
          </div>
          <div style={{width: 750, height: 200, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Descrição do Objeto</p>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{cnaeOccupation1}</strong></p><br /><br />
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{cnaeOccupation2}</strong></p>
            
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 300, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Data de ínicio das Atividades</p>
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{data.toLocaleDateString()}</strong></p>
          </div>
          <div style={{width: 200, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Número de Inscrição no CNPJ </p>
              {events.act.split(' ')[0] !== '080' ? 
              <p style={{marginLeft: 10, fontSize: 16}}><strong>{cnpj}</strong></p>
              : ''
            }
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Transferência de sede ou de filial de outra UF | NIRE anterior</p>
             
          </div>
          <div style={{width: 100, height: 100, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>UF</p>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{uf}</strong></p>
          </div>
          <div style={{width: 250, height: 100, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Uso da Junta Comercial</p>
            <p style={{fontSize: 13}}>Dependente de autorização governamental: 1 - Sim 3 - Não </p>
            <div style={{width: 15, height: 15,marginLeft: 15, border: '1px solid #000'}} />
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 1100, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Assinatura da Firma pelo Empresário (ou pelo representante/assistente/gerente)</p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 300, height: 100, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Data da Assinatura: </p>
            <p style={{marginLeft: 10, fontSize: 16}}><strong>{new Date().toLocaleDateString()}</strong></p>
          </div>
          <div style={{width: 800, height: 100, border: '1px solid #000'}}> 
            <p style={{marginLeft: 10}}>Assinatura do Empresário</p>
          </div>
        </BodyPdf>
        <p style={{fontSize: 30, fontWeight: 'bold'}}>Para uso Exclusivo da Junta Comercial</p>
        <BodyPdf>
          <div style={{width: 350, height: 200, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}>Deferido.</p>
            <p style={{marginLeft: 10}}>Publique-se e Arquive-se.</p>
            <p style={{marginLeft: 30}}>__________________________</p>
            <p style={{marginLeft: 60}}>___/____/______</p>
          </div>
        </BodyPdf>
        <p style={{fontSize: 30, fontWeight: 'bold'}}>Declaração</p>
        <BodyPdf>
          <div style={{width: 1100, height: 250, border: '1px solid #000'}}>
            <p style={{marginLeft: 10}}> Declaro que opto pelo Simples Nacional e pelo Simei (arts. 12 e 18-A da Lei Complementar no 123/06), que não incorro em quaisquer das situações <br/>
                impeditivas a essas opções (arts. 3o, 17, 18-A e 29 da mesma lei) e que é fiel a cópia da minha identidade constante do verso deste formulário. <strong>Termo de</strong>  <br/>
                <strong> Ciência e Responsabilidade com Efeito de Alvará de Licença e Funcionamento Provisório.</strong>  Declaro, sob as penas da lei, que conheço e atendo os <br/>
                requisitos legais exigidos pela Prefeitura do Município para emissão do Alvará de Licença e Funcionamento, compreendidos os aspectos sanitários, <br/>
                ambientais, tributários, de segurança pública, uso e ocupação do solo, atividades domiciliares e restrições ao uso de espaços públicos. O não-atendimento a <br/>
                esses requisitos acarretará o cancelamento deste Alvará de Licença e Funcionamento Provisório.</p>
          </div>
        </BodyPdf>
        <BodyPdf>
          <div style={{width: 300, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Ata da Assinatura</p>
          </div>
          <div style={{width: 800, height: 100, border: '1px solid #000'}}>
              <p style={{marginLeft: 10}}>Assinatura do Empresário</p>
          </div>
        </BodyPdf>
      </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
          <Button onClick={handleBack} type='primary' icon={<ArrowLeftOutlined/>} size='large'>Voltar</Button>
          <Button onClick={onButtonClick} type='primary' icon={<DownloadOutlined/>} size='large'>Imprimir</Button>
        </div>
    </div>
  );
}

export default Remp;