import { FaUser, FaCaretSquareDown, FaArchive } from 'react-icons/fa'


interface Data{
  title: string;
  icon: React.FunctionComponent<any>;
  link: string;
}

export const DataCard: Data[] = [
  { 
    title: "Alteração Cadastral",
    link: '/dbe/redesim/ja-possuo-pessoa-juridica/alteracao',
    icon: FaUser
  },
  { 
    title: "Baixa",
    link: 'https://www.gov.br/empresas-e-negocios/pt-br/redesim/ja-possuo-pessoa-juridica/baixa',
    icon: FaCaretSquareDown
  },
  {
    title: "Licenças",
    link: "https://www.gov.br/empresas-e-negocios/pt-br/redesim/ja-possuo-pessoa-juridica/licencas",
    icon: FaArchive
  }
]