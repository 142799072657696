import {
  CardActionArea,
  Card as CardMaterial,
  makeStyles,
  CardContent,
  Box,
  Typography,
} from '@material-ui/core';
import React, { forwardRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '17em',
    height: '10em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardAction: {
    borderLeft: '4px solid rgba(159, 159, 159, 0.8)',
  },
  content: {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column',
    transition: 'all 300ms ease',
  },
}));
const RouterLink = forwardRef((props: any) => {
  return (
    <div style={{ flexGrow: 1 }}>
      <NavLink {...props} />
    </div>
  );
});
interface CardProps {
  title: string;
  description?: string;
  url: string;
}
const Card = ({ description, title, url }: CardProps) => {
  const classes = useStyles();
  const history = useHistory();

  function pageNavigate(url: string) {
    history.push(url);
  }

  function disabledNavigate(){
    history.block(() => {});
  }

  return (
    <CardActionArea
      className={classes.cardAction}
      to={url}
      component={RouterLink}
    >
      <CardMaterial
        variant="outlined"
        className={classes.card}
        onClick={ url ? () => pageNavigate(url) : ()=> disabledNavigate()}
      >
        <CardContent className={classes.content}>
          <Box display={'flex'} justifyContent="center" flex={1}>
            <Typography
              variant={'h2'}
              component={'h2'}
              style={{
                fontSize: 17,
                fontWeight: 600,
                color: '#555',
                maxWidth: '100%',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            mt={2}
            flex={1}
            display={'flex'}
            alignSelf={'center'}
            justifyContent={'center'}
          >
            <Typography
              style={{
                maxWidth: '100%',
                textAlign: 'center',
                fontSize: 13,
                color: '#666',
              }}
              variant={'body2'}
              component={'p'}
            >
              {description}
            </Typography>
          </Box>
        </CardContent>
      </CardMaterial>
    </CardActionArea>
  );
};
export default Card;
