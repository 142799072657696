import Hero1 from '../../../assets/dbe/ap.jpg'
import Hero2 from '../../../assets/dbe/lamp.jpg'
import Hero3 from '../../../assets/dbe/pizza.jpeg'


interface Data { 
  title:string;
  link:string;
  description:string;
  backgroundImg: string;
}

export const cardsData:Data[]= [
  {
    title: "Empreendedor",
    link: 'https://aikmei-app-aikontdigital.vercel.app/login',
    description:'MEI abra sua inscrição (CNPJ) aqui, acesso gratuito, sem complicações e com diversos benefícios',
    backgroundImg: `${Hero1}`
  },
  {
    title: "Créditos",
    link: 'https://www.gov.br/empresas-e-negocios/pt-br/credito',
    description:"Acesso digital a produtos e serviços financeiros para o seu negócio",
    backgroundImg: `${Hero2}`
  }, 
  {
    title: "Redesim",
    link: '/redesim',
    description: "Abra, altere ou baixe sua Pessoa Jurídica e acesse outros serviços para sua empresa ou negócios",
    backgroundImg: `${Hero3}`
  },
  
]