import { Grid, makeStyles } from '@material-ui/core';
import { Button } from 'antd';
import React from 'react';
import { FaBook } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';
import CardOpen from './components/CardOpen';
import { DataCard } from './model/Data'


const useStyles = makeStyles((theme) => ({
  gridCards: {
    margin: theme.spacing(2),
    padding: 'unset',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  }
}));

const DivInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  margin: 10px 100px 10px 100px;
`
const ButtonAction = styled(Button)`
  width: auto;
  height: 40px;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #3a5da7;
  color: #3a5da7;
  border-radius: 50px;
  :hover{
    background-color: #3a5da7;
    color: #fff;
  }
`


const DataCollector = () => {
  const history = useHistory()
  const classes = useStyles();
  return( 
    <ContainerCenter 
      title={'Informações para Registro e Inscrições Tributárias'}
      icon={FaBook}  
    >
      <Grid container spacing={2} className={classes.gridCards}>
          {DataCard.map((item, index) => {
            return(
              <Grid item key={index.toString()}>
                <CardOpen
                  icon={item.icon}
                  title={item.title}
                  link={item.link}
                  />
              </Grid>
            )
          })}
      </Grid>
      <DivInfo>

        <ButtonAction 
          onClick={()=>history.push('/redesim/abrir-pessoa-juridica/coletar-dados/info-juridica')} 
        >
          O que é "Coleta de dados?"
        </ButtonAction>

        <ButtonAction 
          onClick={()=>history.push('/redesim/abrir-pessoa-juridica/coletar-dados/info-registro')} 
        >
        O que são "Registro" e "Inscrições Tributárias"?
        </ButtonAction>

      </DivInfo>

    </ContainerCenter>
  )
}

export default DataCollector