import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContabilistAddress, RepresentantContact } from '../../../../../../../../../../../../../types';
import { useFormDbe } from '../../../../../../../../../../../../hooks/formDbe';
import { usePendencies } from '../../../../../../../../../../../../hooks/pendencies';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`

const Pendencies: React.FC = () => {
  const { contabilistAddress, representantContact,  } = useFormDbe();
  const { 
    mandatoryPendencies,
    optionalPendencies,  
  } = usePendencies()

  const { protocol } = useProtocol();
  const [address, setAddress] = useState({} as ContabilistAddress);
  const [comp, setComp] = useState('');
  const [ , setContact] = useState({} as RepresentantContact);
  const [ , setShowPendencies] = useState(false);
  
  function setStates(){
    setAddress({ ...contabilistAddress });
    setContact({ ...representantContact });
    handleAddress();
  }

  async function handleAddress(){
    const response = await api.get(`/aikjunta/viability/${protocol}`, config);
    let complement = response.data.viability_address.complement;

    setComp(complement);
  }
  function verifyPendencies(){
    if(!mandatoryPendencies.capitalValue){
      setShowPendencies(true);
    }
    if(!mandatoryPendencies){
      setShowPendencies(true);
    }   
    
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    setStates();  
    verifyPendencies();
  },[contabilistAddress]); 

  /* eslint-enable react-hooks/exhaustive-deps */
  return(
    <ContainerCenter title={"Visualizar Pendências"} icon={FaCheckCircle}>
      <Header />
      <DivLeft>
        <FCPJ />
      </DivLeft>

      <DivRight>
        <div style={{
          width: 700,
          height: 300,
          border: '1px solid black',
          marginBottom: 10,
          overflowY: 'scroll',
          scrollBehavior: 'auto'
        }}>
          <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Finalizar Preenchimento</p>
          <div style={{marginLeft: 10}}>
            <p>Ocorreram as seguintes pendências no preecnhimento do seu cadastro: </p>
            <p>No menu <strong>FCPJ:</strong></p>

            <p>Na opção <Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-pj'>Endereço da Pessoa Jurídica</Link></p>
            {
              !comp ? 
              <p style={{backgroundColor: '#f0e229'}}>&emsp; O campo 'Complemento' (opcional) não está preenchido</p>
              : <p>&emsp;&emsp; Não há pendências</p>
            }
            <p>Na opção <Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/identificacao'>Identificação</Link></p>
            {
              !mandatoryPendencies.capitalValue ? 
              <p style={{backgroundColor: '#d30c16', color: '#fdfdfd'}}>&emsp; O campo 'Capital Social' (obrigatório) não está preenchido</p>
              : ''
            }
            {
              !mandatoryPendencies.fantasyName ? 
              <p style={{backgroundColor: '#d30c16', color: '#fdfdfd'}}>&emsp; O campo 'Titulo do estabelecimento (nome fantasia)' (obrigatório) não está preenchido</p>
              : ''
            }
            {
              !!mandatoryPendencies.capitalValue && !!mandatoryPendencies.fantasyName ?
              <p>&emsp;&emsp; Não há pendências</p>
              : ''
            }

            <p>Na opção <Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/contact'>Dados para Contato</Link></p>
            {
              !mandatoryPendencies.phone ? 
              <p style={{backgroundColor: '#d30c16', color: '#fdfdfd'}}>&emsp; O campo 'Telefone 1' (obrigatório) não está preenchido</p>
              : ''
            }
            {
              !optionalPendencies.phone2 ? 
              <p style={{backgroundColor: '#f0e229'}}>&emsp; O campo 'Telefone 2' (opcional) não está preenchido</p>
              : ''
            }  
            {
              !!mandatoryPendencies.phone && !!optionalPendencies.phone2 ?
              <p>&emsp;&emsp; Não há pendências</p>
              : ''
            }

            <p>Na opção <Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-pj'>Endereço do Representante da Pessoa Jurídica</Link></p>
            {
              !comp ? 
              <p style={{backgroundColor: '#f0e229'}}>&emsp; O campo 'Complemento' (opcional) não está preenchido</p>
              : <p>&emsp;&emsp; Não há pendências</p>
            }
        
            <p>Na opção <Link to='/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/endereco-contabilista'>Endereço do Contabilista - Pessoa Física</Link></p>
            {
              !address.complement ? 
              <p style={{backgroundColor: '#f0e229'}}>&emsp; O campo 'Complemento do Logradouro' (opcional) não está preenchido</p>
              : <p>&emsp;&emsp; Não há pendências</p>
            }
            
            
          </div>
       
        </div>
      </DivRight>
    </ContainerCenter>
  );
}

export default Pendencies;