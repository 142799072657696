import { FaBookOpen, FaClipboard, FaCopy} from "react-icons/fa";

interface Data{
  title: string;
  icon: React.FunctionComponent<any>;
  link: string;
}

export const CardData:Data[] = [
  { 
    title: 'Consulta Prévia',
    icon: FaBookOpen,
    link: '/redesim/abrir-pessoa-juridica/consulta-previa',
  },
  {
    title: 'Coleta de Dados',
    icon: FaCopy,
    link: '/redesim/abrir-pessoa-juridica/coletar-dados'
  },
  {
    title: 'Licenças',
    icon: FaClipboard,
    link: 'https://www.gov.br/empresas-e-negocios/pt-br/redesim/abra-sua-pessoa-juridica/passo-3-licencas'
  }
]