import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaBook, FaCheckCircle, FaCloudDownloadAlt, FaFile, FaSignOutAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useProtocol } from '../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../services/auth';
import { usePendencies } from '../../../../../../../../../../../hooks/pendencies';


const HeaderBox = styled.div`
  width: auto;
  height: 200px;
  background-color: #e6e6e6;
  position: relative;
  display: inline-grid;
  margin-bottom: 50px;
  border: 1px solid #000;
  padding: 5px; 
  margin-left: 80px;

`
const DataBox = styled.div`
  width: 1050px;
  height: 200px;
  display: grid;
  flex-direction: row;
  justify-content: space-between;
`


const Header = () => {
  const history = useHistory();
  const { protocol } = useProtocol();
  const { mandatoryPendencies } = usePendencies()
  const [ uf, ] = useState<string>('');
  const [ city, setCity ] = useState<string>('');
  const [ cpf, setCpf ] = useState<string>('');
  const [ companyName, setCompanyName ] = useState<string>('');
  const [ disableBtn, setDisableBtn] = useState(true);

  

  async function handleDataCompany(){
    const res = await api.get(`/aikjunta/viability/${protocol}`, config);
    setCpf(res.data.fictional_person.cpf);
    setCompanyName(res.data.first_name_option);
    setCity(res.data.viability_address.city)

  }
  function verifyPendencies(){
    if(!!mandatoryPendencies.capitalValue && !!mandatoryPendencies.fantasyName && !!mandatoryPendencies.phone){
      setDisableBtn(false);
    }else{
      setDisableBtn(true);
    }
  }

  useEffect(()=> {
    handleDataCompany();
    verifyPendencies();
  })

  return(
    <HeaderBox>
      <DataBox>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 1000,
          height: 30,          
          marginLeft: 20
          }}>
        <p><strong>CPF</strong>: {cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</p> &emsp;
        <p><strong>Nome Empresarial</strong>: {companyName.toLocaleUpperCase()} </p>&emsp;
        <p><strong>Município</strong>: {city}</p>&emsp;
        <p><strong>UF</strong>: {uf}</p> <br/>&emsp;
        </div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-evenly',  
            height: 30,
            width: 1000,
            marginLeft: 20,
        }}>
        <p><strong>Protocolo REDESIM</strong>: {protocol}</p>
        <p><strong>Inscrição de Primeiro Estabelecimento</strong></p>
        </div> <br/>
        <section style={{
          // border: '1px solid #000', 
          width: 920, 
          height: 40, 
          marginLeft: 70,
          backgroundColor: '#dad6d6',
          justifyContent: 'space-around',
          padding: 5
          }}>
          <Button startIcon={<FaBook/>} color="default" variant="contained" size="small" onClick={()=>history.push('/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/')}>Instruções</Button> &emsp;
          <Button startIcon={<FaFile/>} color="default" variant="contained" size="small" onClick={()=>history.push('/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/brief')} >Resumo do Documento</Button> &emsp;
          <Button startIcon={<FaCheckCircle/>} color="default" variant="contained" size="small" onClick={()=>history.push('/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/pendencies')} >Verificar Pendências</Button> &emsp;
          <Button startIcon={<FaCloudDownloadAlt/>} disabled={disableBtn} color="default" variant="contained" size="small" onClick={()=>history.push('/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal/finalizar')} >Finalizar Preenchimento</Button>&emsp;
          <Button startIcon={<FaSignOutAlt/>} color="default" variant="contained" size="small" onClick={()=>history.push('')}>Sair</Button>&emsp;


        </section>
        
      </DataBox>
      
    </HeaderBox>
  )
}

export default Header;