import {ClockCircleOutlined, CloseOutlined, Loading3QuartersOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons"


interface Data { 
  title:string;
  link:string;
  component:React.FunctionComponent<any>;
}

export const cardsData:Data[]= [
  { 
    title:'Nova viabilidade',
    link:'/viabilidade/eventos',
    component: PlusOutlined
   
  }, 
  { 
    title:'Viabilidade para legado e MEI',
    link:'#',
    component:ClockCircleOutlined
   
  }, 
  { 
    title:'Reaproveitar viabilidade ',
    link:'#',
    component:ReloadOutlined
    
  },
  { 
    title:'Consultar viabilidade',
    link:'/viabilidade/consulta',
    component:SearchOutlined
    
  },
  { 
    title:'Cancelar Viabilidade ',
    link:'#',
    component:CloseOutlined
  },
   { 
    title:'Simular Grau de Risco',
    link:'#',
    component:Loading3QuartersOutlined,
  }
]