import React, { CSSProperties } from 'react';

interface LabelInputProps {
  text: string;
  style?: CSSProperties;
}

const LabelInput = ({ text, ...rest }: LabelInputProps) => {
  return (
    <label
      {...rest}>
        
      {text}
    </label>
  );
};
export default LabelInput;
