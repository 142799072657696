import React from 'react';
import './index.css';
import {
  CssBaseline,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { theme } from './themes/junta';
import { Header } from './components';
import Footer from './components/Footer';
import { Route, Router } from 'react-router-dom';
import Pages from './routes/Pages';
import history from './history';
import { FlowProvider } from './hooks/flow';
import { CepProvider } from './hooks/cep';
import { FormProviderViability } from './hooks/formViability';
import { ProtocolProvider } from './hooks/protocol';
import { StateProvider } from './pages/digital-registry/pages/New/hooks/states';
import { ShowProvider } from './pages/digital-registry/pages/New/hooks/showData';
import { FormIntegratorProvider } from './hooks/formIntegrator';
import { FormProviderDbe } from './hooks/formDbe';
import { PendenciesProvider } from './hooks/pendencies';
import { UserProvider } from './hooks/user';

const useStyles = makeStyles((theme) => ({
  app: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '80px',
    // minHeight: '0vh',
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
}));
function App() {
  const classes = useStyles();
  const routes = (
    <Router history={history}>
      <Route component={Pages} />
    </Router>
  );
  return (
    <ThemeProvider theme={theme}>
      <FlowProvider>
        <FormProviderViability>
          <CepProvider>
            <UserProvider>
              <ProtocolProvider>
                <StateProvider>
                  <FormProviderDbe>
                    <PendenciesProvider>
                      <ShowProvider>
                        <FormIntegratorProvider>
                          <StylesProvider>
                            <CssBaseline />
                            <div className={classes.container}>
                              <div className={classes.header}>
                                <Header />
                              </div>
                              <div className={classes.app}>{routes}</div>
                            </div>
                            <Footer />
                          </StylesProvider>
                        </FormIntegratorProvider>
                      </ShowProvider>
                    </PendenciesProvider>
                  </FormProviderDbe>
                </StateProvider>
              </ProtocolProvider>
            </UserProvider>
          </CepProvider>
        </FormProviderViability>
      </FlowProvider>
    </ThemeProvider>
  );
}

export default App;
