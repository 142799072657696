import React, { useState } from 'react';
import styled from 'styled-components'
import jsPDF from 'jspdf'
import * as htmlToImage from 'html-to-image'
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useProtocol } from '../../../../../../../hooks/protocol';
import { useEffect } from 'react';
import api from '../../../../../../../services/api';
import config from '../../../../../../../services/auth';
import { useFormIntegrator } from '../../../../../../../hooks/formIntegrator';


const BodyPdf = styled.div`
  width: 1155px;
  height: auto;
  border: 1px solid #000;
`

const Header = styled.div`
  width: 1150px;
  height: 300px;
  background-color: #fff;
  display: flex;
`

const TextField = styled.div`
  width: 400px;
  height: 100px;
  text-align: justify;
  margin-left: 50px;
`
const ProtocolDiv = styled.div`
  width: 400px;
  height: 192px;
  text-align: center;
  border: 1px solid #000;
  margin: 10px 10px 500px 350px;
`
const DivBox = styled.div`
  border: 1px solid #000;
  width: 1100px;
  height: auto;
  margin-left: 20px;
`

const LocalData = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  flex-direction: column;
  margin-left: 20px;
  align-items: center;
  border: 1px solid #000;
`
const DataBox = styled.div`
  display: flex;
  width: 600px;
  height: auto;
  float: right;
  margin-left: 300px;
  flex-direction: column;
`

const DivInside = styled.div`
  width: 1050px;
  height: auto;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
`

const Process: React.FC = () => {
  const history = useHistory();
  const { protocol } = useProtocol();
  const [companyName, setCompanyName] = useState('');
  const [legalNature, setLegalNature] = useState('');
  const [city, setCity] = useState('');
  const [date] = useState(new Date().toISOString().split('T')[0].split('-').reverse().join('/'))
  const [personName, setPersonName] = useState('');
  const { events } = useFormIntegrator();

  function handleBack(){
    history.push('/integrador/novocfn/docs');
  }

  async function handleData(){ //buscar os dados que necessitam ser usados nos inputs
    const res = await api.get(`/aikjunta/integrator/${protocol}`, config);
    setCompanyName(res.data.dbe.viability.fictional_person.company_name);
    setLegalNature(res.data.legal_nature.split(' ')[0]);
    setCity(res.data.dbe.viability.viability_address.city.toUpperCase());
    setPersonName(res.data.dbe.viability.fictional_person.full_name);
  }

  const onButtonClick = () => {
    const elem = document.getElementById("proccess")!;
    htmlToImage.toPng(elem)
    .then(function(dataUrl){
      const pdf = new jsPDF()
      pdf.addImage(dataUrl, 'JPEG', 15, 40, 180, 200)
      pdf.save('Capa-Processo.pdf')
    })
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(()  =>{
    handleData()
  },[])

  /* eslint-enable react-hooks/exhaustive-deps */
  
  return (
    <div>
    <BodyPdf id="proccess">
      <Header>
        <TextField>
          <div style={{ marginLeft: 60 }}>
            <p>Ministério da Indústria, do Comércio e do Turismo.</p>
            <p>Departamento Nacional do Registro do Comércio.</p>
            <p>Secretaria de Estado da Fazenda.</p>
          </div>
          <div style={{ display: 'flex', width: 700 }}>
            <div style={{ height: 100, width: 600, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{ marginLeft: 20 }}><strong>NIRE: </strong>(da sede ou da filial quando for em outra UF): </p>
            </div>
            <div style={{ height: 100, width: 300, border: '1px solid #000', textAlign: 'center' }}>
              <p><strong>Cód.Natureza Jurídica: </strong></p>
              <p>{legalNature}</p>
            </div>
            <div style={{ height: 100, width: 600, border: '1px solid #000', textAlign: 'center' }}>
              <p><strong>Nº de matrícula do agente auxiliar do comércio: </strong></p>
            </div>
          </div>
        </TextField>
        <ProtocolDiv>
          <p><strong>Nº do Protocolo </strong>(uso da Junta Comercial): </p>
          <p>{protocol}</p>

        </ProtocolDiv>
      </Header>
      <DivBox>
        <p style={{ textAlign: 'center', fontSize: 20 }}><strong>Requerimento</strong></p>
        <hr />
        <br />
        <p style={{ fontSize: 13, marginLeft: 20 }}>ILMº SR. PRESIDENTE DA JUNTA COMERCIAL DO ESTADO DO CEARÁ</p>
        <p style={{ marginLeft: 20 }}><strong>NOME: </strong> <span style={{marginLeft:55}}><u>{companyName}</u></span> </p>
        <p style={{marginLeft: 120}}>(da empresa ou do Agente Auxiliar de comércio)</p>
        <p style={{ marginLeft: 20 }}>REQUERER A V.Sª O DEFERIMENTO DO SEGUINTE ATO: </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: 100, height: 'auto', marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
            <p><strong>Nº de Vias</strong></p>
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}><p style={{marginTop: 5}}>1</p></div>
          </div>
          <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
            <p><strong>Código do Ato</strong></p>
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}><p style={{marginTop: 5}}>{events.act.split(' ')[0]}</p></div>
          </div>
          <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
            <p><strong>Código do Evento</strong></p>
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}></p>              
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}>
                {events.objEvent.length > 0 && (
                  events.objEvent[0].id
                )}
              </p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}>
                {events.objEvent.length > 1 && (
                  events.objEvent[1].id
                )}
              </p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}>
                {events.objEvent.length > 2 && (
                  events.objEvent[2].id
                )}
              </p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}>
                {events.objEvent.length > 3 && (
                  events.objEvent[3].id
                )}
              </p>
            </div> <br />
          </div>
          <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
            <p><strong>Quantidade do Evento</strong></p>
            <div style={{ width: 90, height: 30, border: '1px solid #000',textAlign: 'center'  }}>
              <p style={{marginTop: 5}}></p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
            <p style={{marginTop: 5}}>
                {events.objEvent.length > 0 && (
                  "01"
                )}
              </p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
                <p style={{marginTop: 5}}>
                  {events.objEvent.length > 1 && (
                    "01"
                  )}
                </p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}>
                {events.objEvent.length > 2 && (
                  "01"
                )}
              </p>
            </div> <br />
            <div style={{ width: 90, height: 30, border: '1px solid #000', textAlign: 'center' }}>
              <p style={{marginTop: 5}}>
                {events.objEvent.length > 3 && (
                  "01"
                )}
              </p>
            </div> <br />
          </div>
          <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
            <p><strong>Descrição do Ato / Evento</strong></p>
            <div style={{ width: 500, height: 30, border: '1px solid #000' }}>
              <p style={{marginTop: 5, marginLeft: 10}}>{events.act.split(' ')[2]}</p>
            </div> <br />
            <div style={{ width: 500, height: 30, border: '1px solid #000'}}>
              <p style={{marginTop: 5, marginLeft: 10}}>
                {events.objEvent.length > 0 && ( 
                  events.objEvent[0].name.split('-')[1]
                )}</p>
            </div> <br />
            <div style={{ width: 500, height: 30, border: '1px solid #000' }}>
              <p style={{marginTop: 5, marginLeft: 10}}>
                {events.objEvent.length > 1 && (
                  events.objEvent[1].name.split('-')[1]
                )}
              </p>
            </div> <br />
            <div style={{ width: 500, height: 30, border: '1px solid #000' }}>
            <p style={{marginTop: 5, marginLeft: 10}}>
                {events.objEvent.length > 2 && (
                  events.objEvent[2].name.split('-')[1]
                )}
              </p>
            </div> <br />
            <div style={{ width: 500, height: 30, border: '1px solid #000' }}>
            <p style={{marginTop: 5, marginLeft: 10}}>
                {events.objEvent.length > 3 && (
                  events.objEvent[3].name.split('-')[1]
                )}
              </p>
            </div> <br />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <LocalData>
            <p style={{ marginTop: 30 }}><u>{city}</u></p>
            <p>Local</p>
            <p>Data</p>
            <p><u>{date}</u></p>
          </LocalData>
          <DataBox>
            <p><strong>Representante Legal da Empresa / Agente Auxiliar do Comércio: </strong></p>
            <p>Nome: <u>{personName}</u></p>
            <p>Assinatura: _______________________________________</p>
            <p>Telefone de Contato: ______________________________</p>
            <p>E-mail: ___________________________________________</p>
            <p>Login do Certidão Web (CPF): ______________________</p>
          </DataBox>
        </div>
        <br />
      </DivBox>
      <br />
      <DivBox>
        <p style={{ textAlign: 'center', fontSize: 20 }}><strong>Uso Exclusivo da Junta Comercial</strong></p>
        <hr />
        <div style={{ width: 'auto', height: 50, display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 400 }}></div>
          <p>Decisão Singular</p>

          <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
          <p>Decisão Colegiada</p>
        </div>
        <hr />
        <p style={{ marginLeft: 40 }}><strong>Decisão Colegiada: </strong></p>
        <DivInside>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>Processo em exigência (vide despacho em folha)</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>Processo deferido. Publique-se e arquive-se.</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>Processo indeferido. Publique-se</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>2ª Exigência</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>3ª Exigência</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>4ª Exigência</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>5ª Exigência</p>
            </div>
          </div>
        </DivInside>
        <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>___/___/______</p>
            <p>Data</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
            <p>___________________</p>
            <p>Vogal</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
            <p>___________________</p>
            <p>Vogal</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
            <p>___________________</p>
            <p>Vogal</p>
          </div>
        </div>
        <p style={{ marginLeft: 50 }}>Presidente da ___ turma</p>
        <br />
        <hr />
        <p style={{ marginLeft: 40 }}><strong>Decisão Singular: </strong></p>
        <DivInside>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>Processo em exigência (vide despacho em folha)</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>Processo deferido. Publique-se e arquive-se.</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>Processo indeferido. Publique-se</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>2ª Exigência</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>3ª Exigência</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>4ª Exigência</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ width: 20, height: 20, border: '1px solid #000', marginLeft: 20 }}></div>
              <p>5ª Exigência</p>
            </div>
          </div>
        </DivInside>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 350 }}>
            <p>___/___/______</p>
            <p>Data</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 200 }}>
            <p>___________________</p>
            <p>Responsável</p>
          </div>
        </div>
        <hr />
        <br />
        <p style={{ marginLeft: 40 }}><strong>Orientações para obter Via Única: </strong></p>
        <DivInside style={{ display: 'block' }}>
          <p style={{ marginLeft: 20 }}>A Via única de atos que forem protocolados fora do Simplifica/CE serão disponibilizados no sistema de Certidão Web.Portanto, deve ser <br />
            informado no campo apropriado da Capa de Requerimento o Login no referido sistema.<br />
            Caso não possua Login de acesso, observe as orientações abaixo: <br />
          </p>
          <ul style={{ marginLeft: 50 }}>
            <li>Acesse o site da Jucec, www.jucec.com.gov.br;</li>
            <li>Acesse a opção Certidão Web;</li>
            <li>Clique no opção "Cadastre-se" e preencha o formulário;</li>
          </ul>
          <p style={{ marginLeft: 20 }}>Informe na Capa Requerimento o Login (CPF ou CNPJ) para emissão de Via Única em seu cadastro.</p>
        </DivInside>
      </DivBox>
      <br />

    </BodyPdf>
      
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
      <Button onClick={handleBack} type='primary' icon={<ArrowLeftOutlined/>} size='large'>Voltar</Button>
      <Button onClick={onButtonClick} type='primary' icon={<DownloadOutlined/>} size='large'>Imprimir</Button>
    </div>
   </div>
    
      
  );
}

export default Process;