import React, { useState, useEffect } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { Collapse, Form, Radio } from 'antd';

import { useFlow } from '../../../../../hooks/flow';
import { useFormViability } from '../../../../../hooks/formViability';

import { FaCrosshairs } from 'react-icons/fa';
import { FormEvents } from './components/components.styled';

import { subscribeEvents, AlterEvents } from './events.model';
import { LabelInput } from '../../../../../components';
import { ContainerCenter } from '../../../../components/ContainerCenter';
import { SubEvent } from '../../../../../../types';

const { Panel } = Collapse;

const useStyles = makeStyles((theme) => ({
  collapseContainer: {
    display: 'flex',
  },
  panel: {
    borderRadius: '2px',
    marginBottom: 3,
    background: ' #f7f7f7',
    // border: 0,
    overflow: 'hidden',
  },
  radio: {
    display: 'block',
    // height: '300px',
    lineHeight: '30px',
  },
  container: {
    padding: theme.spacing(3),
  },
}));

const SelectEvent = () => {
  const classes = useStyles();
  const { setNextLink, setCode, setEventType, routes } = useFlow();

  const [disable, setDisable] = useState<boolean>(false);
  const [disableFirstPanel, setDisableFirstPanel] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(false);
  const { setSubEvent } = useFormViability()

  function handleEvents(e:string){
    let event : SubEvent = { code: e }
    setSubEvent(event);
    setEnableButton(true);

  }
  useEffect(() => {
    const routeNext = routes.length - (routes.length - 0); // flow 101
    if (routeNext > routes.length) {
      throw new Error('route is not in range');
    }
    setNextLink(routes[routeNext]?.route); // position 0
  }, [routes, setNextLink]);

  useEffect(() => {
    return () => {
      setCode(0);
    };
  }, [setCode]);
  function callback(key: any) {
    filterPanel(key);
  }
  function filterPanel(key: string[]) {
    const firstPanel = '1';
    const secondPanel = '2';
    if (key.length >= 0) {
      if (key.includes(firstPanel)) {
        firstPanelChange();
      } else if (key.includes(secondPanel)) {
        secondPanelChange();
      } else {
        setDisable(false);
        setDisableFirstPanel(false);
      }
    }
  }
  function firstPanelChange() {
    setDisable(true);
    setDisableFirstPanel(false);
  }

  function secondPanelChange() {
    setDisable(false);
    setDisableFirstPanel(true);
  }

  function changeRadioSubscribe(e: any) {

    const id = e.target.value;
    setCode(Number(id));
    setEventType('subscribe');
  }
  function changeRadioAlter(e: any) {
    console.log({ radio: e.target.value });
  }
  return (
    <ContainerCenter
      title={'Selecione o(s) evento(s) da Viabilidade'}
      icon={FaCrosshairs}
      message={
        'Alguns eventos de alteração podem ser marcados simultaneamente. O nome reservado está sujeito a análise técnica durante a análise do processo pela Junta Comercial.'
      }
      buttonNext={enableButton}
      buttonBack={true}
    >
      <Grid
        container
        className={classes.container}
        alignItems={'center'}
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Collapse onChange={callback} className={classes.panel}>
            <Panel
              className={classes.panel}
              header="Eventos de inscrição"
              key="1"
              style={{ borderLeft: '4px solid #666' }}
              disabled={disableFirstPanel}
            >
              <FormEvents layout="vertical" onChange={changeRadioSubscribe}>
                <Form.Item
                  label={
                    <LabelInput
                      style={{
                        color: '#333333',
                        marginBottom: '5px',
                        maxWidth: '100%',
                        fontWeight: 700,
                        display: 'inline-block',
                      }}
                      text={'Selecione o evento de inscrição:'}
                    />
                  }
                >
                  <Radio.Group>
                    {subscribeEvents.map((el, index) => (
                      <Radio
                        key={index}
                        className={classes.radio}
                        value={el.code}
                        onChange={e=>handleEvents(el.name)}
                      >
                        {el.code} - {el.name}
                      </Radio>
                      
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
            
            <Panel
              className={classes.panel}
              header="Eventos de Alteração"
              key="2"
              style={{ borderLeft: '4px solid #666' }}
              disabled={disable}
            >
              <FormEvents layout="vertical" onChange={changeRadioAlter}>
                <Form.Item
                  label={
                    <LabelInput
                      style={{
                        color: '#333333',
                        marginBottom: '5px',
                        maxWidth: '100%',
                        fontWeight: 700,
                        display: 'inline-block',
                      }}
                      text={'Selecione o evento de alteração:'}
                    />
                  }
                >
                  <Radio.Group>
                    {AlterEvents.map((el, index) => (
                      <Radio
                        key={index}
                        className={classes.radio}
                        value={el.code}
                      >
                        {el.code} - {el.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </FormEvents>
            </Panel>
          </Collapse>
        </Grid>
      </Grid>
    </ContainerCenter>
  );
};
export default SelectEvent;
