import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaBuilding } from 'react-icons/fa';
import styled from 'styled-components';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const CompanyPort = () => {

    const radioStyle = {
        display: 'block',
        height: '20px',
        lineHeight: '30px'
    }

    const { protocol } = useProtocol();
    const [ port, setPort ] = useState<any>();

    async function handlePort(){
        const response = await api.get(`/aikjunta/viability/${protocol}`, config);
        setPort(response.data.port.id)
    }
    useEffect(()=> {
        handlePort();
    })
    return (
        <ContainerCenter title={"Porte da Empresa"} icon={FaBuilding}>
            <Header />
            <DivLeft>
                <FCPJ />
            </DivLeft>
            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Porte da Empresa</p>
                
                <div style={{marginLeft: 10}}>
                    <p>Selecione o enquadramento de Porte da Empresa desejado.</p> <br/>
                    <p>Para solicitar o enquadramento como Microempresa ou Empresa de Pequeno Porte o contribuinte deve verificar se atende as condições previstas na Lei Complementar 123 de 14/12/2006.</p><br/>

                    <Radio.Group value={port} disabled>
                        <Radio value={1} style={radioStyle}>
                            Microempresa
                        </Radio>
                        <Radio value={2} style={radioStyle}>
                            Empresa de Pequeno Porte
                        </Radio>
                        <Radio value={3} style={radioStyle}>
                            Demais
                        </Radio>
                    </Radio.Group>
                </div>
                
                
                </div>
            </DivRight>
        </ContainerCenter>
    );
}

export default CompanyPort;