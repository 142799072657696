import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  height: auto;
  padding: 0.3em 0;
  @media (max-width: 800px) {
    flex-direction: column;
    position: relative;
  }
`;
const Footer = () => {
  return (
    <Container>
      Desenvolvido e mantido pelo projeto
      <strong style={{ margin: '0 0.2%' }}> EMPREENDEDOR DIGITAL</strong> -
      Todos os direitos reservados. Aspectos legais e responsabilidades.
    </Container>
  );
};
export default React.memo(Footer);
