import React, { useEffect } from 'react';

import { FaExchangeAlt } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import styled from 'styled-components';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import { Modal } from 'antd';



const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
  margin-bottom: 30;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;

`
const DivInfo = styled.div`
  width: 600px;
  height: auto;
  margin-bottom: 30px;
  background-color: #929090;
`

const Principal = () => {
  const { protocol } = useProtocol();


  useEffect(() => {
    Modal.info({
      content: 'Preencha todos os campos disponíveis corretamente'
    }) 
  })

  return(
    <ContainerCenter title='Criar sua Pessoa Jurídica' icon={FaExchangeAlt}>
      <Header />
      <DivLeft>
        <FCPJ />
      </DivLeft>

      <DivRight>
          <DivInfo>
            <div style={{backgroundColor:'#e9e3e3', border: '1px solid #000'}}>
              <p style={{backgroundColor: '#ccc', fontWeight: 'bold', textAlign: 'center'}}>Instruções de Navegação</p>
              <p style={{marginLeft: 10}}>O número do Protocolo REDESIM é <strong>{protocol}</strong></p>
              <br/>
              <p style={{marginLeft: 10}}><strong>Guarde</strong> este número, pois será necessário <strong>recuperar</strong> uma solicitação.</p>
            </div>
          </DivInfo>
      </DivRight>

    </ContainerCenter>
      
  )
}

export default Principal