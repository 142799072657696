
interface OptionI {
  name: string;
}
export interface ActionsI {
  name: string;
  value?: string | number;
}
const DevelopmentOptions: OptionI[] = [
  {
    name: 'Produtiva',
  },
  {
    name: 'Auxiliar',
  },
];

const AddressOptions: OptionI[] = [
  {
    name: 'Rural',
  },

  {
    name: 'Urbana',
  },
  {
    name: 'Sem Regularização',
  },
];


const actionsProd: ActionsI[] = [
  {
    name: '01 - Estabelecimento fixo',
  },
  {
    name: '02 - Internet',
  },
  {
    name: '03 - Em local fixo fora de loja',
  },
  {
    name: '04 - Correio',
  },
  {
    name: '05 - Porta a Porta, Postos Móveis ou por Ambulantes',
  },
  {
    name: '06 - Televendas',
  },
  {
    name: '07 - Máquinas Automáticas',
  },
  {
    name: '08 - Atividade Desenvolvida Fora do Estabelecimento',
  },
]; 

const actionsAux: ActionsI[] = [
  { 
     name:'01 - Sede'
  }, 
  { 
    name:'02 - Escritório Administrativo'
  },
  { 
    name:'03 - Depósito Fechado'
  },
   { 
     name:'04 - Almoxarifado'
   }, 
   { 
     name:'05 - Oficina de Reparação'
   }, 
   { 
     name:'06 - Garagem'
   },
   { 
     name:'07 - Unidade de Abastecimento de Combustíveis'
   }, 
   { 
     name:'08 - Ponto de Exposição'
   }, 
   { 
     name:'09 - Centro de Treinamento'
   }, 
   { 
     name:'10 - Centro de Processamento de Dados'
   }, 
   {
      name:'11 - Posto de Coleta'
   }

] 
export {DevelopmentOptions,AddressOptions,actionsAux,actionsProd}