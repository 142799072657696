import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { FaBullseye, FaSearch } from 'react-icons/fa';
import { SearchOutlined } from '@ant-design/icons';
import { format, parseISO } from 'date-fns';
import { Button, Input, Modal, Table } from 'antd';
import { ViabilityStatus } from './components/ViabilityStatus';
import { ContainerCenter } from '../../../../components/ContainerCenter';
import { ColumnType } from 'antd/lib/table';
import api from '../../../../../services/api';
import config from '../../../../../services/auth';
import styled from 'styled-components';
import { useUser } from '../../../../../hooks/user';

const useStyles = makeStyles((theme) => ({
  panel: {
    borderRadius: '2px',
    marginBottom: 3,
    background: ' #f7f7f7',
    overflow: 'hidden',
  },
  container: {
    padding: theme.spacing(3),
  },
  table: {
    marginTop: 10
  },
  textButton: {
    paddingLeft: '0.5em'
  }
}));

const ButtonAction = styled(Button)`
  width: auto;
  height: 40px;
  font-size: 15px;
  background-color: #1890ff;
  border: 1px solid #1890ff;
  color: #fff;
  :hover{
    background-color: #096cc9;
    color: #fff;
  }
`
interface ViabilityProps {
  viability_protocol: string
  first_name_option: string
  rf_regularization: boolean
  created_at: Date
}

interface ViabilityTableProps {
  protocol: string,
  name_option: string,
  status: string,
  date: string,
  action: any,
}

const SearchViability = () => {
  const styles = useStyles();
  const status = useRef(null);
  const [protocol, setProtocol] = useState('');
  const [viability, setViability] = useState<any[]>([]);
  const [viabilityTable, setViabilityTable] = useState<ViabilityTableProps[]>([]);
  const [viabilityStatus, setViabilityStatus] = useState<any>();
  const { user } = useUser();

  const viabilityColumn: ColumnType<any>[] = [
    {
      title: <strong>Protocolo</strong>,
      dataIndex: 'protocol'
    },
    {
      title: <strong>Primeira opção de nome</strong>,
      dataIndex: 'name_option'
    },
    {
      title: <strong>Situação de Análise</strong>,
      dataIndex: 'status'
    },
    {
      title: <strong>Data de Cadastro</strong>,
      dataIndex: 'date'
    },
    {
      title: <strong>Ação</strong>,
      render: (record) => (
        <ButtonAction
          type="primary"
          icon={<FaBullseye />}
          onClick={() => {
            selectRow(record)
            scrollToSection(status)
          }
          }
        >
          <span className={styles.textButton}>Selecionar</span>
        </ButtonAction>
      ),
    }
  ]

  async function handleData(protocol: string) {
    try {
      if (protocol) {
        const res = await api.get(`/aikjunta/viability/${protocol}`, config);
        setViability([res.data])
      }
    } catch (err: any) {
      console.log(err)

      Modal.error({
        content: err.response.data.message
      })
    }
  }

  async function handleViability() {
    const res = await api.get(`/aikjunta/viability/all/${user}`, config);
    setViability(res.data);
  }

  function selectRow(record: any) {
    if (viabilityStatus?.protocol === record.protocol)
      setViabilityStatus(null)
    else
      setViabilityStatus(record);
  }

  function scrollToSection(ref: any) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!protocol) {
      handleViability();
    }
  }, [protocol]);

  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (viability.length > 0) {
      const value: ViabilityTableProps[] = viability.map((viab: ViabilityProps) => ({
        protocol: viab.viability_protocol,
        name_option: viab.first_name_option,
        status: 'Deferida',
        date: format(parseISO(viab.created_at.toString()), 'dd/MM/yyyy HH:mm:ss'),
        action: '',
      }));

      setViabilityTable(value)
    }
  }, [viability]);

  return (
    <ContainerCenter
      title={'Consultar Viabilidade'}
      icon={FaSearch}
      message={
        'O nome reservado está sujeito a análise técnica pela Junta Comercial.'
      }
      buttonBack={true}
    >
      <Grid
        container
        className={styles.container}
        alignItems='flex-start'
        justify='space-between'
      >
        <Box>
          <h5
            style={{
              fontSize: '1.1em',
              fontWeight: 500,
              maxWidth: '100%',
              paddingTop: '0.7em',
              marginBottom: '1em',
            }}
          >
            Possui um número de protocolo?
          </h5>
        </Box>
        <Box>
          <Input
            size={'large'}
            placeholder={'Digite seu protocolo de Viabilidade'}
            style={{
              marginRight: '1em',
              width: '30em',
              maxWidth: '100%',
            }}
            onChange={e => setProtocol(e.target.value)}
          />
          <Button
            htmlType={'submit'}
            size={'large'}
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => handleData(protocol)}
          >
            Pesquisar
          </Button>
        </Box>
        <Grid className={styles.table} item xs={12}>
          <Table
            bordered
            style={{ minHeight: '30vh', width: '100%', backgroundColor: 'white' }}
            columns={viabilityColumn}
            dataSource={viabilityTable}
            pagination={{ position: ['bottomCenter'] }}
          />
        </Grid>
        <Grid ref={status}>
          {viabilityStatus ? <ViabilityStatus viability={viabilityStatus} /> : null}
        </Grid>
      </Grid>
    </ContainerCenter >
  );
};

export default SearchViability;
