import React from 'react'
import { useHistory } from 'react-router-dom';

import { useProtocol } from '../../../../../hooks/protocol';

import { Button } from 'antd';
import { FaCheck } from 'react-icons/fa';
import { ContainerCenter } from '../../../../components/ContainerCenter';

import './styles.css';

const SuccessViability = () =>{
    const history = useHistory();
    const { protocol } = useProtocol();
    
    function goHome(){
        history.push('/principal')
    }

    return(
        <ContainerCenter title="Solicitação realizada com sucesso" icon={FaCheck}>
            <div className="div-text">
                <h2>Utilize este número para acompanhar o andamento de sua solicitação</h2>
                <p>Quando a viabilidade for aprovada, este número deverá ser utilizado no Integrador.</p>
            </div>
            <div className="box-protocol">
                <p>Número da Consulta de Viabilidade: </p>
                <br/>
                <h1>{protocol}</h1>
            </div>
            <div className="btn-div">
                <Button type="primary" size="large" onClick={goHome}>Ir para Página Principal</Button>
            </div>
        </ContainerCenter>
    )
}

export default SuccessViability;