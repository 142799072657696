import React from 'react';
import { FaBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';

const LinkContainer = styled.span`
  margin-left: 27px;
  font-size: 16px;

`


const PreviousConsult = () => {

  return(
    <ContainerCenter title={'Consulta Prévia de Viabilidade'} icon={FaBook} buttonBack>
      <br/>
      <hr/>
      <br/>
      <p style={{textAlign: 'center', 
        color: '#3030d4', 
        fontWeight:700,
        fontSize: 20
      }}>SELECIONE SEU ESTADO</p>
      <hr/>
      <br/>
      <LinkContainer>
        <table style={{width: 900, marginLeft: 200}}>
          <tr>
            <td><Link to='/viabilidade'>Acre - AC</Link></td> &emsp;
            <td><Link to='/viabilidade'>Espírito Santo - ES</Link></td>&emsp;
            <td><Link to='/viabilidade'>Piauí - PI</Link></td>&emsp;
            <td><Link to='/viabilidade'>Rondônia - RO</Link></td>&emsp;
          </tr>
          <br/>
          <tr>
            <td><Link to='/viabilidade'>Alagoas - AL</Link></td>&emsp;
            <td><Link to='/viabilidade'>Goiás - GO</Link></td>&emsp;
            <td><Link to='/viabilidade'>Paraíba - PB</Link></td>&emsp;
            <td><Link to='/viabilidade'>Roraima - RR</Link></td>&emsp;
          </tr>
          <br/>
          <tr>
            <td><Link to='/viabilidade'>Amapá - AP</Link></td>&emsp;
            <td><Link to='/viabilidade'>Maranhão - MA</Link></td>&emsp;
            <td><Link to='/viabilidade'>Paraná - PR</Link></td>&emsp;
            <td><Link to='/viabilidade'>São Paulo - SP</Link></td>&emsp;
          </tr>
          <br/>
          <tr>
            <td><Link to='/viabilidade'>Amazonas - AM</Link></td>&emsp;
            <td><Link to='/viabilidade'>Mato Grosso - MT</Link></td>&emsp;
            <td><Link to='/viabilidade'>Pernambuco - PE</Link></td>&emsp;
            <td><Link to='/viabilidade'>Santa Catarina - SC</Link></td>&emsp;
          </tr>
          <br/>
          <tr>
            <td><Link to='/viabilidade'>Bahia - BA</Link></td>&emsp;
            <td><Link to='/viabilidade'>Mato Grosso Do Sul - MS</Link></td>&emsp;
            <td><Link to='/viabilidade'>Rio de Janeiro - RJ</Link></td>&emsp;
            <td><Link to='/viabilidade'>Sergipe - SE</Link></td>&emsp;
          </tr>
          <br/>
          <tr>
            <td><Link to='/viabilidade'>Ceará - CE</Link></td>&emsp;
            <td><Link to='/viabilidade'>Minas Gerais - MG</Link></td>&emsp;
            <td><Link to='/viabilidade'>Rio Grande Do Norte - RN</Link></td>&emsp;
            <td><Link to='/viabilidade'>Tocantins - TO</Link></td>&emsp;
          </tr>
          <br/>
          <tr>
            <td><Link to='/viabilidade'>Distrito Federal - DF</Link></td>&emsp;
            <td><Link to='/viabilidade'>Pará - PA</Link></td>&emsp;
            <td><Link to='/viabilidade'>Rio Grande Do Sul - RS</Link></td>&emsp;
          </tr>
        </table>
        <br/>
         
        
      </LinkContainer>
    </ContainerCenter>
    
  )
}

export default PreviousConsult