import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { FaExchangeAlt } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';
import { MenuData } from './models/MenuData';
import Menu from './components/Menu';
import { Link, Route, Router } from 'react-router-dom';
import Routes from './pages/routes/DbeRoutes';
import history from '../../../../../../../../history';

const CompanyData = styled.header`
  width: 100%;
  height: 300px;
  background-color: #cecece;
  display: flex;
  flex-direction: column;
`;

const DataContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ContainerMenu = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #eee;  
`

const Body = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  flex: 1;  
`


const FCPJ = styled.div`
  float: left;
`

const DinamicPages = styled.div`
  background-color: #cecece;
  margin-left: 200px;
  width: 100%;
  
`

const NationalCollector = () => {
  const route = (
  <Router history={history}>
    <Route component={Routes}/>
  </Router>
  );

  return(
    <ContainerCenter title="Coletor Nacional" icon={FaExchangeAlt}>
      <CompanyData>
        <DataContainer>
          <p>CNPJ: </p>  &emsp;  <p>Nome Empresarial: </p>  &emsp;&emsp; <p>UF: </p><br />
          <p>Protocolo REDESIM: </p>  &emsp; <p>Município: </p>
          <br />  <p>Alteração Cadastral</p>
        </DataContainer>
        <hr />
        <ContainerMenu>
          {MenuData.map((item, index) => {
            return (
              <Grid item key={index.toString()}>
                <Menu
                  title={item.title}
                  icon={item.icon}
                  link={item.link}
                />
              </Grid>
            )
          })}
        </ContainerMenu>
      </CompanyData>

      <Body>
        <FCPJ>
          <div style={{ width: '170px', height: '200px', marginLeft: '70px', border: '1px solid #000' }}>
            <p style={{ backgroundColor: '#cecece', textAlign: 'center' }}><strong>FCPJ</strong></p>
            <div style={{ textAlign: 'center' }}>
              <ul style={{listStyle: 'none'}}>
                <li><Link to='/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/tipo-doc/'>Eventos</Link></li>
                <li><Link to='/info'>Identificação</Link></li>
                <li><Link to=''>Representante/Preposto</Link></li>
              </ul>
            </div>
          </div>
        </FCPJ>

        <DinamicPages>
          <div>
            {route}
            
          </div>
        </DinamicPages>
      </Body>

    </ContainerCenter>

  )
}

export default NationalCollector