import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { cardsData } from './cardData';
import { Card } from '../../components';
import { FaAddressCard } from 'react-icons/fa'
import api from '../../services/api';
import config from '../../services/auth';
import { useUser } from '../../hooks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(0),
  },
  container: {
    marginTop: theme.spacing(3),
    // padding: theme.spacing(3),
  },
  userBox: {
    margin: theme.spacing(2),
    },
}));

const Home = () => {
  const classes = useStyles();
  const [ firstName, setFirstName ] = useState<string>('');
  const [ lastName, setLastName ] = useState<string>('');
  const [ cpf, setCpf ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const [ phone, setPhone ] = useState<string>('');
  const [ person, setPerson ] = useState<any>();
  const { setUser } = useUser();

  function getUsername(){
    let getPerson;

    getPerson = sessionStorage.getItem(('username'));
    if(getPerson ===  null){
      
      return null;
    } 

    let decryptPerson = atob(getPerson)

    
    
    setPerson(decryptPerson);

    if(person !== undefined){
      getData();
    }
  }

  async function getData(){
    
    const res: any = await api.get(`/user/username/${person}`, config);

    setUser(res.data.id);
    setFirstName(res.data.first_name);
    setLastName(res.data.last_name);
    setCpf(res.data.cpf);
    setEmail(res.data.email);
    setPhone(res.data.phone_number);
    
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    
    getUsername();
  }, person)

  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Fragment>
      <Grid container className={classes.root}>
        <Grid item lg={8}>
          <Paper className={classes.paper} variant={'elevation'} elevation={2}>
            <Container className={classes.container}>
              <Box display={'flex'}>
                <h1>Serviços Redesim</h1>
              </Box>
              <Divider style={{ marginBottom: '1.2em' }} />
              <Grid container spacing={3} className={classes.box}>
                {cardsData.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <Card
                        description={item.description}
                        title={item.title}
                        url={item.link}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item lg={2} className={classes.userBox}>
          <Paper>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 10}}>
              <FaAddressCard style={{width: 30, height: 30, marginLeft: 20}}/> &emsp;
              <h3> <strong>Meu Cadastro</strong> </h3>
            </div>
            <Divider style={{width: '90%', marginLeft: 10, marginTop: 10}} />
            <br/>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
              <h3><strong>Nome: </strong> </h3>
              <p> { firstName } { lastName }</p>
              <h3><strong>CPF: </strong></h3>
              <p>{cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</p>
              <h3><strong>E-mail: </strong></h3>
              <p>{email}</p>
              <h3><strong>Telefone: </strong></h3>
              <p>{phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3 - $4")}</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default Home;
