import { Button } from 'antd';
import React from 'react';
import { FaBook } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../../../components/ContainerCenter';

const ContainerText = styled.div`
  width: auto;
  height: auto;
  margin-left: 27px;
`

const InfoCollect = () => {
  const history = useHistory()
  return(
    <ContainerCenter title={"O que é 'Coleta de Dados'?"} icon={FaBook}>
     <ContainerText>
      <p>É a etapa na qual o cidadão deve fornecer todos os dados e informações de interesse dos órgãos envolvidos no processo de registro e legalização de 
      <br/> pessoas jurídicas, isto também permite que órgãos prestem as orientações a respeito dos procedimentos necessários para a obtenção do registro e das 
      <br/> respectivas inscrições tributárias.</p>
      <br/>
      <p>Com a Redesim, a coleta de informações é unificada, evitando que o solicitante informe repetidamente dados já prestados anteriormente. Toda a 
      <br/> informação é compartilhada entre os órgãos envolvidos, dando mais celeridade ao processo.</p>
      <br/>
      <p>Concluída esta etapa, o solicitante deverá entregar a documentação necessária no respectivo órgão de registro, que pode ser a Junta Comercial, o Cartório
      <br/> de Registro de Pessoa Jurídica ou a OAB. Após a análise da documentação, estando tudo correto, as inscrições da pessoa jurídica são geradas em conjunto: 
      <br/> CNPJ, inscrições tributárias estadual e municipal bem como as no respectivo órgão de registro.</p>
      </ContainerText>
      <Button onClick={()=> history.push('/redesim/abrir-pessoa-juridica/coletar-dados')} type="primary" style={{marginLeft: 25}}>
        Voltar
      </Button>
    </ContainerCenter>
  )
}

export default InfoCollect