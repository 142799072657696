import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FaBullseye } from 'react-icons/fa';
import { ContainerCenter } from '../../../../../components/ContainerCenter';

const useStyles = makeStyles(() => ({
  containerPrincipal: {
    padding: '20px 15px',
    margin: '1.5em 0',
    border: '3px solid #eee',
    borderRadius: '2px',
  },
  containerSecondary: {
    borderLeft: '3px solid #27ae60',
    padding: '16px',
    width: '80vw'
  },
  table: {
    border: 'none',
    marginBottom: '1em'
  }
}));

interface Viability {
  protocol: string;
  status: string;
  date: string;
}

interface ViabilityStatusProps {
  viability: Viability;
}

const ViabilityStatus = ({
  viability,
}: ViabilityStatusProps) => {
  const styles = useStyles()

  return (
    <ContainerCenter
      title={'Situação da Viabilidade'}
      icon={FaBullseye}
      buttonBack={false}
    >
      <Box className={styles.containerPrincipal}>
        <Box className={styles.containerSecondary}>
          <h3><strong>Protocolo:</strong> {viability.protocol}</h3>
          <h3><strong>Situação da viabilidade:</strong> {`Deferida em: ${viability.date}`}</h3>
        </Box>

      </Box>
      <Box className={styles.containerPrincipal}>
        <Box className={styles.containerSecondary}>
          <h3><strong>JUCEC (Consulta de Nome)</strong></h3>
          <h3><strong>Situação:</strong>{'Análise concluída (Reservada)'}</h3>
        </Box>
      </Box>
      <table className={styles.table}>
        <tr className={styles.table}>
          <th className={styles.table}>Pergunta</th>
          <th className={styles.table}>Resposta</th>
        </tr>
        <tr className={styles.table}>
          <td className={styles.table}>Esta consulta de viabilidade é SOMENTE para regularização
            de dados cadastrais perante a Receita Federal do Brasil?</td>
          <td className={styles.table}>Não</td>
        </tr>
      </table>
      <p><strong>Envio para a Receita Federal do Brasil:</strong> Enviada com sucesso </p>
    </ContainerCenter >
  )
};

export { ViabilityStatus };
