import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaPhone } from 'react-icons/fa';
import styled from 'styled-components';
import { useFormDbe } from '../../../../../../../../../../../../hooks/formDbe';
import { usePendencies } from '../../../../../../../../../../../../hooks/pendencies';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`

const LabelBox = styled.div`
    display: flex;
`


const NumberBox = styled.div`
    display: flex;
    flex-direction: row;
`


const Contact: React.FC = () => {    
    const { 
        mandatoryPendencies, 
        setMandatoryPendencies, 
        optionalPendencies, 
        setOptionalPendencies, 
    } = usePendencies()
    const [ddd1, setDdd1] = useState<string>('');
    const [ddd2, setDdd2] = useState<string>('');
    const [ddd3, setDdd3] = useState<string>('');
    const [phone1, setPhone1] = useState<string>('');
    const [phone2, setPhone2] = useState<string>('');
    const [fax, setFax] = useState<string>('');

    const { representantContact, setRepresentantContact } = useFormDbe();

    function handleContactMask(e: any, index: number){
        let value = e;
        let number = [];
        value = value.replace(/[^\d]/g,'');
        value = (value.length === 10) ? 
                    value.replace(/(\d{1})(\d{5})$/,"$1-$2")
                    : value.replace(/(\d{1})(\d{4})$/,"$1-$2");
        number.push(value);
        
        switch(index){
            case 1:
                setMandatoryPendencies({
                    ...mandatoryPendencies, 
                    phone: number.join(''),    
                });
                number.unshift(ddd1);
                setRepresentantContact({ ...representantContact, phone1: number.join(' ') });
                break;
            case 2:
                setOptionalPendencies({
                    ...optionalPendencies,
                    phone2: number.join(''),
                });
                number.unshift(ddd2);
                setRepresentantContact({ ...representantContact, phone2: number.join(' ') });
                break;
            case 3:
                number.unshift(ddd3);
                setRepresentantContact({ ...representantContact, fax: number.join(' ') });
            break;
        }
    }

    function handleDddMask(e: any, index: number){
        let value = e;
        value = value.replace(/[^\d]/g,'');
        value = value.replace(/(\d{1})(\d{1})$/,"($1$2)");
        
        index === 1 && ( setDdd1(value) );
        index === 2 && ( setDdd2(value) );
        index === 3 && ( setDdd3(value) );
        
    }

    function  setChanges(value: string, index: number){
        let arr = [];
        switch(index){
            case 1:
                !!representantContact.phone1 ?
                    arr.push(representantContact.phone1.split(' ')[1])
                    : arr.push(representantContact.phone1);
                arr.unshift(value);
                
                setRepresentantContact({
                    ...representantContact,
                    phone1: arr.join(' ')
                });
                break;
            case 2:
                    !!representantContact.phone2 ?
                        arr.push(representantContact.phone2.split(' ')[1])
                        : arr.push(representantContact.phone2);
                    arr.unshift(value);
                    setRepresentantContact({
                        ...representantContact,
                        phone2: arr.join(' ')
                    });
                break;
            case 3:
                !!representantContact.fax ?
                    arr.push(representantContact.fax.split(' ')[1])
                    : arr.push(representantContact.fax);
                arr.unshift(value);
                setRepresentantContact({
                    ...representantContact,
                    fax: arr.join(' '),
                });
            break;
        }   
    }

    function setStates(){
        if(representantContact.phone1){
            setDdd1(representantContact.phone1.split(' ')[0]);
            setPhone1(representantContact.phone1.split(' ')[1]);
        }
        if(representantContact.phone2){
            setDdd2(representantContact.phone2.split(' ')[0]);
            setPhone2(representantContact.phone2.split(' ')[1]);
        }
        if(representantContact.fax){
            setDdd3(representantContact.fax.split(' ')[0]);
            setFax(representantContact.fax.split(' ')[1]);
        }
        
    }
    
    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        setStates();
    },[representantContact]);

    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <ContainerCenter title={'Dados para Contato'} icon={FaPhone}>
            <Header />
            <DivLeft>
                <FCPJ />
            </DivLeft>


            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Dados para Contato</p>

                    <div style={{ marginLeft: 10 }}>

                        <LabelBox>
                            <Label>DDD: </Label> &emsp;&emsp;
                            <Label>Telefone 1: </Label>
                        </LabelBox> <br />
                        <NumberBox>
                            <Input 
                                style={{ width: 50 }} 
                                type={'text'}
                                maxLength={4}
                                value={ddd1} 
                                onChange={e => handleDddMask(e.target.value,1)}
                                onBlur={() => setChanges(ddd1, 1)}
                            />&emsp;
                            <Input 
                                style={{ width: 100 }}
                                type={'text'}
                                maxLength={10}
                                value={phone1}
                                onChange={e => handleContactMask(e.target.value, 1)} 
                            />
                        </NumberBox>
                        &emsp;
                        <LabelBox>
                            <Label>DDD: </Label> &emsp;&emsp;
                            <Label>Telefone 2: </Label>
                        </LabelBox> <br />
                        <NumberBox>
                            <Input 
                                style={{ width: 50 }}                                
                                maxLength={4}                               
                                value={ddd2}
                                onChange={e => handleDddMask(e.target.value, 2) }
                                onBlur={() => setChanges(ddd2, 2)}
                            />&emsp;
                            <Input 
                                style={{ width: 100 }}
                                type={'text'}
                                maxLength={10}
                                value={phone2}
                                onChange={e => handleContactMask(e.target.value, 2)} 
                            />
                        </NumberBox>
                        &emsp;
                        <LabelBox>
                            <Label>DDD: </Label> &emsp;&emsp;
                            <Label>FAX: </Label>
                        </LabelBox> <br />
                        <NumberBox>
                            <Input 
                                style={{ width: 50 }} 
                                maxLength={2} 
                                value={ddd3}
                                onChange={e => handleDddMask(e.target.value, 3)}
                                onBlur={() => setChanges(ddd3, 3)}
                            />&emsp;
                            <Input 
                                style={{ width: 100 }}
                                maxLength={9}
                                type={'text'}
                                value={fax}
                                onChange={e => handleContactMask(e.target.value, 3)}
                            />
                        </NumberBox>
                        <br /><br />

                        <Label>Correio Eletrônico: </Label> <br />
                        <Input
                            style={{ width: 300 }} 
                            value={representantContact.email} 
                            onChange={e=>setRepresentantContact({...representantContact,email: e.target.value})} 
                            type={'email'} 
                        /> <br/> <br/>



                        <Label>Caixa Postal: </Label> <br />
                        <Input style={{ width: 200 }} disabled/> <br />
                        <Label>CEP da Caixa Postal: </Label> <br />
                        <Input style={{ width: 200 }} disabled/> <br /><br />



                    </div>
                </div>
            </DivRight>

        </ContainerCenter>
    );
}

export default Contact;