import { Input, Select, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { useFormViability } from '../../../../../../../../../../../../hooks/formViability';
import { usePendencies } from '../../../../../../../../../../../../hooks/pendencies';
import { useProtocol } from '../../../../../../../../../../../../hooks/protocol';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';

const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`

const Identification: React.FC = () => {
    const radioStyle ={
        display: 'block',
        height: '20px',
        lineHeight: '30px'
    }

  const [name, setName] = useState<string>('');
  const [ cnpj, setCnpj ] = useState<string>('');
  const [ cpf, setCpf ] = useState<string>('');
  const { protocol } = useProtocol();
  const { mandatoryPendencies, setMandatoryPendencies } = usePendencies();
  const { capital, setCapital, fantasyName, setFantasyName } = useFormViability();

  async function handleDataIdentification(){
    const res = await api.get(`/aikjunta/viability/${protocol}`, config);
    
    setCnpj(res.data.fictional_person.cnpj);
    setName(res.data.fictional_person.full_name);
    setCpf(res.data.fictional_person.cpf);
  }

  function handleFantasy(e: string){ //setar nome fantasia OBRIGATÓRIO
    let fName = {
      ...fantasyName
    }
    fName.name = e;
    setFantasyName(fName);
    setMandatoryPendencies({
      ...mandatoryPendencies, 
      fantasyName: fName.name,    
    })
  }

  function handleCapital(e: any){ //setar capital social OBRIGATÓRIO
    let value = {
      ...capital
    }
    value.value = e;
        
    setCapital(value);
    setMandatoryPendencies({
      ...mandatoryPendencies, 
      capitalValue: value.value,
    })
  }

  function handleFormatCapital(e:  any){
    var value = e; 
    value=value.replace(/\D/g,"");
    value=value.replace(/(\d{1})(\d{14})$/,"$1.$2");
    value=value.replace(/(\d{1})(\d{11})$/,"$1.$2");
    value=value.replace(/(\d{1})(\d{8})$/,"$1.$2");
    value=value.replace(/(\d{1})(\d{5})$/,"$1.$2");
    value=value.replace(/(\d{1})(\d{1,2})$/,"$1,$2");
  
    handleCapital(value);
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    handleDataIdentification();
  }, [protocol])

  /* eslint-enable react-hooks/exhaustive-deps */

  return(
      <ContainerCenter title={'Identificação'} icon={FaUser}>
        <Header />
        <DivLeft>
            <FCPJ />
        </DivLeft>

        <DivRight>
        <div style={{
      width: 700,
      height: 450, 
      border: '1px solid black', 
      marginBottom: 10,
      overflowY: 'scroll',
      scrollBehavior: 'auto'
      }}>
      <p style={{fontWeight:'bold', textAlign: 'center', backgroundColor: '#ccc'}}>Identificação</p>
      <div style={{marginLeft: 10}}>
        <Label>Número do CNPJ: </Label><br/>
        <Input disabled value={cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}style={{width: 300}}/> <br/> <br/>
        <Label>Nome Empresarial (firma ou denominação): </Label>
        <Input disabled value={`${name} ${cpf}`}/> <br/> <br/>
        <Label>Natureza Jurídica: </Label> <br/>
        <Select disabled value={'213-5 Empresário (Individual)'}/> <br/><br/>
        <Label>* Capital Social: </Label> <br/>
        <Input
          onChange={e=>handleFormatCapital(e.target.value)} 
          value={capital.value} 
          type="text"
          style={{
            width: 200,
          }}
        />
        <br/> <br/>
          
        <Label>* Título do Estabelecimento (nome fantasia): </Label> <br/>
        <Input 
          onChange={e=>handleFantasy(e.target.value)} 
          value={fantasyName.name} 
          style={{
            width: 400,
          }}/> <br/> <br/>
         

        <p style={{fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc', marginRight: 10}}>Dados do Órgão de Registro</p>

        <Label>Tipo de Órgão: </Label> <br/>
        <Select disabled value={'Junta Comercial'}/> <br/> <br/>

        <Label>NIRE: </Label> <br/>
        <Input disabled/> <br/> <br/>

        <Label>Número de Registro: </Label> <br/>
        <Input disabled/> <br/> <br/>

        <Label>CNPJ do Órgão de Registro: </Label>
        <Input disabled/> <br/><br/>

        <Label>Ente Federativo Responsável: </Label> <br/>
        <Radio.Group disabled>
            <Radio style={radioStyle} value={1}>
                União
            </Radio>

            <Radio style={radioStyle} value={2}>
                Estado
            </Radio> 

            <Radio style={radioStyle} value={3}>
                Município
            </Radio>
        </Radio.Group> <br/> <br/>

        <Label>Tipo de Estatal (Dependência Orçamentária):</Label> <br/>
        <Select disabled style={{width: 300}}/> <br/> <br/>
      </div>
    </div>
        </DivRight>
      </ContainerCenter>
  );
}

export default Identification;