import React, { useEffect, useState } from 'react';

import { Form, Radio, Checkbox, message } from 'antd';
import { Box, makeStyles } from '@material-ui/core';

import { FormEvents } from '../../SelectEvent/components/components.styled';
import { LabelInput } from '../../../../../../components';
import {
  DevelopmentOptions,
  ActionsI,
  actionsProd,
  actionsAux,
} from './model.data';

import { useFormViability } from '../../../../../../hooks/formViability';


const useStyles = makeStyles(() => ({
  radio: {
    display: 'block',
    lineHeight: '2.4em',
    letterSpacing: '0.5px',
  },
}));
const FormUnityDevelopment = () => {
  const classes = useStyles();
  const [actions, setActions] = useState<ActionsI[]>([] as ActionsI[]);
  const [auxType, setAuxType] = useState<any>();
  const firstRadio = 1;
  const secondRadio = 2;
  const { setEstablishment, establishment } = useFormViability();
  let auxArr:string[] = [];
  let arr:string[] = [];

  useEffect(() =>{ //usado só pra que o contexto seja alterado corretamente
    
  }, [establishment])


  function handleEstablishement(e: string){ 
    arr.push(e);

    auxArr = arr.filter((el, index, self) =>{ //não permitir que se repita a mesma forma de atuação
      return self.indexOf(el) === index;
    });
    BeignSetData();
  }

  function BeignSetData(){ //setar o tipo e a forma de atuação no contexto
  
    let opt = {
      type: establishment.type,
      option: establishment.option
    }

    if(auxType === 1){
      opt.type = "Produtiva";
    }else if(auxType === 2){
      opt.type = "Auxiliar"
    }
    setEstablishment({
      type: opt.type,
      option: establishment.option.concat(auxArr).filter((el, index, array) => { return array.indexOf(el) === index})
    });

  }


  function onChangeRadio(e: any) { //verificar quais opções mostrar ao usuário
    const { value } = e.target;
    
    message.warning("Selecione apenas as opções que se enquadram em sua empresa!")
    if (Number(value) === firstRadio) {
      setActions(actionsProd);
    }
    if (Number(value) === secondRadio) {
      setActions(actionsAux);
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <FormEvents layout={'horizontal'} onChange={onChangeRadio} >
        <Form.Item
          name={'development'}
          style={{ display: 'flex', flexDirection: 'column' }}
          label={
            <LabelInput
              style={{ color: '#333', fontWeight: 700, fontSize: '1.3em' }}
              text={'Tipo de Unidade do Empreendimento'}
            />
          }
          labelAlign={'left'}
          rules={[
            {
              required: true,
              message: 'obrigatório',
            },
          ]}
        >
          <Radio.Group>
            {DevelopmentOptions.map((el, index) => (
              <Radio key={index++} value={index+1} onChange={e=>setAuxType(e.target.value)}>
                {el.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </FormEvents>

      <FormEvents layout={'vertical'} >
        <Form.Item style={{ padding: 4, margin: 4 }} >
          <Checkbox.Group>
            {actions.map((el, index) => (
              <Checkbox 
              
              className={classes.radio}
              key={index}
              onChange={()=>handleEstablishement(el.name)} 
              value={el.name}>
                {el.name}
              </Checkbox>
              
            ))}
          </Checkbox.Group>
        </Form.Item>
      </FormEvents>
    </Box>
  );
};

export { FormUnityDevelopment };
