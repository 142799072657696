import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ContainerCenter } from '../../../components/ContainerCenter';
import { FaList } from 'react-icons/fa';
import { Data } from './models/Data';
import { CardPhotos } from '../business/components/CardPhotos';


const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCards: {
    margin: theme.spacing(2),
    padding: 'unset',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));


const Redesim = () => {
  const classes = useStyles();

  return(
    <ContainerCenter title="Redesim" icon={FaList}>
      <Grid container spacing={2} className={classes.gridCards}>
        {Data.map((item, index) => {
          return (
            <Grid item key={index.toString()}>
              <CardPhotos
                backgroundImg={item.backgroundImg}
                title={item.title}
                link={item.link}
              />
            </Grid>
          );
        })}
      </Grid>
    </ContainerCenter>
  )
}

export default Redesim