import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useFlow } from '../../../../../hooks/flow';
import { useFormViability } from '../../../../../hooks/formViability';

import { Box, Grid, makeStyles } from '@material-ui/core';
import { Form, Button, Input } from 'antd';
import { ContainerOutlined, SearchOutlined } from '@ant-design/icons';
import { ContainerCenter } from '../../../../components/ContainerCenter';
import { LabelInput } from '../../../../../components';
import { TableCnae } from './components/TableCnae';
import { ActivityPrincipal } from './components/ActivityPrincipal';
import { ActivitySecondary } from './components/ActivitySecondary';

import api from '../../../../../services/api';
import config from '../../../../../services/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  item: {
    margin: 0,
  },
}));

const Message = () => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <span>
        Para algumas atividades será necessário responder perguntas para
        definição do risco pelos Órgãos de Licenciamento. O nome reservado está
        sujeito a análise técnica durante a análise do processo pelo Órgão de
        Registro.
      </span>

      <a href={'https://cnae.ibge.gov.br/'} rel="noreferrer" target="_blank">
        Acesse o site oficial da Comissão Nacional de Classificação - CONCLA em
        caso de dúvidas ao selecionar as atividades econômicas do
        empreendimento.
      </a>
    </Box>
  );
};
const CompanyIdentify = () => {


  // A pesquisa não é obrigado passar valor
  // Se passar valor pesquisa aquele valor
  // Se não passar valor pesquisa retorna todos os valores CNAE

  const [showData, setShowData] = useState(false);
  const { routes, setNextLink } = useFlow();
  const { activityPrincipal } = useFormViability();

  const history = useHistory();
  const classes = useStyles();



  function onFinish() {
    setShowData(true); // just for test mock
  }
  const [ setAllClasses] = useState<any>([]);
  const [classeSel, setClasseSel] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [selData, setSelData] = useState<any>();

  function handleClasses() { //mostrar todas as classes CNAE
    let arr: any[] = [];

     arr = data.map((item: { cnae: any; description: any; }, index: any) => ({
      id: index,
      cnae: `${item.cnae}`,
      description: `${item.description}`
    }));    

     setAllClasses(arr);

  }

  function searchCnae() { //mostrar apenas a pesquisada no input
    let aux_arr: any[] = [];

    data.map((item: { cnae: any, description: any }, index: any) => {
      if (classeSel === item.cnae) {
        aux_arr.push({
          id: index + 1,
          cnae: `${item.cnae}`,
          description: `${item.description}`
        });
      }
      return null;
    });

    setSelData(aux_arr);



  }

  async function getCnaeApi() { //chamada a api para a tabela de CNAE
    const res = await api.get('/aikjunta/economic-activity', config);
    setData(res.data)

  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    getCnaeApi();


    //controle de rota protegida
    const routeNext = routes.length - (routes.length - 4); // flow 101
    if (routeNext > routes.length) {
      history.replace('/viabilidade/eventos');
    }
    setNextLink(routes[routeNext]?.route); // position 2

  }, [activityPrincipal]);
  
/* eslint-enable react-hooks/exhaustive-deps */

  return (
    <ContainerCenter
      title={'Identificação da Empresa'}
      message={<Message />}
      icon={ContainerOutlined}
      buttonBack={true}
      buttonNext={activityPrincipal.cnae !== undefined ? true : false}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Box flexDirection={'row'}>
            <Form onFinish={() => onFinish()}>
              <Form.Item
                name={'cna-code'}
                style={{ display: 'flex', flexDirection: 'column' }}
                label={
                  <LabelInput
                    style={{ color: '#333', fontWeight: 700 }}
                    text={
                      'Digite o código CNAE ou a descrição da atividade                  '
                    }
                  />
                }
                labelAlign={'left'}
              >
                <Box>
                  <Input
                    size={'large'}
                    style={{
                      marginRight: '1em',
                      width: '30em',
                      maxWidth: '100%',
                    }}
                    onChange={e => setClasseSel(e.target.value)}
                  />
                  <Button
                    htmlType={'submit'}
                    size={'large'}
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={!classeSel ? handleClasses : searchCnae}
                  >
                    Pesquisar
                  </Button>
                </Box>
              </Form.Item>
            </Form>
          </Box>
        </Grid>

        {showData && <TableCnae data={!classeSel ? data : selData} />}
        {showData && <ActivityPrincipal />}

        {showData && <ActivitySecondary />}
      </Grid>
    </ContainerCenter>
  );
};

export default CompanyIdentify;
