import { Box, Card, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface MenuProps{
  title: string;
  icon: React.FunctionComponent<any>;
  link: string;
}

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  :hover{
    cursor: pointer;
  }
`;

const Menu = ({ 
  title,
  icon: Icon,
  link
}: MenuProps) =>{
  const history = useHistory();
  function pageNavigate(link: string) {
    // global.window.location.href = link;
    history.push(link);
  }

  return(
    <Container style={{height: '200px'}}>
      <CardContainer onClick={() => pageNavigate(link)}>
          <Icon style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            color: 'gray',
          }}/>

          <Box >
            <Typography component={'h1'} style={{ 
              color: 'gray', 
              fontSize: '16px', 
              }}>{title}</Typography>
          </Box>

      </CardContainer>
      
    </Container>
  )
}

export default Menu