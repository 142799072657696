import { Box, makeStyles, Grid } from '@material-ui/core';
import { Button, Divider, Radio, Form } from 'antd';
import { DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { ActivityRow } from '../../../../../../../types';
import { LabelInput } from '../../../../../../components';
import { useFormViability } from '../../../../../../hooks/formViability';

interface OptionI {
  option: string;
  value: boolean;
}
const useStyles = makeStyles((theme) => ({
  containerSecondary: {
    padding: '20px 15px',
    borderTop: '3px solid #d2d2d2',
    // margin: '2em 0',
    border: '1px solid #eee',
    borderRadius: '2px',
  },
  containerPrincial: {
    padding: '20px 15px',
    borderTop: '3px solid #27ae60',
    margin: '1.5em 0',
    border: '1px solid #eee',
    borderRadius: '2px',
  },
}));
interface ActivityContainerProps {
  activity: ActivityRow;

  secondary?: boolean;
}
const ActivityContainer = ({
  activity,

  secondary = true,
}: ActivityContainerProps) => {
  const { setActivityPrincipal, removeActivitySecondary } = useFormViability();

  const classes = useStyles();
  const options: OptionI[] = [
    {
      option: 'Sim',
      value: true,
    },
    {
      option: 'Não',
      value: false,
    },
  ];
  function selectActivityPrincipal() {
    removeActivitySecondary(activity);
    setActivityPrincipal(activity);
  }

  return (
    <Box display={'flex'} width={'100%'} mt={2}>
      <Grid
        container
        className={
          secondary ? classes.containerSecondary : classes.containerPrincial
        }
      >
        <Grid item xs>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box>
              <span
                style={{
                  fontSize: 15,
                  margin: 0,
                  fontWeight: 'bold',
                  flexGrow: 1,
                }}
              >
                {activity.cnae} - {activity.description}
              </span>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Button
              onClick={() => selectActivityPrincipal()}
              size={'large'}
              type={'primary'}
              icon={<CheckCircleOutlined />}
              style={{ marginRight: 20 }}
            >
              Definir como principal
            </Button>
            <Button
              size={'large'}
              style={{
                backgroundColor: '#d9534f',
                color: '#fff',
                borderColor: '#d43f3a',
              }}
              icon={<DeleteOutlined />}
              onClick={() => removeActivitySecondary(activity)}
            >
              Remover
            </Button>
          </Box>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Box>
            <Form layout={'horizontal'}>
              <Form.Item
                style={{ display: 'flex', flexDirection: 'column' }}
                name={'local'}
                label={
                  <LabelInput
                    style={{ color: '#333', fontWeight: 500 }}
                    text={'Atividade exercida no local?'}
                  />
                }
                labelAlign={'left'}
              >
                <Radio.Group>
                  {options.map((el, index) => (
                    <Radio key={index} value={el.value}>
                      {el.option}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export { ActivityContainer };
