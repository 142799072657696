import { FaBook, FaExchangeAlt, FaBuilding, FaCalculator, FaPencilAlt } from 'react-icons/fa'


interface Data{
  title: string;
  icon: React.FunctionComponent<any>;
  link: string;
}

export const IconData: Data[] = [
  { 
    title: "Preciso de consulta prévia?",
    link: '/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/preciso-de-consulta-previa',
    icon: FaBook
  },
  { 
    title: "Alterar dados da Pessoa Jurídica",
    link: '/dbe/redesim/ja-possuo-pessoa-juridica/alteracao/coleta-de-dados',
    icon: FaExchangeAlt
  },
  {
    title: "Inscrever Nova filial",
    link: "https://www.gov.br/empresas-e-negocios/pt-br/redesim/ja-possuo-pessoa-juridica/licencas",
    icon: FaBuilding
  },{
    title: "Solicitar Inscrição Tributária",
    link: "https://www.gov.br/empresas-e-negocios/pt-br/redesim/ja-possuo-pessoa-juridica/licencas",
    icon: FaCalculator
  },{
    title: "Inscrever Patrimônio de Afetação",
    link: "https://www.gov.br/empresas-e-negocios/pt-br/redesim/ja-possuo-pessoa-juridica/licencas",
    icon: FaPencilAlt
  }
]