import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaMapMarker } from 'react-icons/fa';
import styled from 'styled-components';
import { useFormDbe } from '../../../../../../../../../../../../hooks/formDbe';
import api from '../../../../../../../../../../../../services/api';
import config from '../../../../../../../../../../../../services/auth';
import { ContainerCenter } from '../../../../../../../../../../../components/ContainerCenter';
import FCPJ from '../../components/fcpj';
import Header from '../../components/header';


const DivLeft = styled.div`
  float: left;
  margin-left: 80px;
`
const DivRight = styled.div`
  float: right;
  margin-right: 90px;
`
const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 14px;
  display:'inline-block'; 
  margin-bottom: '5px';
`


const LabelBox = styled.div`
    display: flex;
`


const NumberBox = styled.div`
    display: flex;
    flex-direction: row;
`


const AddresContabilist: React.FC = () => {
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [ person, setPerson ] = useState('');
    const [city, setCity] = useState('');
    const [ state, setState ] = useState('');
    const [ email, setEmail ] = useState('');
    const { contabilistAddress, setContabilistAddress } = useFormDbe();
    const [dddPhone, setDddPhone] = useState('');
    const [dddFax, setDddFax] = useState('');

    function getCep(e: any) {
        setContabilistAddress({
          ...contabilistAddress,
          zipCode: e,
        });
    }
      
    function getPublicPlace(e: any) {
        setContabilistAddress({
          ...contabilistAddress,
          publicPlace: e,
        });
    }      

    function getDistrict(e: any) {
        setContabilistAddress({
          ...contabilistAddress,
          district: e,
        });
    }

    function getNumber(e: any) {
        setContabilistAddress({
          ...contabilistAddress,
          number: e,
        });
    }      

    function getComplement(e: any) {
        setContabilistAddress({
          ...contabilistAddress,
          complement: e,
        });
    }  

    function handleCEPMask(e: any){
        let value = e;
        
        value = value.replace(/[^\d]/g,'');
        value = value.replace(/(\d{1})(\d{6})$/,'$1.$2');
        value = value.replace(/(\d{1})(\d{1,3})$/,'$1-$2');
        getCep(value)
    }

    function getUserData(){
        let getPerson;

        getPerson = sessionStorage.getItem(btoa('username'));
        if(getPerson === null) return null;
     
        let decrypted = atob(getPerson);
      
        setPerson(decrypted);

        if(person !== undefined){
            getData();
        }
    }

    async function getData(){
        const res: any = await api.get(`/user/username/${person}`, config);
        
        setDddPhone(res.data.phone_number.substring(0,2));
        setPhone(res.data.phone_number.substring(2));
        setCity(res.data.city);
        setState(res.data.state);
        setEmail(res.data.email);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        getUserData();
        
        
    }, [person])
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <ContainerCenter title={'Endereço do Contabilista'} icon={FaMapMarker}>
            <Header />

            <DivLeft>
                <FCPJ />
            </DivLeft>

            <DivRight>
                <div style={{
                    width: 700,
                    height: 450,
                    border: '1px solid black',
                    marginBottom: 10,
                    overflowY: 'scroll',
                    scrollBehavior: 'auto'
                }}>
                    <p style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ccc' }}>Endereço do Contabilista</p>

                    <div style={{ marginLeft: 10 }}>
                        <Label>CEP: </Label> <br />
                        <Input  
                            style={{ width: 150 }} 
                            type={'text'}
                            maxLength={10}
                            value={contabilistAddress.zipCode}
                            onChange={e => handleCEPMask(e.target.value)}    
                        /> <br /> <br />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <Label>Tipo de Logradouro: </Label>
                            <Select disabled style={{ width: 100 }} /> <br />

                            <Label>Logradouro: </Label>
                            <Input onChange={e => getPublicPlace(e.target.value)} value={contabilistAddress.publicPlace} style={{ width: 300, marginRight: 10 }} /> <br />

                            <Label>Bairro/Distrito:  </Label>
                            <Input onChange={e => getDistrict(e.target.value)} value={contabilistAddress.district}  style={{ width: 300 }} /> <br />

                            <Label>Distrito: </Label>
                            <Input  style={{ width: 100 }} /> <br />

                            <Label>Município: </Label>
                            <Input   style={{ width: 150 }} value={city} onChange={e  => setCity(e.target.value)}/> <br />

                            <Label>UF: </Label>
                            <Input  value={state.replace(/\d/g,'')} maxLength={2} style={{ width: 100 }} onChange={e => setState(e.target.value.toUpperCase())} /> <br />

                            <Label>Número: </Label>
                            <Input onChange={e=>getNumber(e.target.value)} value={contabilistAddress.number}  style={{ width: 100 }} type={'number'} /> <br />

                            <Label>Complemento: </Label>
                            <Input onChange={e => getComplement(e.target.value)} value={contabilistAddress.complement} style={{ width: 200 }} /> <br />

                            <p style={{ textAlign: 'center', backgroundColor: '#ccc' }}><strong>Dados para Contato</strong></p>
                            <LabelBox>
                                <Label>DDD: </Label> &emsp;&emsp;
                                <Label>Telefone 1: </Label>
                            </LabelBox> 
                            <NumberBox>
                                <Input 
                                    style={{ width: 50 }} 
                                    maxLength={4}
                                    value={dddPhone.replace(/(\d{1})(\d{1})$/,"($1$2)")}  
                                    onChange={e => setDddPhone(e.target.value)}   
                                />&emsp;
                                <Input 
                                    style={{ width: 150 }} 
                                    type={'text'}
                                    maxLength={10}
                                    value={phone.replace(/(\d{4})(\d{4})/, "$1-$2")}
                                    onChange={e =>  setPhone(e.target.value)}
                                    
                                    // onChange={e => handleContactMask(e.target.value, 1)}
                                />
                            </NumberBox> <br/>
                            <LabelBox>
                                <Label>DDD: </Label> &emsp;&emsp;
                                <Label>FAX: </Label>
                            </LabelBox> 
                            <NumberBox>
                                <Input 
                                    style={{ width: 50 }}
                                    value={dddFax.replace(/(\d{1})(\d{1})$/,"($1$2)")} 
                                    onChange={e => setDddFax(e.target.value)}
                                    maxLength={4} 
                                />&emsp;
                                <Input 
                                    style={{ width: 150 }} 
                                    type={'text'}
                                    maxLength={9}
                                    value={fax.replace(/(\d{4})(\d{1})/,'$1-$2')}
                                    onChange={e => setFax(e.target.value)}
                                />
                            </NumberBox><br/>
                        <Label>Correio Eletrônico: </Label> 
                        <Input 
                            value={email} 
                            style={{width: 450}}
                            onChange={e => {setEmail(e.target.value)}}    
                        />
                        <br/>
                        </div>
                    </div>


                </div>
            </DivRight>
        </ContainerCenter>
    );
}

export default AddresContabilist;