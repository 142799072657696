import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Button, Input, Modal } from 'antd';
import { FaRegFileAlt, FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerCenter } from '../../../../../../../../../components/ContainerCenter';
import UFForm from '../../../../../Components/UFForm';
import api from '../../../../../../../../../../services/api';
import config from '../../../../../../../../../../services/auth';
import { useProtocol } from '../../../../../../../../../../hooks/protocol';


const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 18px;
  display:'inline-block'; 
  margin-bottom: '5px';
`



const Create = () => {
  const history = useHistory();
  const { protocol, setProtocol } = useProtocol();
  const [ isEqual, setIsEqual ] = useState<boolean>(false);
  const [ exist, setExist ] = useState<boolean>(false);
  const [ disableButton, setDisableButton ] = useState<boolean>(true);

  async function callProtocol(){ //verificar se protocolo existe na tabela de viabilidade
    const response = await api.get('/aikjunta/viability', config);

    response.data.map((item: any) => {
      if(protocol === item.viability_protocol){
        setIsEqual(true);
      }
      return null
    })
  }

  async function compareProtocol() { //verificar se protocolo já foi cadastrado no DBE
    const res = await api.get(`/aikjunta/dbe/`, config);
    
    const found = res.data.find((protocolo: any) => protocolo.dbe_protocol === protocol )
    found !== undefined ? setExist(true) : setExist(false);
  }

  function permitAcces(){ //controle de permissão de acesso
    if(isEqual === true && exist === true){
      Modal.error({
        content: 'Protocolo já cadastrado'
      })
      setDisableButton(true)
    }else if(isEqual === false && exist === false){
      Modal.error({
        content: 'Protocolo não existe! Insira um protocolo válido'
      })
      setDisableButton(true);
    }else if(isEqual === true && exist === false){
      setDisableButton(false);
    }
  }
  
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    callProtocol();
    compareProtocol();
  }, [protocol])

  /* eslint-enable react-hooks/exhaustive-deps */
   
  return (
    <ContainerCenter title={'Coletor Nacional → Inscrição Matriz'} icon={FaPlus}>
      <UFForm />
      <br />
      <br />
      <Label style={{marginLeft: 30}}>Protocolo de Viabilidade</Label> <br />
      <Input 
        type="text"  
        value={protocol} 
        style={{ width: 250, height: 30, marginLeft: 30 }}
        onChange={e=>setProtocol(e.target.value)}
        onBlur={permitAcces}
        />        
      <br />
      <br />
      <Label style={{marginLeft: 30}}>
        Caso escolha assinar o Documento Básico de Entrada - DBE utilizando o Certificado Digital, selecione a opção abaixo: 
      </Label>
      <br />
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <FormControlLabel 
          style={{marginLeft: 20, color:"#1d94f0"}}
          control={
            <Checkbox color="primary"/>
          }
          label="Transmitir com Certificado Digital"
          labelPlacement="end"
        /> &emsp;
        <FaRegFileAlt style={{width: 50, height: 100, color: "#1d94f0"}} />
      </div>

      <br /><br />
      <Button
        onClick={()=> history.push('/redesim/abrir-pessoa-juridica/coletar-dados/coleta-nacional/principal')}
        type="primary"
        size="large"
        style={{marginLeft: 550, marginBottom: 10}}
        disabled={disableButton} 
      >
        Continuar
      </Button>
      


    </ContainerCenter>
  )
}

export default Create