

interface GenericalObject{ 
  name:string;
  value?:number;
}
const juntaComercial:GenericalObject[] = [
  { 
    name:"CONSORCIO DE SOCIEDADES",
  
  }, 
  { 
    name:'COOPERATIVA'
  }, 
  { 
    name:'COOPERATIVAS DE CONSUMO'
  },
  { 
     name:"EMPRESA INDIVIDUAL DE RESPONSABILIDADE LIMITADA (DE NATUREZA EMPRESÁRIA)"
  },
  { 
    name:'EMPRESA PUBLICA'
  }, 
  { 
    name:'EMPRESÁRIO'
  }, 
  { 
    name:"ESTABELECIMENTO,NO BRASIL, EMP.BINAC.ARGENT-BRASIL"
  }, 
  { 
    name:'ESTABELECIMENTO,NO BRASIL,DE SOCIEDADE ESTRANGEIRA - FILIAL'
  }, 
  { 
    name:'GRUPO DE SOCIEDADES'
  },
  { 
    name:'SOCIEDADE ANONIMA ABERTA'
  }, 
  { 
    name:'SOCIEDADE ANONIMA FECHADA'
  }, 
  { 
    name:'SOCIEDADE DE ECONOMIA MISTA'
  }, 
  { 
    name:'SOCIEDADE EMPRESARIA EM COMANDITA POR ACOES'
  }, 
  { 
    name:'SOCIEDADE EMPRESARIA EM COMANDITA SIMPLES'
  }, 
  { 
    name:'SOCIEDADE EMPRESARIA EM NOME COLETIVO'
  }, 
  { 
    name:'SOCIEDADE EMPRESÁRIA LIMITADA'
  }
 ]

const cartorioRegistro : GenericalObject[] = [ 
  { 
    name:"ASSOCIACAO PRIVADA"
  }, 
  { 
    name:'CONSORCIO PUBLICO DE DIREITO PRIVADO'
  }, 
  { 
    name:'EMPRESA INDIVIDUAL DE RESPONSABILIDADE LIMITADA (DE NATUREZA SIMPLES)'
  }, 
  { 
    name:'ENTIDADE DE MEDIACAO E ARBITRAGEM'
  }, 
  { 
    name:'ENTIDADE SINDICAL'
  }, 
  { 
    name:'ESTABELECIMENTO,NO BRASIL,DE FUNDACAO OU ASSOCIACAO ESTRANGEIRAS'
  }, 
  { 
    name:'ESTABELECIMENTO,NO BRASIL,DE SOCIEDADE ESTRANGEIRA - FILIAL'
  }, 
  { 
    name:'FUNDACAO PRIVADA'
  }, 
  { 
    name:'FUNDACAO PUBLICA DE DIREITO PRIVADO ESTADUAL OU DO DISTRITO FEDERAL'
  }, 
  { 
    name:'FUNDACAO PUBLICA DE DIREITO PRIVADO FEDERAL'
  },
  { 
    name:'FUNDACAO PUBLICA DE DIREITO PRIVADO MUNICIPAL'
  }, 
  { 
    name:'ORGANIZACAO RELIGIOSA'
  }, 
  { 
    name:'ORGANIZACAO SOCIAL'
  }, 
  { 
    name:'ORGAO DE DIRECAO LOCAL DE PARTIDO POLITICO'
  }, 
  { 
    name:'ORGAO DE DIRECAO NACIONAL DE PARTIDO POLITICO'
  }, 
  { 
    name:'ORGAO DE DIRECAO REGIONAL DE PARTIDO POLITICO'
  }, 
  { 
    name:'SERVICO SOCIAL AUTONOMO'
  }, 
  { 
    name:'SOCIEDADE SIMPLES EM COMANDITA SIMPLES'
  }, 
  { 
    name:'SOCIEDADE SIMPLES EM NOME COLETIVO'
  }, 
  { 
    name:'SOCIEDADE SIMPLES LIMITADA'
  }, 
  { 
    name:'SOCIEDADE SIMPLES PURA'
  }
]

const aob:GenericalObject[] = [ 
  { 
    name:"SOCIEDADE SIMPLES PURA"
  }, 
   { 
     name:'SOCIEDADE UNIPESSOAL DE ADVOCACIA'
   }
 ]

const atoLegal:GenericalObject[] = [
  { 
    name:'AUTARQUIA ESTADUAL OU DO DISTRITO FEDERAL'
  }, 
  { 
    name:'AUTARQUIA FEDERAL'
  }, 
  { 
    name:'AUTARQUIA MUNICIPAL'
  }, 
  { 
    name:'CONSORCIO PUBLICO DE DIREITO PUBLICO (ASSOCIACAO PUBLICA)'
  }, 
  { 
    name:'ESTADO OU DISTRITO FEDERAL'
  }, 
  { 
     name:'FUNDACAO ESTADUAL OU DO DISTRITO FEDERAL'
  }, 
  { 
    name:'FUNDACAO FEDERAL'
  }, 
  { 
    name:'FUNDACAO PUBLICA DE DIREITO PUBLICO MUNICIPAL'
  }, 
  { 
    name:'MUNICIPIO'
  }, 
  { 
    name:'ORGAO PUBLICO AUTONOMO ESTADUAL OU DO DISTRITO FEDERAL'
  }, 
  { 
    name:'ORGAO PUBLICO AUTONOMO FEDERAL'
  }, 
  { 
    name:'ORGAO PUBLICO AUTONOMO MUNICIPAL'
  },
  { 
    name:'ORGAO PUBLICO DO PODER EXECUTIVO ESTADUAL OU DO DISTRITO FEDERAL'
  }, 
   { 
     name:'ORGAO PUBLICO DO PODER EXECUTIVO FEDERAL'
   }, 
   { 
     name:'ORGAO PUBLICO DO PODER EXECUTIVO MUNICIPAL'
   }, 
   { 
     name:'ORGAO PUBLICO DO PODER JUDICIARIO ESTADUAL'
   },
   { 
     name:'ORGAO PUBLICO DO PODER JUDICIARIO FEDERAL'
   },
   { 
     name:'ORGAO PUBLICO DO PODER LEGISLATIVO ESTADUAL OU DO DISTRITO FEDERAL'
   }, 
   { 
     name:'ORGAO PUBLICO DO PODER LEGISLATIVO FEDERAL'
   },
   { 
     name:'ORGAO PUBLICO DO PODER LEGISLATIVO MUNICIPAL'
   }, 
   { 
     name:'SERVICO NOTARIAL E REGISTRAL (CARTORIO)'
   },
   {
     name:'SERVICO SOCIAL AUTONOMO'
   }
]

const outros:GenericalObject[] = [
  { 
    name:'CONSORCIO SIMPLES'
  }, 
  { 
    name:'EMPRESA BINACIONAL'
  }, 
  { 
     name:'ORGANIZACAO INTERNACIONAL'
  }, 
  { 
    name:'OUTRAS INSTITUICOES EXTRATERRITORIAIS'
  }, 
  { 
    name:'REPRESENTACAO DIPLOMATICA ESTRANGEIRA'
  }
]

export { juntaComercial,cartorioRegistro,aob,atoLegal,outros}