export const cpfValidate = (doc_number: string) => {
  // variaveis de apoio/manipulação
  let first_digit: number;
  let last_digit: number;
  let result: number = 0;

  //proteção #0 -> remover pontos e traços
  doc_number = doc_number.replace(/\./g, '');
  doc_number = doc_number.replace(/-/g, '');
  doc_number = doc_number.replace(/_/g, '');

  // proteção #1 -> verificando se todos os números são iguais, utilizando REGEX
  // REGEX de validação /^(\d)\1{10}$/

  if(doc_number === '' || doc_number.toString().length !== 11 || /^(\d)\1{10}$/.test(doc_number)){
      return false;
  } else{
      // proteção #2 -> validação do primeiro digito
      
      for(let i = 1; i <= 9; i++){
          result += parseInt(doc_number.substring(i - 1, i)) * (11 - i);
      }

      first_digit = (result * 10) % 11;

      // proteção #3 -> caso resultado seja 10 ou 11, informamos o valor 0
      if((first_digit === 10) || (first_digit === 11)){
          first_digit = 0
      }

      // verificando se a validação do digito 1 e true ou false
      if(first_digit !== parseInt(doc_number.substring(9, 10))){
          return false;
      } else{
          // proteção #4 -> validação do segundo digito
          
          result = 0
          
          for(let i = 1; i <= 10; i++){
              result += parseInt(doc_number.substring(i - 1, i)) * (12 - i);
          }

          last_digit = (result * 10) % 11;

          // proteção #5 -> verificando se o resultado e igual a 10 ou 11, caso seja informamos o valor 0
          if((last_digit === 10) || (last_digit === 11)){
              last_digit = 0
          }

          //verificando se a validação do digito 2 e true ou false
          if(last_digit !== parseInt(doc_number.substring(10, 11))){
              return false;
          } else {
              return true;
          }
      }
  }
}
