import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import config from '../../../../../../services/auth';
import api from '../../../../../../services/api';
import { nature } from '../OpenPJ/pages/DataCollector/pages/CreatePJ/models/nature';

const { Option } = Select

const DivForm = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  margin-left:30px;
  margin-right: 30px;
`

const Label = styled.label`
  color:#1d94f0;
  font-weight: 300; 
  font-size: 18px;
  display:'inline-block'; 
  margin-bottom: '5px';
`


const UFForm = () => {

  const [city, setCity] = useState<any>([]);
  const [uf, setUf] = useState<any>([]);
  const [legalNatures, setLegalNature] = useState<any>([]);

  function handleChange(e: string){ // popular o select com as cidades do estado selecionado
    handleCity(e);
  }


  async function handleUF(){
    const res = await api.get(`/state`, config); //buscar estados na tabela de ESTADOS
    setUf(res.data);
  }

  async function handleCity(UF: any){
    const res = await api.get(`/municipalities/${UF}`, config); //buscar cidades na tabela de BrazillianMunicipalities
    setCity(res.data);
  }

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(()=>{
      handleUF();
      setLegalNature(nature);
      console.log(legalNatures);
  }, legalNatures)
  
  /* eslint-enable react-hooks/exhaustive-deps */


  return(
    <DivForm>
            <Label>UF: </Label>
            <Select style={{width: '90%'}} onChange={handleChange}>
              {uf.map((item: { name: string; id: number; uf: string })  =>( 
                <Option key={item.id} value={item.uf}>{item.name}</Option>
                
              ))}
            </Select>
            <br/>
            <br/>
            <Label>Cidade: </Label>
            <Select notFoundContent style={{width: '90%'}} onChange={handleChange}>
            {city.map((item: { county: string; id: number })  =>( 
                <Option key={item.id} value={item.county}>{item.county}</Option>
                
              ))}
            </Select>
            <br/>
            <br/>
            <Label>Natureza Jurídica: </Label>
            <Select style={{width: '90%'}} >
              {
                legalNatures.map((item: {cod: string, desc: string}) => (
                  <Option value={item.cod} key={item.cod} >{item.cod} - {item.desc}</Option>
                ))
              }
            </Select>
           
         </DivForm>
  )
}

export default UFForm;