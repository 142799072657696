
interface Data { 
  title:string;
  link:string;
  description:string;
}
export const cardsData:Data[]= [
  { 
    title:'Viabilidade',
    link:'/viabilidade',
    description:'Realize a consulta de viabilidade'
  }, 
  { 
    title:'Consulta DBE',
    link:'/dbe',
    description:'Documento básico de entrada do CNPJ'
  }, 
  { 
    title:'Integrador',
    link:'/integrador',
    description:'Sistema integrador REMP e FCN'
  },
  { 
    title:'Retirar Documentos',
    link: '/principal',
    description:'Certidões e documentos aprovados (Via única)'
  },
  { 
    title:'Inscrições Tributárias e Licenciamento',
    link: '/principal',
    description:'Acesso ao portal',
  },
  {
    title: 'Registro Digital',
    link:'/registro-digital',
    description: 'Envio eletrônico de documentos e FCN'

  }
]