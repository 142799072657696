import React, { SetStateAction, useContext, useState } from "react";
import { Contabilist, ContabilistAddress, Representant, RepresentantContact } from "../../types";

interface DBEContextData{
    representant: Representant;
    setRepresentant: React.Dispatch<SetStateAction<any>>;
    representantContact: RepresentantContact;
    setRepresentantContact: React.Dispatch<SetStateAction<any>>;
    contabilist: Contabilist;
    setContabilist: React.Dispatch<SetStateAction<any>>;
    contabilistAddress: ContabilistAddress;
    setContabilistAddress: React.Dispatch<SetStateAction<any>>;
}

const FormContext = React.createContext({} as DBEContextData);

const FormProviderDbe: React.FC = ({ children }) => {
    const [ representant, setRepresentant ] = useState<Representant>({} as Representant);
    const [ contabilist, setContabilist ] = useState<Contabilist>({} as Contabilist);
    const [ representantContact, setRepresentantContact ] = useState<RepresentantContact>({} as RepresentantContact);
    const [ contabilistAddress, setContabilistAddress ] = useState<ContabilistAddress>({} as ContabilistAddress);
    return(
        <FormContext.Provider
            value={{
                representant, 
                setRepresentant,
                representantContact,
                setRepresentantContact, 
                contabilist, 
                setContabilist,
                contabilistAddress,
                setContabilistAddress
            }}
        >
            {children}
        </FormContext.Provider>
    )
}

const useFormDbe = () => {
    const context = useContext(FormContext);
    if(!context) throw new Error('UseContext must be used within a provider');
    const {
        representant,
        setRepresentant,
        representantContact,
        setRepresentantContact, 
        contabilist, 
        setContabilist,
        contabilistAddress,
        setContabilistAddress
    } = context;

    return {
        representant,
        setRepresentant,
        representantContact,
        setRepresentantContact, 
        contabilist,
        setContabilist,
        contabilistAddress,
        setContabilistAddress
    };
};

export { FormProviderDbe, useFormDbe };
export default FormContext;