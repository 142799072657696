import React, { createContext, useState, useContext, useEffect } from 'react';
import { FlowI, selectFlow } from '../routes/flow';

interface FlowContextData {
  eventType: 'subscribe' | 'alter' | '';
  setEventType: React.Dispatch<any>;
  code: number; // selected event
  setCode: React.Dispatch<any>;
  previousLink: string; // used to return page
  setPreviousLink: React.Dispatch<any>;
  nextLink: string;
  setNextLink: React.Dispatch<any>;
  routes: FlowI[];
}

const FlowContext = createContext({} as FlowContextData);

const FlowProvider: React.FC = ({ children }) => {
  const [eventType, setEventType] = useState<'subscribe' | 'alter' | ''>('');
  const [routes, setRoutes] = useState<FlowI[]>([]);

  const [code, setCode] = useState<number>(0);
  const [previousLink, setPreviousLink] = useState<string>('');
  const [nextLink, setNextLink] = useState<string>('');

  useEffect(() => {
    if (code !== 0 && eventType === 'subscribe') {
      let routes = selectFlow(code);
      setRoutes(routes);
    } else if (code !== 0 && eventType === 'alter') {
      //  TODO
      // filter by routes of alterFlow
    }
  },[code, eventType]);
  return (
    <FlowContext.Provider
      value={{
        code,
        routes,
        setCode,
        eventType,
        setEventType,
        nextLink,
        setNextLink,
        previousLink,
        setPreviousLink,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};

const useFlow = () => {
  const context = useContext(FlowContext);
  if (!context) throw new Error('UseContext must be used within a Provider');
  const {
    code,
    setCode,
    eventType,
    setEventType,
    previousLink,
    setPreviousLink,
    nextLink,
    routes,
    setNextLink,
  } = context;
  return {
    routes,
    code,
    setCode,
    eventType,
    setEventType,
    previousLink,
    setPreviousLink,
    nextLink,
    setNextLink,
  };
};

export { useFlow, FlowProvider };
