import React from 'react';
import FlagIcon from '@material-ui/icons/Flag';
import { Box, Grid } from '@material-ui/core';
import { useFormViability } from '../../../../../../hooks/formViability';
import { ActivityContainer } from './ActivityContainer';

const ActivitySecondary = () => {
  const { activitiesSecondary } = useFormViability();
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box display={'flex'} flexDirection={'row'}>
        <FlagIcon />
        <h2 style={{ fontSize: 24, margin: 0 }}>Atividade(s) Secundária(s):</h2>
      </Box>

      <Grid spacing={2} container style={{ display: 'flex', width: '100%' }}>
        {activitiesSecondary.length > 0 &&
          activitiesSecondary.map((el, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ActivityContainer activity={el} />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export { ActivitySecondary };
