import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ContainerCenter } from '../../../../../../../components/ContainerCenter';
import { FaExchangeAlt } from 'react-icons/fa';
import { IconData } from '../../models/IconData'
import { IconCards } from '../../../AlreadyExist/components/IconCards'


const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridCards: {
    margin: theme.spacing(2),
    padding: 'unset',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
}));

const AlterCad = () => {
  const classes = useStyles();
  return(
    <ContainerCenter
      title={'Alteração Cadastral'}
      icon={FaExchangeAlt}>

      <Grid container spacing={2} className={classes.gridCards}>
        {IconData.map((item, index) => {
          return (
            <Grid item key={index.toString()}>
              <IconCards
                icon={item.icon}
                title={item.title}
                link={item.link}
              />
            </Grid>
          );
        })}
      </Grid>


    </ContainerCenter>
  )
}

export default AlterCad