import React , { createContext, useContext, useState } from 'react'

interface ProtocolContextData{
    protocol: string;
    setProtocol: React.Dispatch<any>;
}
const ProtocolContext = createContext({} as ProtocolContextData)

const ProtocolProvider: React.FC = ({ children }) => {
    const [protocol, setProtocol] = useState<string>('');
    return (
        <ProtocolContext.Provider value={{protocol, setProtocol}}> 
            {children}
        </ProtocolContext.Provider>
    )
}

const useProtocol = () => {
    const context = useContext(ProtocolContext);
    if(!context) throw new Error('UseProtocol must be used within a Provider');
    const { protocol, setProtocol } = context;
    return { protocol, setProtocol };
}

export { ProtocolProvider, useProtocol }