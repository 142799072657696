import React from 'react';
import { Table, Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { useFormViability } from '../../../../../../hooks/formViability';
import { ActivityRow } from '../../../../../../../types';


export const ButtonAction = styled(Button)`
  background-color: #009d72;
  color: white;
  border: none;
  transition: background-color 1s;
  &:hover {
    background-color: #09bb8c;
  }
`;
const TableCnae = ({data} : any) => {
  const { setActivitiesSecondary, activitiesSecondary } = useFormViability();
  

  function selectRow(record: ActivityRow) {
    var activities = activitiesSecondary.filter(
      (el) => el.cnae !== record.cnae
    );

    setActivitiesSecondary([...activities, record]);
  }

  

  const columns: ColumnType<any>[] = [
    {
      title: 'Código CNAE',
      dataIndex: 'cnae',
      key: 'cnae',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ação',
      key: 'x',
      dataIndex: '',
      render: (record) => (
        <ButtonAction
          size={'large'}
          type={'primary'}
          onClick={() => selectRow(record)}
        >
          Adicionar
        </ButtonAction>
      ),
    },
  ];
  
 
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Table
        bordered
        style={{ minHeight: '30vh', width: '100%', backgroundColor: 'white' }}
        columns={columns}
        dataSource={data}
        pagination={{position: ['bottomLeft']}}
        
      />
      
    </Box>
  );
};

export { TableCnae };
