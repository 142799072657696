interface Data {
    title: string;
    link: string;
}

export const cardsData: Data[] = [
    {
        title: 'Novo FCN/REMP',
        link: '/integrador/novofcn'
    },
    {
        title: "Abrir FCN/REMP",
        link: '/integrador'
    },
    {
        title: 'Consultar FCNRE do Usuário',
        link: '/integrador'
    }
]