import { Box, Typography } from '@material-ui/core';
import React from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface CardProps{
  title: string;
  icon: React.FunctionComponent<any>;
  link: string;
}

const Container = styled.div`
  width: 700px;
  height: 400px;
  display: inline;
  flex-direction: row;
`

const Card = styled.div`
  width: 200px;
  height: 200px;
  background-color: #eeeef7;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color;
  :hover{
    cursor: pointer;
    background-color: #bde2f3;
  }
`

const ContainerCard = ({ 
  title,
  icon: Icon,
  link,
}: CardProps) => {
  const history = useHistory();
  function pageNavigate(link: string) {
    // global.window.location.href = link;
    history.push(link);
  }
  return(
    <Container>
      <Card onClick={()=> pageNavigate(link)}>
        <Box>
          <Icon 
            style={{
              display: 'flex',
              color: '#1714a0',
              fontSize: 50,
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 50px 0px 50px'
            }}
          />
          <Box>
            <Typography style={{color: 'darkBlue', fontSize: 16, margin: 20}}>
              {title}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Container>
  )
}

export default ContainerCard