import React from 'react';
import { Alert, Divider } from 'antd';
import { Box } from '@material-ui/core';

import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { useFormViability } from '../../../../../../hooks/formViability';
import { ActivityContainer } from './ActivityContainer';
const ActivityPrincipal = () => {
  const { activityPrincipal } = useFormViability();
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Divider />
      <Box display={'flex'} alignItems={'center'}>
        <TrackChangesIcon />
        <h2 style={{ fontSize: 24, margin: 0 }}>Atividade Principal:</h2>
      </Box>
      {!activityPrincipal.cnae && (
        <Alert
          type={'warning'}
          style={{
            padding: 15,
            marginTop: 10,
            borderLeft: '4px solid #e6c025',
          }}
          message={
            <span
              style={{
                borderColor: '#ffe88a',
                color: '#8a6d3b',
              }}
            >
              <strong>Atenção:</strong> Escolha uma atividade principal dentre
              as selecionadas para prosseguir
            </span>
          }
        />
      )}

      <Divider />
      {activityPrincipal.cnae && (
        <ActivityContainer activity={activityPrincipal} secondary={false} />
      )}
    </Box>
  );
};

export { ActivityPrincipal };
