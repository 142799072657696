function getToken(){
    const token = localStorage.getItem('token_junta');
    if(token === null) return null;

    let decrypted = atob(token);
    return `Bearer ${decrypted}`;
}

const config = {
    get headers() {
        return { 
            Authorization: getToken(),
            'Content-Type': 'application/json'
        };
    }
}

export default config;