interface NatureI{
	cod: string;
	desc: string;
}

export const nature :  NatureI[] = [

	{
		cod: "101-5", 
		desc: "Órgão Público do Poder Executivo Federal"},
	{
		cod: "102-3", 
		desc: "Órgão Público do Poder Executivo Estadual ou do Distrito Federal"},
	{
		cod: "103-1", 
		desc: "Órgão Público do Poder Executivo Municipal"},
	{
		cod: "104-0", 
		desc: "Órgão Público do Poder Legislativo Federal"},
	{
		cod: "105-8", 
		desc: "Órgão Público do Poder Legislativo Estadual ou do Distrito Federal"},
	{
		cod: "106-6", 
		desc: "Órgão Público do Poder Legislativo Municipal"},
	{
		cod: "107-4", 
		desc: "Órgão Público do Poder Judiciário Federal"},
	{
		cod: "108-2", 
		desc: "Órgão Público do Poder Judiciário Estadual"},
	{
		cod: "110-4", 
		desc: "Autarquia Federal"},
	{
		cod: "111-2", 
		desc: "Autarquia Estadual ou do Distrito Federal"},
	{
		cod: "112-0", 
		desc: "Autarquia Municipal"},
	{
		cod: "113-9", 
		desc: "Fundação Pública de Direito Público Federal"},
	{
		cod: "114-7", 
		desc: "Fundação Pública de Direito Público Estadual ou do Distrito Federal"},
	{
		cod: "115-5", 
		desc: "Fundação Pública de Direito Público Municipal"},
	{
		cod: "116-3", 
		desc: "Órgão Público Autônomo Federal"},
	{
		cod: "117-1", 
		desc: "Órgão Público Autônomo Estadual ou do Distrito Federal"},
	{
		cod: "118-0", 
		desc: "Órgão Público Autônomo Municipal"},
	{
		cod: "119-8", 
		desc: "Comissão Polinacional"},
	{
		cod: "120-1", 
		desc: "Fundo Público"},
	{
		cod: "121-0", 
		desc: "Consórcio Público de Direito Público (Associação Pública)"},
	{
		cod: "122-8", 
		desc: "Consórcio Público de Direito Privado"},
	{
		cod: "123-6", 
		desc: "Estado ou Distrito Federal"},
	{
		cod: "124-4", 
		desc: "Município"},
	{
		cod: "125-2", 
		desc: "Fundação Pública de Direito Privado Federal"},
	{
		cod: "126-0", 
		desc: "Fundação Pública de Direito Privado Estadual ou do Distrito Federal"},
	{
		cod: "127-9", 
		desc: "Fundação Pública de Direito Privado Municipal"},
	{
		cod: "201-1", 
		desc: "Empresa Pública"},
	{
		cod: "203-8", 
		desc: "Sociedade de Economia Mista"},
	{
		cod: "204-6", 
		desc: "Sociedade Anônima Aberta"},
	{
		cod: "205-4", 
		desc: "Sociedade Anônima Fechada"},
	{
		cod: "206-2", 
		desc: "Sociedade Empresária Limitada"},
	{
		cod: "207-0", 
		desc: "Sociedade Empresária em Nome Coletivo"},
	{
		cod: "208-9", 
		desc: "Sociedade Empresária em Comandita Simples"},
	{
		cod: "209-7", 
		desc: "Sociedade Empresária em Comandita por Ações"},
	{
		cod: "212-7", 
		desc: "Sociedade em Conta de Participação"},
	{
		cod: "213-5", 
		desc: "Empresário (Individual)"},
	{
		cod: "214-3", 
		desc: "Cooperativa"},
	{
		cod: "215-1", 
		desc: "Consórcio de Sociedades"},
	{
		cod: "216-0", 
		desc: "Grupo de Sociedades"},
	{
		cod: "217-8", 
		desc: "Estabelecimento, no Brasil, de Sociedade Estrangeira"},
	{
		cod: "219-4", 
		desc: "Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira"},
	{
		cod: "221-6", 
		desc: "Empresa Domiciliada no Exterior"},
	{
		cod: "222-4", 
		desc: "Clube/Fundo de Investimento"},
	{
		cod: "223-2", 
		desc: "Sociedade Simples Pura"},
	{
		cod: "224-0", 
		desc: "Sociedade Simples Limitada"},
	{
		cod: "225-9", 
		desc: "Sociedade Simples em Nome Coletivo"},
	{
		cod: "226-7", 
		desc: "Sociedade Simples em Comandita Simples "},
	{
		cod: "227-5", 
		desc: "Empresa Binacional"},
	{
		cod: "228-3", 
		desc: "Consórcio de Empregadores"},
	{
		cod: "229-1", 
		desc: "Consórcio Simples"},
	{
		cod: "230-5", 
		desc: "Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)"},
	{
		cod: "231-3", 
		desc: "Empresa Individual de Responsabilidade Limitada (de Natureza Simples)"},
	{
		cod: "232-1", 
		desc: "Sociedade Unipessoal de Advogados"},
	{
		cod: "233-0", 
		desc: "Cooperativas de Consumo"},
	{
		cod: "303-4", 
		desc: "Serviço Notarial e Registral (Cartório)"},
	{
		cod: "306-9", 
		desc: "Fundação Privada"},
	{
		cod: "307-7", 
		desc: "Serviço Social Autônomo"},
	{
		cod: "308-5", 
		desc: "Condomínio Edilício"},
	{
		cod: "310-7", 
		desc: "Comissão de Conciliação Prévia"},
	{
		cod: "311-5", 
		desc: "Entidade de Mediação e Arbitragem"},
	{
		cod: "313-1", 
		desc: "Entidade Sindical"},
	{
		cod: "320-4", 
		desc: "Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras"},
	{
		cod: "321-2", 
		desc: "Fundação ou Associação Domiciliada no Exterior"},
	{
		cod: "322-0", 
		desc: "Organização Religiosa"},
	{
		cod: "323-9", 
		desc: "Comunidade Indígena"},
	{
		cod: "324-7", 
		desc: "Fundo Privado"},
	{
		cod: "325-5", 
		desc: "Órgão de Direção Nacional de Partido Político"},
	{
		cod: "326-3", 
		desc: "Órgão de Direção Regional de Partido Político"},
	{
		cod: "327-1", 
		desc: "Órgão de Direção Local de Partido Político"},
	{
		cod: "328-0", 
		desc: "Comitê Financeiro de Partido Político"},
	{
		cod: "329-8", 
		desc: "Frente Plebiscitária ou Referendária"},
	{
		cod: "330-1", 
		desc: "Organização Social (OS)"},
	{
		cod: "331-0", 
		desc: "Demais Condomínios"},
	{
		cod: "399-9", 
		desc: "Associação Privada"},
	{
		cod: "401-4", 
		desc: "Empresa Individual Imobiliária"},
	{
		cod: "402-2", 
		desc: "Segurado Especial"},
	{
		cod: "408-1", 
		desc: "Contribuinte individual"},
	{
		cod: "409-0", 
		desc: "Candidato a Cargo Político Eletivo"},
	{
		cod: "411-1", 
		desc: "Leiloeiro"},
	{
		cod: "412-0", 
		desc: "Produtor Rural (Pessoa Física)"},
	{
		cod: "501-0", 
		desc: "Organização Internacional"},
	{
		cod: "502-9", 
		desc: "Representação Diplomática Estrangeira"},
	{
		cod: "503-7", 
		desc: "Outras Instituições Extraterritoriais "}
]

